
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient, userServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetUsersRequest } from "zaehlerfreunde-central/user_service_pb";
import { AccessRole } from "zaehlerfreunde-proto-types/permissions_pb";
import { AddUserToSpaceRequest, GetSpaceAccessRolesRequest } from "zaehlerfreunde-central/space_service_pb";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class AddUserToSpaceDialog extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  @Prop({ default: null }) space: Space | null;
  @Prop() userInfo: RemoteData<UserError, User[]>;
  alreadyAddedUsers: string[] = [];
  partnerUsers: RemoteData<UserError, User[]> = initialized;

  itemsPerPage: number = 10;
  page: number = 1;
  search: string = "";

  addToSpaceCall: RemoteCall<UserError> = initialized;
  spaceAccessRoles: RemoteData<UserError, AccessRole[]> = initialized;

  selectedUsers: User[] | null = null;
  addAsAdmin: boolean = false;
  selectedAccessRole: string | null = null;

  get spaceAccessRoleOptions(): { text: string; value: string; permissions: string }[] {
    return this.spaceAccessRoles.list.map((role: AccessRole) => ({
      text: role.getName(),
      value: role.getId(),
      permissions: role.getPermissionNamesList().join(", "),
    }));
  }

  userEmail(user: User): string {
    return user.getEmail();
  }
  userName(user: User): string {
    return user.getName();
  }
  userValue(user: User): User {
    return user;
  }
  userPhoto(user: User): string | undefined {
    return user.getUserProfileInfo()?.getPicture();
  }

  async loadUsers(): Promise<void> {
    const request = new GetUsersRequest();
    request.setPageSize(this.itemsPerPage);
    request.setPage(this.page - 1);
    if (this.search != "") {
      request.setEmail(this.search);
    }
    request.setAvoidUserIdsList(this.alreadyAddedUsers);
    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      const response = await userServiceClient.getUsers(request, {});
      this.partnerUsers = success(response.getUsersList());
      if (this.selectedUsers) {
        this.selectedUsers?.forEach((selectedUser) => {
          let selectedUserPresent = false;
          this.partnerUsers.data?.forEach((user) => {
            if (user.getId() == selectedUser.getId()) {
              selectedUserPresent = true;
            }
          });
          if (!selectedUserPresent) {
            this.partnerUsers.data?.push(selectedUser);
          }
        });
      }
    } catch (error) {
      this.partnerUsers = failure(userErrorFrom(error));
    }
  }

  async mounted(): Promise<void> {
    this.userInfo.data?.forEach((user) => {
      this.alreadyAddedUsers.push(user.getId());
    });
    if (this.space !== null) {
      try {
        this.spaceAccessRoles = pending;
        const response = await spaceServiceClient.getSpaceAccessRoles(new GetSpaceAccessRolesRequest(), {});
        this.spaceAccessRoles = success(response.getAccessRolesList());
      } catch (error) {
        this.spaceAccessRoles = failure(userErrorFrom(error));
      }
      await this.loadUsers();
    }
  }

  async onSaveClicked(): Promise<void> {
    try {
      this.addToSpaceCall = pending;

      const request = new AddUserToSpaceRequest();
      if (this.selectedUsers) {
        let ids: string[] = [];
        this.selectedUsers.forEach((user) => {
          ids.push(user.getId());
        });
        request.setUserIdsList(ids);
      }

      if (this.space) {
        request.setSpaceIdsList([this.space.getId()]);

        if (this.selectedAccessRole) {
          request.setAccessRole(this.selectedAccessRole);
        }
      }

      await spaceServiceClient.addUserToSpace(request, {});

      this.addToSpaceCall = success(void 0);

      this.$emit("user-added");
    } catch (error) {
      this.addToSpaceCall = failure(userErrorFrom(error));
    }
  }
}
