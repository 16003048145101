
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ZaehlerfreundeLogo from "./ZaehlerfreundeLogo.vue";
import SpaceSelect from "../SpaceSelect.vue";
import { spacesModule } from "@/store/modules/spaces";
import { themeModule } from "@/store/modules/theme";
import { PartnerLogo } from "zaehlerfreunde-proto-types/partners_pb";
import { paths } from "@/router/routes";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

interface MenuItemsGroup {
  name: string;
  menuItems: MenuItem[];
}

interface MenuItem {
  logo: string;
  display: string;
  path: string;
  requiresSpace: boolean;
}

@Component({
  components: {
    ZaehlerfreundeLogo,
    SpaceSelect,
  },
})
export default class SideMenu extends Vue {
  @Prop({ default: "Dashboard" }) selectedMenuItem: string;
  @Prop({ default: false }) sideMenuTemporary: boolean;

  @featuresModules.Getter enabledFeatures: Set<Feature>;
  @themeModule.Getter partnerLogo: PartnerLogo | null;
  @spacesModule.Getter spaceSelected: boolean;

  showMenu: boolean = false;

  paths = paths;

  @Watch("sideMenuTemporary")
  onSideMenuTemporaryChanged(): void {
    this.showMenu = false;
  }

  get menuItemGroups(): MenuItemsGroup[] {
    return [
      {
        name: "Allgemein",
        menuItems: [
          { logo: "mdi-speedometer", display: "Dashboard", path: paths.platform.dashboard, requiresSpace: false },
          ...(this.enabledFeatures.has(Feature.AUTOMATIONS)
            ? [
                {
                  logo: "mdi-robot",
                  display: "Smarte Steuerung",
                  path: paths.platform.automations,
                  requiresSpace: true,
                },
              ]
            : []),
          ...(this.enabledFeatures.has(Feature.SIMULATOR)
            ? [{ logo: "mdi-calculator", display: "Simulator", path: paths.platform.simulator, requiresSpace: true }]
            : []),
          ...(this.enabledFeatures.has(Feature.TARIFF_SWITCH)
            ? [
                {
                  logo: "mdi-file-document",
                  display: "Tarifwechsel",
                  path: paths.platform.tariffSwitch,
                  requiresSpace: true,
                },
              ]
            : []),
          ...(this.enabledFeatures.has(Feature.TIPS)
            ? [{ logo: "mdi-lightbulb", display: "Tipps", path: paths.platform.tips, requiresSpace: false }]
            : []),
          ...(this.enabledFeatures.has(Feature.SHOP)
            ? [{ logo: "mdi-shopping", display: "Shop", path: paths.platform.shop, requiresSpace: false }]
            : []),
        ],
      },
      {
        name: "Verwaltung",
        menuItems: [
          { logo: "mdi-connection", display: "Geräte", path: paths.platform.devices, requiresSpace: true },
          { logo: "mdi-home", display: "Objekt", path: paths.platform.spaceSettings, requiresSpace: true },
          ...(this.enabledFeatures.has(Feature.INVOICES)
            ? [{ logo: "mdi-receipt-text", display: "Rechnungen", path: paths.platform.invoices, requiresSpace: true }]
            : []),
        ],
      },
      {
        name: "Support",
        menuItems: [{ logo: "mdi-help", display: "FAQ", path: paths.platform.faq, requiresSpace: false }],
      },
    ];
  }

  show(): void {
    this.showMenu = true;
  }

  onMenuItemClicked(menuItem: MenuItem): void {
    if (this.selectedMenuItem === menuItem.display) {
      this.showMenu = false;
    } else {
      this.$router.push(menuItem.path);
    }
  }
}
