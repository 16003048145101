
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Product extends Vue {
  get productId(): string {
    return this.$route.params.productId;
  }

  mounted(): void {
    let script = document.createElement("script");
    script.setAttribute("src", "/single-product.js");
    script.type = "text/javascript";
    document.head.appendChild(script);
  }
}
