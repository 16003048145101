import { render, staticRenderFns } from "./UsersAndInvitesTable.vue?vue&type=template&id=318cfd33&scoped=true"
import script from "./UsersAndInvitesTable.vue?vue&type=script&lang=ts"
export * from "./UsersAndInvitesTable.vue?vue&type=script&lang=ts"
import style0 from "./UsersAndInvitesTable.vue?vue&type=style&index=0&id=318cfd33&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318cfd33",
  null
  
)

export default component.exports