
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class MiniPvModulesCollection extends Vue {
  mounted(): void {
    let script = document.createElement("script");
    script.setAttribute("src", "/mini-pv-modules-collection.js");
    script.type = "text/javascript";
    document.head.appendChild(script);
  }
}
