
import { getAuthService } from "@/auth";
import SubscriptionPlans, { ActivateButton } from "@/components/subscription/SubscriptionPlans.vue";
import { paths } from "@/router/routes";
import { Vue, Component } from "vue-property-decorator";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";

@Component({
  components: { SubscriptionPlans },
})
export default class Pricing extends Vue {
  SubscriptionPlan = SubscriptionPlan;

  get freePlanButton(): ActivateButton {
    return {
      text: this.$t("registerNow").toString(),
      show: true,
      disabled: false,
      event: "activateFreePlan",
    };
  }

  get proPlanButton(): ActivateButton {
    return {
      text: this.$t("registerNow").toString(),
      show: true,
      disabled: false,
      event: "activateProPlan",
    };
  }

  onActivatePlanClicked(plan: SubscriptionPlan): void {
    if (plan === SubscriptionPlan.FREE) {
      this.$gtag.event("registration-clicked", {
        event_label: "Free Plan Registration clicked",
        event_category: "user-action",
      });
    } else {
      this.$gtag.event("registration-clicked", {
        event_label: "Pro Plan Registration clicked",
        event_category: "user-action",
      });
    }

    this.$gtag.event("conversion", {
      send_to: "AW-10831568791/--0CCJP1sI0YEJe_8qwo",
      value: 10,
      currency: "USD",
    });

    getAuthService().loginWithRedirect({
      appState: {
        targetUrl: plan === SubscriptionPlan.PRO ? paths.platform.account.subscriptions : undefined,
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  }
}
