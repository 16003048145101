
import { Component, Prop, Vue } from "vue-property-decorator";
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { EnergyTariff, GasTariff, WaterTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import AddUpdateWaterTariff from "../space-settings/tariff/AddUpdateWaterTariff.vue";
import AddUpdateGasTariff from "../space-settings/tariff/AddUpdateGasTariff.vue";
import AddUpdateEnergyTariff from "../space-settings/tariff/AddUpdateEnergyTariff.vue";
import spaces from "@/store/modules/spaces";

@Component({
  components: {
    AddUpdateEnergyTariff,
    AddUpdateWaterTariff,
    AddUpdateGasTariff,
  },
})
export default class AddTariffReminder extends Vue {
  @Prop({ default: false }) reason: "tariff-switch" | "monthly-costs" | "simulator" | "another-tariff" | "first-tariff";
  @Prop({ default: Device.Medium.ELECTRICITY }) deviceMedium: Device.Medium;
  @Prop({ default: "" }) spaceId: string;

  @tariffModule.Getter energyTariffs: RemoteData<UserError, EnergyTariff[]>;
  @tariffModule.Getter gasTariffs: RemoteData<UserError, GasTariff[]>;
  @tariffModule.Getter waterTariffs: RemoteData<UserError, WaterTariff[]>;

  zfProtoDeviceMedium = Device.Medium;
  showAddUpdateTariffDialog = false;

  get selectedSpaceId(): string {
    if (this.spaceId) {
      return this.spaceId;
    } else {
      return spaces.selectedSpace?.getId() ?? "";
    }
  }

  get currentMonth(): string {
    return new Date().toLocaleString("de-DE", { month: "long" });
  }

  get tariffWithoutStartDateExists(): boolean {
    return this.energyTariffs.list.some((e) => !e.getStartDate());
  }
}
