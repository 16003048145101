
import { RemoteData, initialized } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { User } from "zaehlerfreunde-proto-types/user_pb";

export interface UserDetailsData {
  title: string;
  value: string;
}

@Component({
  components: {},
})
export default class UserDetails extends Vue {
  @Prop({ default: initialized }) userInfo: RemoteData<UserError, User>;
  detailsItems: UserDetailsData[] = [];

  get userDetails(): UserDetailsData[] {
    return [
      {
        title: "Name",
        value: this.userInfo?.data?.getUserProfileInfo()?.getName() ?? "",
      },
      {
        title: "Email",
        value: this.userInfo?.data?.getEmail() ?? "",
      },
      {
        title: "Letzte Anmeldung",
        value: moment
          .unix(this.userInfo?.data?.getUserProfileInfo()?.getLastLogin() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
      },
      {
        title: "Erste Anmeldung",
        value: moment
          .unix(this.userInfo?.data?.getUserProfileInfo()?.getCreatedAt() ?? 0)
          .locale("de")
          .format("DD.MM.YYYY HH:mm"),
      },

      {
        title: "Anzahl der Anmeldungen",
        value: (this.userInfo?.data?.getUserProfileInfo()?.getLoginCount() ?? 0).toString(),
      },

      {
        title: "Handynummer",
        value: this.userInfo?.data?.getUserProfileInfo()?.getPhoneNumber() ?? "",
      },
    ];
  }
}
