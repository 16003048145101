
import { partnerServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { Partner, PartnerDomainStatus } from "zaehlerfreunde-proto-types/partners_pb";
import {
  AddPartnerRequest,
  ConfirmPartnerDomainCreationRequest,
  GetPartnerDomainStatusRequest,
} from "zaehlerfreunde-central/partner_service_pb";
import { GetTreeSelectResponse } from "zaehlerfreunde-central/ui_service_pb";
import { TreeSelectionNode, toTreeSelectionNode } from "@/utils/proto-utils";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { EnvironmentConfig, getEnvironment } from "@/config/env";
import { hexCodeRule, imageUrlRule, urlRule } from "@/utils/rules";

@Component({
  components: { Treeselect },
})
export default class CreatePartnerDialog extends Vue {
  @Prop({ default: [] }) childPartnersNodes: GetTreeSelectResponse.Node[];
  @Prop() partner: Partner;
  @Prop({ default: false }) isSuperAdmin: boolean;

  selectedChildPartnerNode: TreeSelectionNode = {
    id: "",
    label: "",
  };

  inProgress = pending;
  init = initialized;

  confirmCreatePartnerCall: RemoteCall<UserError> = initialized;
  createPartnerCall: RemoteCall<UserError> = initialized;
  partnerDomainStatus: RemoteCall<UserError> = initialized;

  partnerDnsProgress: RemoteCall<UserError> = initialized;
  partnerAuth0AppProgress: RemoteCall<UserError> = initialized;

  id: string = "";
  name: string = "";
  sendGridListId: string = "";
  selectedPlan: SubscriptionPlan = SubscriptionPlan.FREE;
  selectedType: Partner.Type = Partner.Type.B2B;
  step = 1;
  plans = [
    { text: "Free", value: SubscriptionPlan.FREE },
    { text: "B2B", value: SubscriptionPlan.B2B },
    { text: "PRO", value: SubscriptionPlan.PRO },
    { text: "White Label", value: SubscriptionPlan.WHITE_LABEL },
  ];
  types = [
    { text: "B2B", value: Partner.Type.B2B },
    { text: "Zählerfreunde", value: Partner.Type.ZAEHLERFREUNDE },
    { text: "White Label", value: Partner.Type.WHITE_LABEL },
  ];
  withUser: boolean = false;
  userEmail: string = "";
  userPassword: string = "";
  isPartnerCreationInProgress: boolean = false;
  loginBtnColor: string = "#AAAAAA";
  loginPageLogoUri: string = "";
  logoutRedirectUrl: string = "";

  withDomain: boolean = true;
  hasParentPartner: boolean = true;
  showConfirmBtn: boolean = false;
  createdPartnerId: string = "";

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";
  validatePartnerId: (value: string) => boolean = (value: string) => {
    let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~A-Z]/;
    return !format.test(value);
  };

  hexCodeRule = hexCodeRule;
  imageUrlRule = imageUrlRule;
  urlRule = urlRule;

  get partnerDomain(): string {
    if (this.envConfig.isProd) {
      return `https://${this.id}.zaehlerfreunde.de`;
    } else {
      return `https://${this.id}internal.zaehlerfreunde.com`;
    }
  }

  get partnerName(): string {
    return this.name;
  }
  get infoComplete(): boolean {
    return this.id != "" && this.name != "" && this.validatePartnerId(this.id);
  }

  get isSubmitBtnEnabled(): boolean {
    return (
      this.infoComplete &&
      (this.withDomain
        ? this.loginBtnColor != "" &&
          this.loginPageLogoUri != "" &&
          this.logoutRedirectUrl != "" &&
          urlRule(this.logoutRedirectUrl) === true &&
          imageUrlRule(this.loginPageLogoUri) === true
        : true)
    );
  }

  get envConfig(): EnvironmentConfig {
    return getEnvironment();
  }

  get options(): TreeSelectionNode[] {
    return [
      {
        id: this.partner.getId(),
        label: this.partner.getName(),
        children: this.childPartnersNodes.length
          ? this.childPartnersNodes.map((node) => toTreeSelectionNode(node))
          : undefined,
      },
    ];
  }

  get title(): string {
    return this.isPartnerCreationInProgress
      ? `Mandanten hinzufügen - ${this.createdPartnerId}`
      : "Mandanten hinzufügen";
  }

  get subTitle(): string {
    return this.isPartnerCreationInProgress
      ? `Hier sehen Sie den Status der Domain-Erstellung für ${this.createdPartnerId} . Sollte ein Fehler aufgetreten sein, kontaktieren Sie bitte kontakt@zaehlerfreunde.com`
      : "Hier können Sie für ausgewählte Gruppen oder Kunden (Mandanten) eine eigene White-Label Lösung anlegen. Der Kunde kann anschließend auf Wunsch Admin-Rechte für seine Objekte erhalten. Dadurch können Sie ein neues Geschäftsmodell aufbauen und ihrem Kunden eine individuelle Lösung anbieten.";
  }

  mounted(): void {
    this.selectedChildPartnerNode = {
      id: this.partner.getId(),
      label: this.partner.getName(),
      children: this.childPartnersNodes.map((node) => toTreeSelectionNode(node)),
    };
    this.getPartnerDomainStatus();
  }

  async createPartner(): Promise<void> {
    this.createPartnerCall = pending;

    const partner = new Partner();
    partner.setId(this.id);
    partner.setName(this.name);
    if (this.isSuperAdmin) {
      partner.setSendgridListId(this.sendGridListId);
      partner.setDefaultSubscriptionPlan(this.selectedPlan ?? SubscriptionPlan.FREE);
      partner.setType(this.selectedType ?? Partner.Type.ZAEHLERFREUNDE);
    }

    if (this.hasParentPartner) {
      partner.setParentPartnerId(this.selectedChildPartnerNode.id);
    }

    const request = new AddPartnerRequest();
    request.setPartner(partner);
    if (this.withDomain) {
      const params = new AddPartnerRequest.PartnerDomainParams();
      params.setLogoutRedirectUrl(this.logoutRedirectUrl);
      params.setLoginPageLogoUrl(this.loginPageLogoUri);
      params.setLoginBtnColor(this.loginBtnColor);
      request.setPartnerDomainParams(params);
    }

    try {
      if (this.infoComplete) {
        const response = await partnerServiceClient.addPartner(request, {});

        if (this.withDomain) {
          setTimeout(() => {
            this.createPartnerCall = success(void 0);
            this.partnerDnsProgress = pending;
            this.createdPartnerId = this.id;
            this.isPartnerCreationInProgress = true;
          }, 2000);
        } else {
          this.createPartnerCall = success(void 0);
          this.createdPartnerId = this.id;
          this.isPartnerCreationInProgress = true;
          this.partnerDnsProgress = initialized;
          this.partnerAuth0AppProgress = initialized;
        }
      }
    } catch (error) {
      this.createPartnerCall = failure(userErrorFrom(error));
    }
  }

  async getPartnerDomainStatus(): Promise<void> {
    try {
      this.partnerDomainStatus = pending;
      const response = await partnerServiceClient.getPartnerDomainStatus(new GetPartnerDomainStatusRequest(), {});
      this.createdPartnerId = response.getPartnerId();

      this.partnerDomainStatus = success(void 0);

      switch (response.getStatus()) {
        case PartnerDomainStatus.IN_PROGRESS_DNS:
          this.isPartnerCreationInProgress = true;
          this.partnerDnsProgress = pending;

          break;
        case PartnerDomainStatus.ERROR_DNS:
          this.isPartnerCreationInProgress = true;
          this.partnerDnsProgress = failure(userErrorFrom(null));

          break;

        case PartnerDomainStatus.IN_PROGRESS_AUTH0_APP:
          this.isPartnerCreationInProgress = true;
          this.partnerDnsProgress = success(void 0);
          this.partnerAuth0AppProgress = pending;
          break;
        case PartnerDomainStatus.ERROR_AUTH0_APP:
          this.isPartnerCreationInProgress = true;
          this.partnerDnsProgress = success(void 0);
          this.partnerAuth0AppProgress = failure(userErrorFrom(null));
          break;

        case PartnerDomainStatus.VERIFY_CREATED:
          this.isPartnerCreationInProgress = true;
          this.partnerDnsProgress = success(void 0);
          this.partnerAuth0AppProgress = success(void 0);
          this.showConfirmBtn = true;
          break;

        default:
          this.partnerDnsProgress = initialized;
          this.partnerAuth0AppProgress = initialized;
          this.createdPartnerId = "";
          break;
      }
    } catch (error) {
      this.partnerDomainStatus = failure(userErrorFrom(error));
    }
  }

  async confirmCreation(): Promise<void> {
    try {
      this.confirmCreatePartnerCall = pending;
      const request = new ConfirmPartnerDomainCreationRequest();
      request.setPartnerId(this.createdPartnerId);
      const response = await partnerServiceClient.confirmPartnerDomainCreation(request, {});
      this.confirmCreatePartnerCall = success(void 0);
      this.isPartnerCreationInProgress = false;
    } catch (error) {
      this.confirmCreatePartnerCall = failure(userErrorFrom(error));
    }
  }
}
