
import account from "@/store/modules/account";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

const stylingModule = namespace("styling");

@Component
export default class ChangePassword extends Vue {
  @stylingModule.State primaryColor: string;

  onCancelClicked(): void {
    this.$emit("cancel");
  }

  onSaveClicked(): void {
    account.setNewPassword();
    this.$emit("save");
  }
}
