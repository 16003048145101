// source: services/central/tariff_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_datetime_pb = require('../../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
var types_tariff_pb = require('../../types/tariff_pb.js');
goog.object.extend(proto, types_tariff_pb);
var types_device_pb = require('../../types/device_pb.js');
goog.object.extend(proto, types_device_pb);
goog.exportSymbol('proto.services.central.AddAdvancePaymentRequest', null, global);
goog.exportSymbol('proto.services.central.AddAdvancePaymentRequest.TariffCase', null, global);
goog.exportSymbol('proto.services.central.AddAdvancePaymentResponse', null, global);
goog.exportSymbol('proto.services.central.AddEnergyTariffRequest', null, global);
goog.exportSymbol('proto.services.central.AddEnergyTariffResponse', null, global);
goog.exportSymbol('proto.services.central.AddTariffRequest', null, global);
goog.exportSymbol('proto.services.central.AddTariffRequest.TariffCase', null, global);
goog.exportSymbol('proto.services.central.AddTariffResponse', null, global);
goog.exportSymbol('proto.services.central.AddTariffResponse.TariffsCase', null, global);
goog.exportSymbol('proto.services.central.AdvancePayment', null, global);
goog.exportSymbol('proto.services.central.DeleteAdvancePaymentRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteAdvancePaymentRequest.TariffCase', null, global);
goog.exportSymbol('proto.services.central.DeleteAdvancePaymentResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteEnergyTariffRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteEnergyTariffResponse', null, global);
goog.exportSymbol('proto.services.central.DeleteTariffRequest', null, global);
goog.exportSymbol('proto.services.central.DeleteTariffResponse', null, global);
goog.exportSymbol('proto.services.central.EnergyTariffsResponse', null, global);
goog.exportSymbol('proto.services.central.GasTariffsResponse', null, global);
goog.exportSymbol('proto.services.central.GetAdvancePaymentsRequest', null, global);
goog.exportSymbol('proto.services.central.GetAdvancePaymentsRequest.TariffCase', null, global);
goog.exportSymbol('proto.services.central.GetAdvancePaymentsResponse', null, global);
goog.exportSymbol('proto.services.central.GetCharityProjectsRequest', null, global);
goog.exportSymbol('proto.services.central.GetCharityProjectsResponse', null, global);
goog.exportSymbol('proto.services.central.GetCurrentEnergyTariffRequest', null, global);
goog.exportSymbol('proto.services.central.GetCurrentEnergyTariffResponse', null, global);
goog.exportSymbol('proto.services.central.GetCurrentTariffRequest', null, global);
goog.exportSymbol('proto.services.central.GetCurrentTariffResponse', null, global);
goog.exportSymbol('proto.services.central.GetCurrentTariffResponse.CurrentTariffCase', null, global);
goog.exportSymbol('proto.services.central.GetEnergyTariffsRequest', null, global);
goog.exportSymbol('proto.services.central.GetEnergyTariffsResponse', null, global);
goog.exportSymbol('proto.services.central.GetEstimatedConsumptionRequest', null, global);
goog.exportSymbol('proto.services.central.GetEstimatedConsumptionResponse', null, global);
goog.exportSymbol('proto.services.central.GetPendingSwitchRequest', null, global);
goog.exportSymbol('proto.services.central.GetPendingSwitchResponse', null, global);
goog.exportSymbol('proto.services.central.GetSearchInfoRequest', null, global);
goog.exportSymbol('proto.services.central.GetSearchInfoResponse', null, global);
goog.exportSymbol('proto.services.central.GetSearchResultsRequest', null, global);
goog.exportSymbol('proto.services.central.GetSearchResultsResponse', null, global);
goog.exportSymbol('proto.services.central.GetTariffsRequest', null, global);
goog.exportSymbol('proto.services.central.GetTariffsResponse', null, global);
goog.exportSymbol('proto.services.central.GetTariffsResponse.TariffsDeprecatedCase', null, global);
goog.exportSymbol('proto.services.central.SelectCharityProjectRequest', null, global);
goog.exportSymbol('proto.services.central.SelectCharityProjectResponse', null, global);
goog.exportSymbol('proto.services.central.SetEstimatedConsumptionRequest', null, global);
goog.exportSymbol('proto.services.central.SetEstimatedConsumptionResponse', null, global);
goog.exportSymbol('proto.services.central.SetIsWatchdogEnabledRequest', null, global);
goog.exportSymbol('proto.services.central.SetIsWatchdogEnabledResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateEnergyTariffRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateEnergyTariffResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateSupplierCustomerIdRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateSupplierCustomerIdResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateTariffRequest', null, global);
goog.exportSymbol('proto.services.central.UpdateTariffRequest.TariffCase', null, global);
goog.exportSymbol('proto.services.central.UpdateTariffResponse', null, global);
goog.exportSymbol('proto.services.central.UpdateTariffResponse.TariffsCase', null, global);
goog.exportSymbol('proto.services.central.WaterTariffsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTariffsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetTariffsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTariffsRequest.displayName = 'proto.services.central.GetTariffsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.EnergyTariffsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.EnergyTariffsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.EnergyTariffsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.EnergyTariffsResponse.displayName = 'proto.services.central.EnergyTariffsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.WaterTariffsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.WaterTariffsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.WaterTariffsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.WaterTariffsResponse.displayName = 'proto.services.central.WaterTariffsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GasTariffsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GasTariffsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GasTariffsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GasTariffsResponse.displayName = 'proto.services.central.GasTariffsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetTariffsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetTariffsResponse.oneofGroups_);
};
goog.inherits(proto.services.central.GetTariffsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetTariffsResponse.displayName = 'proto.services.central.GetTariffsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCurrentTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCurrentTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCurrentTariffRequest.displayName = 'proto.services.central.GetCurrentTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCurrentTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetCurrentTariffResponse.oneofGroups_);
};
goog.inherits(proto.services.central.GetCurrentTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCurrentTariffResponse.displayName = 'proto.services.central.GetCurrentTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.AddTariffRequest.oneofGroups_);
};
goog.inherits(proto.services.central.AddTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddTariffRequest.displayName = 'proto.services.central.AddTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.AddTariffResponse.oneofGroups_);
};
goog.inherits(proto.services.central.AddTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddTariffResponse.displayName = 'proto.services.central.AddTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.UpdateTariffRequest.oneofGroups_);
};
goog.inherits(proto.services.central.UpdateTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateTariffRequest.displayName = 'proto.services.central.UpdateTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.UpdateTariffResponse.oneofGroups_);
};
goog.inherits(proto.services.central.UpdateTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateTariffResponse.displayName = 'proto.services.central.UpdateTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteTariffRequest.displayName = 'proto.services.central.DeleteTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteTariffResponse.displayName = 'proto.services.central.DeleteTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetEstimatedConsumptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetEstimatedConsumptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetEstimatedConsumptionRequest.displayName = 'proto.services.central.GetEstimatedConsumptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetEstimatedConsumptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetEstimatedConsumptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetEstimatedConsumptionResponse.displayName = 'proto.services.central.GetEstimatedConsumptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetEstimatedConsumptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetEstimatedConsumptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetEstimatedConsumptionRequest.displayName = 'proto.services.central.SetEstimatedConsumptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetEstimatedConsumptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetEstimatedConsumptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetEstimatedConsumptionResponse.displayName = 'proto.services.central.SetEstimatedConsumptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSearchResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSearchResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSearchResultsRequest.displayName = 'proto.services.central.GetSearchResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSearchResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetSearchResultsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetSearchResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSearchResultsResponse.displayName = 'proto.services.central.GetSearchResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetPendingSwitchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetPendingSwitchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetPendingSwitchRequest.displayName = 'proto.services.central.GetPendingSwitchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetPendingSwitchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetPendingSwitchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetPendingSwitchResponse.displayName = 'proto.services.central.GetPendingSwitchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSearchInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSearchInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSearchInfoRequest.displayName = 'proto.services.central.GetSearchInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetSearchInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetSearchInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetSearchInfoResponse.displayName = 'proto.services.central.GetSearchInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetIsWatchdogEnabledRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetIsWatchdogEnabledRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetIsWatchdogEnabledRequest.displayName = 'proto.services.central.SetIsWatchdogEnabledRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SetIsWatchdogEnabledResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SetIsWatchdogEnabledResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SetIsWatchdogEnabledResponse.displayName = 'proto.services.central.SetIsWatchdogEnabledResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCharityProjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCharityProjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCharityProjectsRequest.displayName = 'proto.services.central.GetCharityProjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCharityProjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetCharityProjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetCharityProjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCharityProjectsResponse.displayName = 'proto.services.central.GetCharityProjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SelectCharityProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.SelectCharityProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SelectCharityProjectRequest.displayName = 'proto.services.central.SelectCharityProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.SelectCharityProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.SelectCharityProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.SelectCharityProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.SelectCharityProjectResponse.displayName = 'proto.services.central.SelectCharityProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateSupplierCustomerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateSupplierCustomerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateSupplierCustomerIdRequest.displayName = 'proto.services.central.UpdateSupplierCustomerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateSupplierCustomerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateSupplierCustomerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateSupplierCustomerIdResponse.displayName = 'proto.services.central.UpdateSupplierCustomerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetEnergyTariffsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetEnergyTariffsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetEnergyTariffsRequest.displayName = 'proto.services.central.GetEnergyTariffsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetEnergyTariffsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetEnergyTariffsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetEnergyTariffsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetEnergyTariffsResponse.displayName = 'proto.services.central.GetEnergyTariffsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCurrentEnergyTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCurrentEnergyTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCurrentEnergyTariffRequest.displayName = 'proto.services.central.GetCurrentEnergyTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetCurrentEnergyTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.GetCurrentEnergyTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetCurrentEnergyTariffResponse.displayName = 'proto.services.central.GetCurrentEnergyTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddEnergyTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddEnergyTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddEnergyTariffRequest.displayName = 'proto.services.central.AddEnergyTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddEnergyTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.AddEnergyTariffResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.AddEnergyTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddEnergyTariffResponse.displayName = 'proto.services.central.AddEnergyTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateEnergyTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.UpdateEnergyTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateEnergyTariffRequest.displayName = 'proto.services.central.UpdateEnergyTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.UpdateEnergyTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.UpdateEnergyTariffResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.UpdateEnergyTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.UpdateEnergyTariffResponse.displayName = 'proto.services.central.UpdateEnergyTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteEnergyTariffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteEnergyTariffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteEnergyTariffRequest.displayName = 'proto.services.central.DeleteEnergyTariffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteEnergyTariffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteEnergyTariffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteEnergyTariffResponse.displayName = 'proto.services.central.DeleteEnergyTariffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAdvancePaymentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.GetAdvancePaymentsRequest.oneofGroups_);
};
goog.inherits(proto.services.central.GetAdvancePaymentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAdvancePaymentsRequest.displayName = 'proto.services.central.GetAdvancePaymentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.GetAdvancePaymentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.central.GetAdvancePaymentsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.central.GetAdvancePaymentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.GetAdvancePaymentsResponse.displayName = 'proto.services.central.GetAdvancePaymentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddAdvancePaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.AddAdvancePaymentRequest.oneofGroups_);
};
goog.inherits(proto.services.central.AddAdvancePaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddAdvancePaymentRequest.displayName = 'proto.services.central.AddAdvancePaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AddAdvancePaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AddAdvancePaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AddAdvancePaymentResponse.displayName = 'proto.services.central.AddAdvancePaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteAdvancePaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_);
};
goog.inherits(proto.services.central.DeleteAdvancePaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteAdvancePaymentRequest.displayName = 'proto.services.central.DeleteAdvancePaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.DeleteAdvancePaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.DeleteAdvancePaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.DeleteAdvancePaymentResponse.displayName = 'proto.services.central.DeleteAdvancePaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.central.AdvancePayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.central.AdvancePayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.central.AdvancePayment.displayName = 'proto.services.central.AdvancePayment';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTariffsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTariffsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTariffsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTariffsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTariffsRequest}
 */
proto.services.central.GetTariffsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTariffsRequest;
  return proto.services.central.GetTariffsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTariffsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTariffsRequest}
 */
proto.services.central.GetTariffsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTariffsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTariffsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTariffsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTariffsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetTariffsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetTariffsRequest} returns this
 */
proto.services.central.GetTariffsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Device.Medium medium = 2;
 * @return {!proto.types.Device.Medium}
 */
proto.services.central.GetTariffsRequest.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.services.central.GetTariffsRequest} returns this
 */
proto.services.central.GetTariffsRequest.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.EnergyTariffsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.EnergyTariffsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.EnergyTariffsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.EnergyTariffsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.EnergyTariffsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsList: jspb.Message.toObjectList(msg.getEnergyTariffsList(),
    types_tariff_pb.EnergyTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.EnergyTariffsResponse}
 */
proto.services.central.EnergyTariffsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.EnergyTariffsResponse;
  return proto.services.central.EnergyTariffsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.EnergyTariffsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.EnergyTariffsResponse}
 */
proto.services.central.EnergyTariffsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.addEnergyTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.EnergyTariffsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.EnergyTariffsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.EnergyTariffsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.EnergyTariffsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.EnergyTariff energy_tariffs = 1;
 * @return {!Array<!proto.types.EnergyTariff>}
 */
proto.services.central.EnergyTariffsResponse.prototype.getEnergyTariffsList = function() {
  return /** @type{!Array<!proto.types.EnergyTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.EnergyTariff, 1));
};


/**
 * @param {!Array<!proto.types.EnergyTariff>} value
 * @return {!proto.services.central.EnergyTariffsResponse} returns this
*/
proto.services.central.EnergyTariffsResponse.prototype.setEnergyTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergyTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyTariff}
 */
proto.services.central.EnergyTariffsResponse.prototype.addEnergyTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergyTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.EnergyTariffsResponse} returns this
 */
proto.services.central.EnergyTariffsResponse.prototype.clearEnergyTariffsList = function() {
  return this.setEnergyTariffsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.WaterTariffsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.WaterTariffsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.WaterTariffsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.WaterTariffsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.WaterTariffsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    waterTariffsList: jspb.Message.toObjectList(msg.getWaterTariffsList(),
    types_tariff_pb.WaterTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.WaterTariffsResponse}
 */
proto.services.central.WaterTariffsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.WaterTariffsResponse;
  return proto.services.central.WaterTariffsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.WaterTariffsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.WaterTariffsResponse}
 */
proto.services.central.WaterTariffsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.WaterTariff;
      reader.readMessage(value,types_tariff_pb.WaterTariff.deserializeBinaryFromReader);
      msg.addWaterTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.WaterTariffsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.WaterTariffsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.WaterTariffsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.WaterTariffsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaterTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.WaterTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.WaterTariff water_tariffs = 1;
 * @return {!Array<!proto.types.WaterTariff>}
 */
proto.services.central.WaterTariffsResponse.prototype.getWaterTariffsList = function() {
  return /** @type{!Array<!proto.types.WaterTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.WaterTariff, 1));
};


/**
 * @param {!Array<!proto.types.WaterTariff>} value
 * @return {!proto.services.central.WaterTariffsResponse} returns this
*/
proto.services.central.WaterTariffsResponse.prototype.setWaterTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.WaterTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.WaterTariff}
 */
proto.services.central.WaterTariffsResponse.prototype.addWaterTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.WaterTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.WaterTariffsResponse} returns this
 */
proto.services.central.WaterTariffsResponse.prototype.clearWaterTariffsList = function() {
  return this.setWaterTariffsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GasTariffsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GasTariffsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GasTariffsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GasTariffsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GasTariffsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gasTariffsList: jspb.Message.toObjectList(msg.getGasTariffsList(),
    types_tariff_pb.GasTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GasTariffsResponse}
 */
proto.services.central.GasTariffsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GasTariffsResponse;
  return proto.services.central.GasTariffsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GasTariffsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GasTariffsResponse}
 */
proto.services.central.GasTariffsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.GasTariff;
      reader.readMessage(value,types_tariff_pb.GasTariff.deserializeBinaryFromReader);
      msg.addGasTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GasTariffsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GasTariffsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GasTariffsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GasTariffsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGasTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.GasTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.GasTariff gas_tariffs = 1;
 * @return {!Array<!proto.types.GasTariff>}
 */
proto.services.central.GasTariffsResponse.prototype.getGasTariffsList = function() {
  return /** @type{!Array<!proto.types.GasTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.GasTariff, 1));
};


/**
 * @param {!Array<!proto.types.GasTariff>} value
 * @return {!proto.services.central.GasTariffsResponse} returns this
*/
proto.services.central.GasTariffsResponse.prototype.setGasTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.GasTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.GasTariff}
 */
proto.services.central.GasTariffsResponse.prototype.addGasTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.GasTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GasTariffsResponse} returns this
 */
proto.services.central.GasTariffsResponse.prototype.clearGasTariffsList = function() {
  return this.setGasTariffsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetTariffsResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.services.central.GetTariffsResponse.TariffsDeprecatedCase = {
  TARIFFS_DEPRECATED_NOT_SET: 0,
  ENERGY_TARIFFS_RESPONSE: 1,
  WATER_TARIFFS_RESPONSE: 2,
  GAS_TARIFFS_RESPONSE: 3
};

/**
 * @return {proto.services.central.GetTariffsResponse.TariffsDeprecatedCase}
 */
proto.services.central.GetTariffsResponse.prototype.getTariffsDeprecatedCase = function() {
  return /** @type {proto.services.central.GetTariffsResponse.TariffsDeprecatedCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetTariffsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetTariffsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetTariffsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetTariffsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTariffsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsResponse: (f = msg.getEnergyTariffsResponse()) && proto.services.central.EnergyTariffsResponse.toObject(includeInstance, f),
    waterTariffsResponse: (f = msg.getWaterTariffsResponse()) && proto.services.central.WaterTariffsResponse.toObject(includeInstance, f),
    gasTariffsResponse: (f = msg.getGasTariffsResponse()) && proto.services.central.GasTariffsResponse.toObject(includeInstance, f),
    tariffs: (f = msg.getTariffs()) && types_tariff_pb.Tariffs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetTariffsResponse}
 */
proto.services.central.GetTariffsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetTariffsResponse;
  return proto.services.central.GetTariffsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetTariffsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetTariffsResponse}
 */
proto.services.central.GetTariffsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.EnergyTariffsResponse;
      reader.readMessage(value,proto.services.central.EnergyTariffsResponse.deserializeBinaryFromReader);
      msg.setEnergyTariffsResponse(value);
      break;
    case 2:
      var value = new proto.services.central.WaterTariffsResponse;
      reader.readMessage(value,proto.services.central.WaterTariffsResponse.deserializeBinaryFromReader);
      msg.setWaterTariffsResponse(value);
      break;
    case 3:
      var value = new proto.services.central.GasTariffsResponse;
      reader.readMessage(value,proto.services.central.GasTariffsResponse.deserializeBinaryFromReader);
      msg.setGasTariffsResponse(value);
      break;
    case 4:
      var value = new types_tariff_pb.Tariffs;
      reader.readMessage(value,types_tariff_pb.Tariffs.deserializeBinaryFromReader);
      msg.setTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetTariffsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetTariffsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetTariffsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetTariffsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.EnergyTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getWaterTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.WaterTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getGasTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.central.GasTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getTariffs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      types_tariff_pb.Tariffs.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnergyTariffsResponse energy_tariffs_response = 1;
 * @return {?proto.services.central.EnergyTariffsResponse}
 */
proto.services.central.GetTariffsResponse.prototype.getEnergyTariffsResponse = function() {
  return /** @type{?proto.services.central.EnergyTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.EnergyTariffsResponse, 1));
};


/**
 * @param {?proto.services.central.EnergyTariffsResponse|undefined} value
 * @return {!proto.services.central.GetTariffsResponse} returns this
*/
proto.services.central.GetTariffsResponse.prototype.setEnergyTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetTariffsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTariffsResponse} returns this
 */
proto.services.central.GetTariffsResponse.prototype.clearEnergyTariffsResponse = function() {
  return this.setEnergyTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTariffsResponse.prototype.hasEnergyTariffsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WaterTariffsResponse water_tariffs_response = 2;
 * @return {?proto.services.central.WaterTariffsResponse}
 */
proto.services.central.GetTariffsResponse.prototype.getWaterTariffsResponse = function() {
  return /** @type{?proto.services.central.WaterTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.WaterTariffsResponse, 2));
};


/**
 * @param {?proto.services.central.WaterTariffsResponse|undefined} value
 * @return {!proto.services.central.GetTariffsResponse} returns this
*/
proto.services.central.GetTariffsResponse.prototype.setWaterTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.GetTariffsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTariffsResponse} returns this
 */
proto.services.central.GetTariffsResponse.prototype.clearWaterTariffsResponse = function() {
  return this.setWaterTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTariffsResponse.prototype.hasWaterTariffsResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GasTariffsResponse gas_tariffs_response = 3;
 * @return {?proto.services.central.GasTariffsResponse}
 */
proto.services.central.GetTariffsResponse.prototype.getGasTariffsResponse = function() {
  return /** @type{?proto.services.central.GasTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GasTariffsResponse, 3));
};


/**
 * @param {?proto.services.central.GasTariffsResponse|undefined} value
 * @return {!proto.services.central.GetTariffsResponse} returns this
*/
proto.services.central.GetTariffsResponse.prototype.setGasTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.GetTariffsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTariffsResponse} returns this
 */
proto.services.central.GetTariffsResponse.prototype.clearGasTariffsResponse = function() {
  return this.setGasTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTariffsResponse.prototype.hasGasTariffsResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional types.Tariffs tariffs = 4;
 * @return {?proto.types.Tariffs}
 */
proto.services.central.GetTariffsResponse.prototype.getTariffs = function() {
  return /** @type{?proto.types.Tariffs} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.Tariffs, 4));
};


/**
 * @param {?proto.types.Tariffs|undefined} value
 * @return {!proto.services.central.GetTariffsResponse} returns this
*/
proto.services.central.GetTariffsResponse.prototype.setTariffs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetTariffsResponse} returns this
 */
proto.services.central.GetTariffsResponse.prototype.clearTariffs = function() {
  return this.setTariffs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetTariffsResponse.prototype.hasTariffs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCurrentTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCurrentTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCurrentTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCurrentTariffRequest}
 */
proto.services.central.GetCurrentTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCurrentTariffRequest;
  return proto.services.central.GetCurrentTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCurrentTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCurrentTariffRequest}
 */
proto.services.central.GetCurrentTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCurrentTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCurrentTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCurrentTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetCurrentTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCurrentTariffRequest} returns this
 */
proto.services.central.GetCurrentTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.Device.Medium medium = 2;
 * @return {!proto.types.Device.Medium}
 */
proto.services.central.GetCurrentTariffRequest.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.services.central.GetCurrentTariffRequest} returns this
 */
proto.services.central.GetCurrentTariffRequest.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetCurrentTariffResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.services.central.GetCurrentTariffResponse.CurrentTariffCase = {
  CURRENT_TARIFF_NOT_SET: 0,
  ENERGY_TARIFF: 1,
  WATER_TARIFF: 2,
  GAS_TARIFF: 3
};

/**
 * @return {proto.services.central.GetCurrentTariffResponse.CurrentTariffCase}
 */
proto.services.central.GetCurrentTariffResponse.prototype.getCurrentTariffCase = function() {
  return /** @type {proto.services.central.GetCurrentTariffResponse.CurrentTariffCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetCurrentTariffResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCurrentTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCurrentTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCurrentTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariff: (f = msg.getEnergyTariff()) && types_tariff_pb.EnergyTariff.toObject(includeInstance, f),
    waterTariff: (f = msg.getWaterTariff()) && types_tariff_pb.WaterTariff.toObject(includeInstance, f),
    gasTariff: (f = msg.getGasTariff()) && types_tariff_pb.GasTariff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCurrentTariffResponse}
 */
proto.services.central.GetCurrentTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCurrentTariffResponse;
  return proto.services.central.GetCurrentTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCurrentTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCurrentTariffResponse}
 */
proto.services.central.GetCurrentTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.setEnergyTariff(value);
      break;
    case 2:
      var value = new types_tariff_pb.WaterTariff;
      reader.readMessage(value,types_tariff_pb.WaterTariff.deserializeBinaryFromReader);
      msg.setWaterTariff(value);
      break;
    case 3:
      var value = new types_tariff_pb.GasTariff;
      reader.readMessage(value,types_tariff_pb.GasTariff.deserializeBinaryFromReader);
      msg.setGasTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCurrentTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCurrentTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCurrentTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
  f = message.getWaterTariff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_tariff_pb.WaterTariff.serializeBinaryToWriter
    );
  }
  f = message.getGasTariff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_tariff_pb.GasTariff.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.EnergyTariff energy_tariff = 1;
 * @return {?proto.types.EnergyTariff}
 */
proto.services.central.GetCurrentTariffResponse.prototype.getEnergyTariff = function() {
  return /** @type{?proto.types.EnergyTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.EnergyTariff, 1));
};


/**
 * @param {?proto.types.EnergyTariff|undefined} value
 * @return {!proto.services.central.GetCurrentTariffResponse} returns this
*/
proto.services.central.GetCurrentTariffResponse.prototype.setEnergyTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.GetCurrentTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCurrentTariffResponse} returns this
 */
proto.services.central.GetCurrentTariffResponse.prototype.clearEnergyTariff = function() {
  return this.setEnergyTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCurrentTariffResponse.prototype.hasEnergyTariff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional types.WaterTariff water_tariff = 2;
 * @return {?proto.types.WaterTariff}
 */
proto.services.central.GetCurrentTariffResponse.prototype.getWaterTariff = function() {
  return /** @type{?proto.types.WaterTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.WaterTariff, 2));
};


/**
 * @param {?proto.types.WaterTariff|undefined} value
 * @return {!proto.services.central.GetCurrentTariffResponse} returns this
*/
proto.services.central.GetCurrentTariffResponse.prototype.setWaterTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.GetCurrentTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCurrentTariffResponse} returns this
 */
proto.services.central.GetCurrentTariffResponse.prototype.clearWaterTariff = function() {
  return this.setWaterTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCurrentTariffResponse.prototype.hasWaterTariff = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional types.GasTariff gas_tariff = 3;
 * @return {?proto.types.GasTariff}
 */
proto.services.central.GetCurrentTariffResponse.prototype.getGasTariff = function() {
  return /** @type{?proto.types.GasTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.GasTariff, 3));
};


/**
 * @param {?proto.types.GasTariff|undefined} value
 * @return {!proto.services.central.GetCurrentTariffResponse} returns this
*/
proto.services.central.GetCurrentTariffResponse.prototype.setGasTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.GetCurrentTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCurrentTariffResponse} returns this
 */
proto.services.central.GetCurrentTariffResponse.prototype.clearGasTariff = function() {
  return this.setGasTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCurrentTariffResponse.prototype.hasGasTariff = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.AddTariffRequest.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.services.central.AddTariffRequest.TariffCase = {
  TARIFF_NOT_SET: 0,
  ENERGY_TARIFF: 3,
  WATER_TARIFF: 4,
  GAS_TARIFF: 5
};

/**
 * @return {proto.services.central.AddTariffRequest.TariffCase}
 */
proto.services.central.AddTariffRequest.prototype.getTariffCase = function() {
  return /** @type {proto.services.central.AddTariffRequest.TariffCase} */(jspb.Message.computeOneofCase(this, proto.services.central.AddTariffRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedYearlyConsumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    energyTariff: (f = msg.getEnergyTariff()) && types_tariff_pb.EnergyTariff.toObject(includeInstance, f),
    waterTariff: (f = msg.getWaterTariff()) && types_tariff_pb.WaterTariff.toObject(includeInstance, f),
    gasTariff: (f = msg.getGasTariff()) && types_tariff_pb.GasTariff.toObject(includeInstance, f),
    newAdvancePayment: (f = msg.getNewAdvancePayment()) && proto.services.central.AdvancePayment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddTariffRequest}
 */
proto.services.central.AddTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddTariffRequest;
  return proto.services.central.AddTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddTariffRequest}
 */
proto.services.central.AddTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEstimatedYearlyConsumption(value);
      break;
    case 3:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.setEnergyTariff(value);
      break;
    case 4:
      var value = new types_tariff_pb.WaterTariff;
      reader.readMessage(value,types_tariff_pb.WaterTariff.deserializeBinaryFromReader);
      msg.setWaterTariff(value);
      break;
    case 5:
      var value = new types_tariff_pb.GasTariff;
      reader.readMessage(value,types_tariff_pb.GasTariff.deserializeBinaryFromReader);
      msg.setGasTariff(value);
      break;
    case 6:
      var value = new proto.services.central.AdvancePayment;
      reader.readMessage(value,proto.services.central.AdvancePayment.deserializeBinaryFromReader);
      msg.setNewAdvancePayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEnergyTariff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
  f = message.getWaterTariff();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      types_tariff_pb.WaterTariff.serializeBinaryToWriter
    );
  }
  f = message.getGasTariff();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      types_tariff_pb.GasTariff.serializeBinaryToWriter
    );
  }
  f = message.getNewAdvancePayment();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.services.central.AdvancePayment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.AddTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double estimated_yearly_consumption = 2;
 * @return {number}
 */
proto.services.central.AddTariffRequest.prototype.getEstimatedYearlyConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.setEstimatedYearlyConsumption = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.clearEstimatedYearlyConsumption = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffRequest.prototype.hasEstimatedYearlyConsumption = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional types.EnergyTariff energy_tariff = 3;
 * @return {?proto.types.EnergyTariff}
 */
proto.services.central.AddTariffRequest.prototype.getEnergyTariff = function() {
  return /** @type{?proto.types.EnergyTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.EnergyTariff, 3));
};


/**
 * @param {?proto.types.EnergyTariff|undefined} value
 * @return {!proto.services.central.AddTariffRequest} returns this
*/
proto.services.central.AddTariffRequest.prototype.setEnergyTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.AddTariffRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.clearEnergyTariff = function() {
  return this.setEnergyTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffRequest.prototype.hasEnergyTariff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional types.WaterTariff water_tariff = 4;
 * @return {?proto.types.WaterTariff}
 */
proto.services.central.AddTariffRequest.prototype.getWaterTariff = function() {
  return /** @type{?proto.types.WaterTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.WaterTariff, 4));
};


/**
 * @param {?proto.types.WaterTariff|undefined} value
 * @return {!proto.services.central.AddTariffRequest} returns this
*/
proto.services.central.AddTariffRequest.prototype.setWaterTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.services.central.AddTariffRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.clearWaterTariff = function() {
  return this.setWaterTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffRequest.prototype.hasWaterTariff = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional types.GasTariff gas_tariff = 5;
 * @return {?proto.types.GasTariff}
 */
proto.services.central.AddTariffRequest.prototype.getGasTariff = function() {
  return /** @type{?proto.types.GasTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.GasTariff, 5));
};


/**
 * @param {?proto.types.GasTariff|undefined} value
 * @return {!proto.services.central.AddTariffRequest} returns this
*/
proto.services.central.AddTariffRequest.prototype.setGasTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.services.central.AddTariffRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.clearGasTariff = function() {
  return this.setGasTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffRequest.prototype.hasGasTariff = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AdvancePayment new_advance_payment = 6;
 * @return {?proto.services.central.AdvancePayment}
 */
proto.services.central.AddTariffRequest.prototype.getNewAdvancePayment = function() {
  return /** @type{?proto.services.central.AdvancePayment} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AdvancePayment, 6));
};


/**
 * @param {?proto.services.central.AdvancePayment|undefined} value
 * @return {!proto.services.central.AddTariffRequest} returns this
*/
proto.services.central.AddTariffRequest.prototype.setNewAdvancePayment = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffRequest} returns this
 */
proto.services.central.AddTariffRequest.prototype.clearNewAdvancePayment = function() {
  return this.setNewAdvancePayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffRequest.prototype.hasNewAdvancePayment = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.AddTariffResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.services.central.AddTariffResponse.TariffsCase = {
  TARIFFS_NOT_SET: 0,
  ENERGY_TARIFFS_RESPONSE: 1,
  WATER_TARIFFS_RESPONSE: 2,
  GAS_TARIFFS_RESPONSE: 3
};

/**
 * @return {proto.services.central.AddTariffResponse.TariffsCase}
 */
proto.services.central.AddTariffResponse.prototype.getTariffsCase = function() {
  return /** @type {proto.services.central.AddTariffResponse.TariffsCase} */(jspb.Message.computeOneofCase(this, proto.services.central.AddTariffResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsResponse: (f = msg.getEnergyTariffsResponse()) && proto.services.central.EnergyTariffsResponse.toObject(includeInstance, f),
    waterTariffsResponse: (f = msg.getWaterTariffsResponse()) && proto.services.central.WaterTariffsResponse.toObject(includeInstance, f),
    gasTariffsResponse: (f = msg.getGasTariffsResponse()) && proto.services.central.GasTariffsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddTariffResponse}
 */
proto.services.central.AddTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddTariffResponse;
  return proto.services.central.AddTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddTariffResponse}
 */
proto.services.central.AddTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.EnergyTariffsResponse;
      reader.readMessage(value,proto.services.central.EnergyTariffsResponse.deserializeBinaryFromReader);
      msg.setEnergyTariffsResponse(value);
      break;
    case 2:
      var value = new proto.services.central.WaterTariffsResponse;
      reader.readMessage(value,proto.services.central.WaterTariffsResponse.deserializeBinaryFromReader);
      msg.setWaterTariffsResponse(value);
      break;
    case 3:
      var value = new proto.services.central.GasTariffsResponse;
      reader.readMessage(value,proto.services.central.GasTariffsResponse.deserializeBinaryFromReader);
      msg.setGasTariffsResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.EnergyTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getWaterTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.WaterTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getGasTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.central.GasTariffsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnergyTariffsResponse energy_tariffs_response = 1;
 * @return {?proto.services.central.EnergyTariffsResponse}
 */
proto.services.central.AddTariffResponse.prototype.getEnergyTariffsResponse = function() {
  return /** @type{?proto.services.central.EnergyTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.EnergyTariffsResponse, 1));
};


/**
 * @param {?proto.services.central.EnergyTariffsResponse|undefined} value
 * @return {!proto.services.central.AddTariffResponse} returns this
*/
proto.services.central.AddTariffResponse.prototype.setEnergyTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.AddTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffResponse} returns this
 */
proto.services.central.AddTariffResponse.prototype.clearEnergyTariffsResponse = function() {
  return this.setEnergyTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffResponse.prototype.hasEnergyTariffsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WaterTariffsResponse water_tariffs_response = 2;
 * @return {?proto.services.central.WaterTariffsResponse}
 */
proto.services.central.AddTariffResponse.prototype.getWaterTariffsResponse = function() {
  return /** @type{?proto.services.central.WaterTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.WaterTariffsResponse, 2));
};


/**
 * @param {?proto.services.central.WaterTariffsResponse|undefined} value
 * @return {!proto.services.central.AddTariffResponse} returns this
*/
proto.services.central.AddTariffResponse.prototype.setWaterTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.AddTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffResponse} returns this
 */
proto.services.central.AddTariffResponse.prototype.clearWaterTariffsResponse = function() {
  return this.setWaterTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffResponse.prototype.hasWaterTariffsResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GasTariffsResponse gas_tariffs_response = 3;
 * @return {?proto.services.central.GasTariffsResponse}
 */
proto.services.central.AddTariffResponse.prototype.getGasTariffsResponse = function() {
  return /** @type{?proto.services.central.GasTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GasTariffsResponse, 3));
};


/**
 * @param {?proto.services.central.GasTariffsResponse|undefined} value
 * @return {!proto.services.central.AddTariffResponse} returns this
*/
proto.services.central.AddTariffResponse.prototype.setGasTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.AddTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddTariffResponse} returns this
 */
proto.services.central.AddTariffResponse.prototype.clearGasTariffsResponse = function() {
  return this.setGasTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddTariffResponse.prototype.hasGasTariffsResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.UpdateTariffRequest.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.services.central.UpdateTariffRequest.TariffCase = {
  TARIFF_NOT_SET: 0,
  ENERGY_TARIFF: 2,
  WATER_TARIFF: 3,
  GAS_TARIFF: 4
};

/**
 * @return {proto.services.central.UpdateTariffRequest.TariffCase}
 */
proto.services.central.UpdateTariffRequest.prototype.getTariffCase = function() {
  return /** @type {proto.services.central.UpdateTariffRequest.TariffCase} */(jspb.Message.computeOneofCase(this, proto.services.central.UpdateTariffRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    energyTariff: (f = msg.getEnergyTariff()) && types_tariff_pb.EnergyTariff.toObject(includeInstance, f),
    waterTariff: (f = msg.getWaterTariff()) && types_tariff_pb.WaterTariff.toObject(includeInstance, f),
    gasTariff: (f = msg.getGasTariff()) && types_tariff_pb.GasTariff.toObject(includeInstance, f),
    newAdvancePayment: (f = msg.getNewAdvancePayment()) && proto.services.central.AdvancePayment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateTariffRequest}
 */
proto.services.central.UpdateTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateTariffRequest;
  return proto.services.central.UpdateTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateTariffRequest}
 */
proto.services.central.UpdateTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.setEnergyTariff(value);
      break;
    case 3:
      var value = new types_tariff_pb.WaterTariff;
      reader.readMessage(value,types_tariff_pb.WaterTariff.deserializeBinaryFromReader);
      msg.setWaterTariff(value);
      break;
    case 4:
      var value = new types_tariff_pb.GasTariff;
      reader.readMessage(value,types_tariff_pb.GasTariff.deserializeBinaryFromReader);
      msg.setGasTariff(value);
      break;
    case 5:
      var value = new proto.services.central.AdvancePayment;
      reader.readMessage(value,proto.services.central.AdvancePayment.deserializeBinaryFromReader);
      msg.setNewAdvancePayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnergyTariff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
  f = message.getWaterTariff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_tariff_pb.WaterTariff.serializeBinaryToWriter
    );
  }
  f = message.getGasTariff();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      types_tariff_pb.GasTariff.serializeBinaryToWriter
    );
  }
  f = message.getNewAdvancePayment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.services.central.AdvancePayment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.UpdateTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateTariffRequest} returns this
 */
proto.services.central.UpdateTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.EnergyTariff energy_tariff = 2;
 * @return {?proto.types.EnergyTariff}
 */
proto.services.central.UpdateTariffRequest.prototype.getEnergyTariff = function() {
  return /** @type{?proto.types.EnergyTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.EnergyTariff, 2));
};


/**
 * @param {?proto.types.EnergyTariff|undefined} value
 * @return {!proto.services.central.UpdateTariffRequest} returns this
*/
proto.services.central.UpdateTariffRequest.prototype.setEnergyTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.UpdateTariffRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffRequest} returns this
 */
proto.services.central.UpdateTariffRequest.prototype.clearEnergyTariff = function() {
  return this.setEnergyTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffRequest.prototype.hasEnergyTariff = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional types.WaterTariff water_tariff = 3;
 * @return {?proto.types.WaterTariff}
 */
proto.services.central.UpdateTariffRequest.prototype.getWaterTariff = function() {
  return /** @type{?proto.types.WaterTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.WaterTariff, 3));
};


/**
 * @param {?proto.types.WaterTariff|undefined} value
 * @return {!proto.services.central.UpdateTariffRequest} returns this
*/
proto.services.central.UpdateTariffRequest.prototype.setWaterTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.UpdateTariffRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffRequest} returns this
 */
proto.services.central.UpdateTariffRequest.prototype.clearWaterTariff = function() {
  return this.setWaterTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffRequest.prototype.hasWaterTariff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional types.GasTariff gas_tariff = 4;
 * @return {?proto.types.GasTariff}
 */
proto.services.central.UpdateTariffRequest.prototype.getGasTariff = function() {
  return /** @type{?proto.types.GasTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.GasTariff, 4));
};


/**
 * @param {?proto.types.GasTariff|undefined} value
 * @return {!proto.services.central.UpdateTariffRequest} returns this
*/
proto.services.central.UpdateTariffRequest.prototype.setGasTariff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.services.central.UpdateTariffRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffRequest} returns this
 */
proto.services.central.UpdateTariffRequest.prototype.clearGasTariff = function() {
  return this.setGasTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffRequest.prototype.hasGasTariff = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AdvancePayment new_advance_payment = 5;
 * @return {?proto.services.central.AdvancePayment}
 */
proto.services.central.UpdateTariffRequest.prototype.getNewAdvancePayment = function() {
  return /** @type{?proto.services.central.AdvancePayment} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AdvancePayment, 5));
};


/**
 * @param {?proto.services.central.AdvancePayment|undefined} value
 * @return {!proto.services.central.UpdateTariffRequest} returns this
*/
proto.services.central.UpdateTariffRequest.prototype.setNewAdvancePayment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffRequest} returns this
 */
proto.services.central.UpdateTariffRequest.prototype.clearNewAdvancePayment = function() {
  return this.setNewAdvancePayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffRequest.prototype.hasNewAdvancePayment = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.UpdateTariffResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.services.central.UpdateTariffResponse.TariffsCase = {
  TARIFFS_NOT_SET: 0,
  ENERGY_TARIFFS_RESPONSE: 1,
  WATER_TARIFFS_RESPONSE: 2,
  GAS_TARIFFS_RESPONSE: 3
};

/**
 * @return {proto.services.central.UpdateTariffResponse.TariffsCase}
 */
proto.services.central.UpdateTariffResponse.prototype.getTariffsCase = function() {
  return /** @type {proto.services.central.UpdateTariffResponse.TariffsCase} */(jspb.Message.computeOneofCase(this, proto.services.central.UpdateTariffResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsResponse: (f = msg.getEnergyTariffsResponse()) && proto.services.central.EnergyTariffsResponse.toObject(includeInstance, f),
    waterTariffsResponse: (f = msg.getWaterTariffsResponse()) && proto.services.central.WaterTariffsResponse.toObject(includeInstance, f),
    gasTariffsResponse: (f = msg.getGasTariffsResponse()) && proto.services.central.GasTariffsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateTariffResponse}
 */
proto.services.central.UpdateTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateTariffResponse;
  return proto.services.central.UpdateTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateTariffResponse}
 */
proto.services.central.UpdateTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.EnergyTariffsResponse;
      reader.readMessage(value,proto.services.central.EnergyTariffsResponse.deserializeBinaryFromReader);
      msg.setEnergyTariffsResponse(value);
      break;
    case 2:
      var value = new proto.services.central.WaterTariffsResponse;
      reader.readMessage(value,proto.services.central.WaterTariffsResponse.deserializeBinaryFromReader);
      msg.setWaterTariffsResponse(value);
      break;
    case 3:
      var value = new proto.services.central.GasTariffsResponse;
      reader.readMessage(value,proto.services.central.GasTariffsResponse.deserializeBinaryFromReader);
      msg.setGasTariffsResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.central.EnergyTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getWaterTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.central.WaterTariffsResponse.serializeBinaryToWriter
    );
  }
  f = message.getGasTariffsResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.central.GasTariffsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnergyTariffsResponse energy_tariffs_response = 1;
 * @return {?proto.services.central.EnergyTariffsResponse}
 */
proto.services.central.UpdateTariffResponse.prototype.getEnergyTariffsResponse = function() {
  return /** @type{?proto.services.central.EnergyTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.EnergyTariffsResponse, 1));
};


/**
 * @param {?proto.services.central.EnergyTariffsResponse|undefined} value
 * @return {!proto.services.central.UpdateTariffResponse} returns this
*/
proto.services.central.UpdateTariffResponse.prototype.setEnergyTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.services.central.UpdateTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffResponse} returns this
 */
proto.services.central.UpdateTariffResponse.prototype.clearEnergyTariffsResponse = function() {
  return this.setEnergyTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffResponse.prototype.hasEnergyTariffsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WaterTariffsResponse water_tariffs_response = 2;
 * @return {?proto.services.central.WaterTariffsResponse}
 */
proto.services.central.UpdateTariffResponse.prototype.getWaterTariffsResponse = function() {
  return /** @type{?proto.services.central.WaterTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.WaterTariffsResponse, 2));
};


/**
 * @param {?proto.services.central.WaterTariffsResponse|undefined} value
 * @return {!proto.services.central.UpdateTariffResponse} returns this
*/
proto.services.central.UpdateTariffResponse.prototype.setWaterTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.central.UpdateTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffResponse} returns this
 */
proto.services.central.UpdateTariffResponse.prototype.clearWaterTariffsResponse = function() {
  return this.setWaterTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffResponse.prototype.hasWaterTariffsResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GasTariffsResponse gas_tariffs_response = 3;
 * @return {?proto.services.central.GasTariffsResponse}
 */
proto.services.central.UpdateTariffResponse.prototype.getGasTariffsResponse = function() {
  return /** @type{?proto.services.central.GasTariffsResponse} */ (
    jspb.Message.getWrapperField(this, proto.services.central.GasTariffsResponse, 3));
};


/**
 * @param {?proto.services.central.GasTariffsResponse|undefined} value
 * @return {!proto.services.central.UpdateTariffResponse} returns this
*/
proto.services.central.UpdateTariffResponse.prototype.setGasTariffsResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.central.UpdateTariffResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateTariffResponse} returns this
 */
proto.services.central.UpdateTariffResponse.prototype.clearGasTariffsResponse = function() {
  return this.setGasTariffsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateTariffResponse.prototype.hasGasTariffsResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tariffId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteTariffRequest}
 */
proto.services.central.DeleteTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteTariffRequest;
  return proto.services.central.DeleteTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteTariffRequest}
 */
proto.services.central.DeleteTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTariffId(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTariffId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.DeleteTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteTariffRequest} returns this
 */
proto.services.central.DeleteTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tariff_id = 2;
 * @return {string}
 */
proto.services.central.DeleteTariffRequest.prototype.getTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteTariffRequest} returns this
 */
proto.services.central.DeleteTariffRequest.prototype.setTariffId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional types.Device.Medium medium = 3;
 * @return {!proto.types.Device.Medium}
 */
proto.services.central.DeleteTariffRequest.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.services.central.DeleteTariffRequest} returns this
 */
proto.services.central.DeleteTariffRequest.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteTariffResponse}
 */
proto.services.central.DeleteTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteTariffResponse;
  return proto.services.central.DeleteTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteTariffResponse}
 */
proto.services.central.DeleteTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetEstimatedConsumptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetEstimatedConsumptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetEstimatedConsumptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEstimatedConsumptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetEstimatedConsumptionRequest}
 */
proto.services.central.GetEstimatedConsumptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetEstimatedConsumptionRequest;
  return proto.services.central.GetEstimatedConsumptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetEstimatedConsumptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetEstimatedConsumptionRequest}
 */
proto.services.central.GetEstimatedConsumptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetEstimatedConsumptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetEstimatedConsumptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetEstimatedConsumptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEstimatedConsumptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetEstimatedConsumptionRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetEstimatedConsumptionRequest} returns this
 */
proto.services.central.GetEstimatedConsumptionRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetEstimatedConsumptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetEstimatedConsumptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetEstimatedConsumptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEstimatedConsumptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimatedConsumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetEstimatedConsumptionResponse}
 */
proto.services.central.GetEstimatedConsumptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetEstimatedConsumptionResponse;
  return proto.services.central.GetEstimatedConsumptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetEstimatedConsumptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetEstimatedConsumptionResponse}
 */
proto.services.central.GetEstimatedConsumptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEstimatedConsumption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetEstimatedConsumptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetEstimatedConsumptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetEstimatedConsumptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEstimatedConsumptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double estimated_consumption = 1;
 * @return {number}
 */
proto.services.central.GetEstimatedConsumptionResponse.prototype.getEstimatedConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.GetEstimatedConsumptionResponse} returns this
 */
proto.services.central.GetEstimatedConsumptionResponse.prototype.setEstimatedConsumption = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetEstimatedConsumptionResponse} returns this
 */
proto.services.central.GetEstimatedConsumptionResponse.prototype.clearEstimatedConsumption = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetEstimatedConsumptionResponse.prototype.hasEstimatedConsumption = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetEstimatedConsumptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetEstimatedConsumptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetEstimatedConsumptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEstimatedConsumptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedConsumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetEstimatedConsumptionRequest}
 */
proto.services.central.SetEstimatedConsumptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetEstimatedConsumptionRequest;
  return proto.services.central.SetEstimatedConsumptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetEstimatedConsumptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetEstimatedConsumptionRequest}
 */
proto.services.central.SetEstimatedConsumptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEstimatedConsumption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetEstimatedConsumptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetEstimatedConsumptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetEstimatedConsumptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEstimatedConsumptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedConsumption();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.SetEstimatedConsumptionRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SetEstimatedConsumptionRequest} returns this
 */
proto.services.central.SetEstimatedConsumptionRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double estimated_consumption = 2;
 * @return {number}
 */
proto.services.central.SetEstimatedConsumptionRequest.prototype.getEstimatedConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.SetEstimatedConsumptionRequest} returns this
 */
proto.services.central.SetEstimatedConsumptionRequest.prototype.setEstimatedConsumption = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetEstimatedConsumptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetEstimatedConsumptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetEstimatedConsumptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEstimatedConsumptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetEstimatedConsumptionResponse}
 */
proto.services.central.SetEstimatedConsumptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetEstimatedConsumptionResponse;
  return proto.services.central.SetEstimatedConsumptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetEstimatedConsumptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetEstimatedConsumptionResponse}
 */
proto.services.central.SetEstimatedConsumptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetEstimatedConsumptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetEstimatedConsumptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetEstimatedConsumptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetEstimatedConsumptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSearchResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSearchResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSearchResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSearchResultsRequest}
 */
proto.services.central.GetSearchResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSearchResultsRequest;
  return proto.services.central.GetSearchResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSearchResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSearchResultsRequest}
 */
proto.services.central.GetSearchResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSearchResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSearchResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSearchResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetSearchResultsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSearchResultsRequest} returns this
 */
proto.services.central.GetSearchResultsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetSearchResultsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSearchResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSearchResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSearchResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchResultsList: jspb.Message.toObjectList(msg.getSearchResultsList(),
    types_tariff_pb.SearchResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSearchResultsResponse}
 */
proto.services.central.GetSearchResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSearchResultsResponse;
  return proto.services.central.GetSearchResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSearchResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSearchResultsResponse}
 */
proto.services.central.GetSearchResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.SearchResult;
      reader.readMessage(value,types_tariff_pb.SearchResult.deserializeBinaryFromReader);
      msg.addSearchResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSearchResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSearchResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSearchResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.SearchResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.SearchResult search_results = 1;
 * @return {!Array<!proto.types.SearchResult>}
 */
proto.services.central.GetSearchResultsResponse.prototype.getSearchResultsList = function() {
  return /** @type{!Array<!proto.types.SearchResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.SearchResult, 1));
};


/**
 * @param {!Array<!proto.types.SearchResult>} value
 * @return {!proto.services.central.GetSearchResultsResponse} returns this
*/
proto.services.central.GetSearchResultsResponse.prototype.setSearchResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.SearchResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SearchResult}
 */
proto.services.central.GetSearchResultsResponse.prototype.addSearchResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.SearchResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetSearchResultsResponse} returns this
 */
proto.services.central.GetSearchResultsResponse.prototype.clearSearchResultsList = function() {
  return this.setSearchResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetPendingSwitchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetPendingSwitchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetPendingSwitchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPendingSwitchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetPendingSwitchRequest}
 */
proto.services.central.GetPendingSwitchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetPendingSwitchRequest;
  return proto.services.central.GetPendingSwitchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetPendingSwitchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetPendingSwitchRequest}
 */
proto.services.central.GetPendingSwitchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetPendingSwitchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetPendingSwitchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetPendingSwitchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPendingSwitchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetPendingSwitchRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetPendingSwitchRequest} returns this
 */
proto.services.central.GetPendingSwitchRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetPendingSwitchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetPendingSwitchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetPendingSwitchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPendingSwitchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingSwitch: (f = msg.getPendingSwitch()) && types_tariff_pb.TariffSwitch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetPendingSwitchResponse}
 */
proto.services.central.GetPendingSwitchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetPendingSwitchResponse;
  return proto.services.central.GetPendingSwitchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetPendingSwitchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetPendingSwitchResponse}
 */
proto.services.central.GetPendingSwitchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.TariffSwitch;
      reader.readMessage(value,types_tariff_pb.TariffSwitch.deserializeBinaryFromReader);
      msg.setPendingSwitch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetPendingSwitchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetPendingSwitchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetPendingSwitchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetPendingSwitchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingSwitch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_tariff_pb.TariffSwitch.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.TariffSwitch pending_switch = 1;
 * @return {?proto.types.TariffSwitch}
 */
proto.services.central.GetPendingSwitchResponse.prototype.getPendingSwitch = function() {
  return /** @type{?proto.types.TariffSwitch} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.TariffSwitch, 1));
};


/**
 * @param {?proto.types.TariffSwitch|undefined} value
 * @return {!proto.services.central.GetPendingSwitchResponse} returns this
*/
proto.services.central.GetPendingSwitchResponse.prototype.setPendingSwitch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetPendingSwitchResponse} returns this
 */
proto.services.central.GetPendingSwitchResponse.prototype.clearPendingSwitch = function() {
  return this.setPendingSwitch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetPendingSwitchResponse.prototype.hasPendingSwitch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSearchInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSearchInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSearchInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSearchInfoRequest}
 */
proto.services.central.GetSearchInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSearchInfoRequest;
  return proto.services.central.GetSearchInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSearchInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSearchInfoRequest}
 */
proto.services.central.GetSearchInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSearchInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSearchInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSearchInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetSearchInfoRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetSearchInfoRequest} returns this
 */
proto.services.central.GetSearchInfoRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetSearchInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetSearchInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetSearchInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchInfo: (f = msg.getSearchInfo()) && types_tariff_pb.TariffSearchInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetSearchInfoResponse}
 */
proto.services.central.GetSearchInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetSearchInfoResponse;
  return proto.services.central.GetSearchInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetSearchInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetSearchInfoResponse}
 */
proto.services.central.GetSearchInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.TariffSearchInfo;
      reader.readMessage(value,types_tariff_pb.TariffSearchInfo.deserializeBinaryFromReader);
      msg.setSearchInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetSearchInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetSearchInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetSearchInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetSearchInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_tariff_pb.TariffSearchInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.TariffSearchInfo search_info = 1;
 * @return {?proto.types.TariffSearchInfo}
 */
proto.services.central.GetSearchInfoResponse.prototype.getSearchInfo = function() {
  return /** @type{?proto.types.TariffSearchInfo} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.TariffSearchInfo, 1));
};


/**
 * @param {?proto.types.TariffSearchInfo|undefined} value
 * @return {!proto.services.central.GetSearchInfoResponse} returns this
*/
proto.services.central.GetSearchInfoResponse.prototype.setSearchInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetSearchInfoResponse} returns this
 */
proto.services.central.GetSearchInfoResponse.prototype.clearSearchInfo = function() {
  return this.setSearchInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetSearchInfoResponse.prototype.hasSearchInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetIsWatchdogEnabledRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetIsWatchdogEnabledRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetIsWatchdogEnabledRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isWatchdogEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    sepaDetails: (f = msg.getSepaDetails()) && types_tariff_pb.SepaDetails.toObject(includeInstance, f),
    meterId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest}
 */
proto.services.central.SetIsWatchdogEnabledRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetIsWatchdogEnabledRequest;
  return proto.services.central.SetIsWatchdogEnabledRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetIsWatchdogEnabledRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest}
 */
proto.services.central.SetIsWatchdogEnabledRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWatchdogEnabled(value);
      break;
    case 3:
      var value = new types_tariff_pb.SepaDetails;
      reader.readMessage(value,types_tariff_pb.SepaDetails.deserializeBinaryFromReader);
      msg.setSepaDetails(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetIsWatchdogEnabledRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetIsWatchdogEnabledRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetIsWatchdogEnabledRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsWatchdogEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSepaDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_tariff_pb.SepaDetails.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest} returns this
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_watchdog_enabled = 2;
 * @return {boolean}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.getIsWatchdogEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest} returns this
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.setIsWatchdogEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional types.SepaDetails sepa_details = 3;
 * @return {?proto.types.SepaDetails}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.getSepaDetails = function() {
  return /** @type{?proto.types.SepaDetails} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.SepaDetails, 3));
};


/**
 * @param {?proto.types.SepaDetails|undefined} value
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest} returns this
*/
proto.services.central.SetIsWatchdogEnabledRequest.prototype.setSepaDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest} returns this
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.clearSepaDetails = function() {
  return this.setSepaDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.hasSepaDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string meter_id = 4;
 * @return {string}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.getMeterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest} returns this
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.setMeterId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.SetIsWatchdogEnabledRequest} returns this
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.clearMeterId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.SetIsWatchdogEnabledRequest.prototype.hasMeterId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SetIsWatchdogEnabledResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SetIsWatchdogEnabledResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SetIsWatchdogEnabledResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetIsWatchdogEnabledResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SetIsWatchdogEnabledResponse}
 */
proto.services.central.SetIsWatchdogEnabledResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SetIsWatchdogEnabledResponse;
  return proto.services.central.SetIsWatchdogEnabledResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SetIsWatchdogEnabledResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SetIsWatchdogEnabledResponse}
 */
proto.services.central.SetIsWatchdogEnabledResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SetIsWatchdogEnabledResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SetIsWatchdogEnabledResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SetIsWatchdogEnabledResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SetIsWatchdogEnabledResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCharityProjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCharityProjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCharityProjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCharityProjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCharityProjectsRequest}
 */
proto.services.central.GetCharityProjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCharityProjectsRequest;
  return proto.services.central.GetCharityProjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCharityProjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCharityProjectsRequest}
 */
proto.services.central.GetCharityProjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCharityProjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCharityProjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCharityProjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCharityProjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetCharityProjectsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCharityProjectsRequest} returns this
 */
proto.services.central.GetCharityProjectsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetCharityProjectsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCharityProjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCharityProjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCharityProjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCharityProjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    charityProjectsList: jspb.Message.toObjectList(msg.getCharityProjectsList(),
    types_tariff_pb.CharityProject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCharityProjectsResponse}
 */
proto.services.central.GetCharityProjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCharityProjectsResponse;
  return proto.services.central.GetCharityProjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCharityProjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCharityProjectsResponse}
 */
proto.services.central.GetCharityProjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.CharityProject;
      reader.readMessage(value,types_tariff_pb.CharityProject.deserializeBinaryFromReader);
      msg.addCharityProjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCharityProjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCharityProjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCharityProjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCharityProjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCharityProjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.CharityProject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.CharityProject charity_projects = 1;
 * @return {!Array<!proto.types.CharityProject>}
 */
proto.services.central.GetCharityProjectsResponse.prototype.getCharityProjectsList = function() {
  return /** @type{!Array<!proto.types.CharityProject>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.CharityProject, 1));
};


/**
 * @param {!Array<!proto.types.CharityProject>} value
 * @return {!proto.services.central.GetCharityProjectsResponse} returns this
*/
proto.services.central.GetCharityProjectsResponse.prototype.setCharityProjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.CharityProject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.CharityProject}
 */
proto.services.central.GetCharityProjectsResponse.prototype.addCharityProjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.CharityProject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetCharityProjectsResponse} returns this
 */
proto.services.central.GetCharityProjectsResponse.prototype.clearCharityProjectsList = function() {
  return this.setCharityProjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SelectCharityProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SelectCharityProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SelectCharityProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SelectCharityProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SelectCharityProjectRequest}
 */
proto.services.central.SelectCharityProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SelectCharityProjectRequest;
  return proto.services.central.SelectCharityProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SelectCharityProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SelectCharityProjectRequest}
 */
proto.services.central.SelectCharityProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SelectCharityProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SelectCharityProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SelectCharityProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SelectCharityProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.SelectCharityProjectRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SelectCharityProjectRequest} returns this
 */
proto.services.central.SelectCharityProjectRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.services.central.SelectCharityProjectRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.SelectCharityProjectRequest} returns this
 */
proto.services.central.SelectCharityProjectRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.SelectCharityProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.SelectCharityProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.SelectCharityProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.SelectCharityProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SelectCharityProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    charityProjectsList: jspb.Message.toObjectList(msg.getCharityProjectsList(),
    types_tariff_pb.CharityProject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.SelectCharityProjectResponse}
 */
proto.services.central.SelectCharityProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.SelectCharityProjectResponse;
  return proto.services.central.SelectCharityProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.SelectCharityProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.SelectCharityProjectResponse}
 */
proto.services.central.SelectCharityProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.CharityProject;
      reader.readMessage(value,types_tariff_pb.CharityProject.deserializeBinaryFromReader);
      msg.addCharityProjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.SelectCharityProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.SelectCharityProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.SelectCharityProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.SelectCharityProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCharityProjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.CharityProject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.CharityProject charity_projects = 1;
 * @return {!Array<!proto.types.CharityProject>}
 */
proto.services.central.SelectCharityProjectResponse.prototype.getCharityProjectsList = function() {
  return /** @type{!Array<!proto.types.CharityProject>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.CharityProject, 1));
};


/**
 * @param {!Array<!proto.types.CharityProject>} value
 * @return {!proto.services.central.SelectCharityProjectResponse} returns this
*/
proto.services.central.SelectCharityProjectResponse.prototype.setCharityProjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.CharityProject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.CharityProject}
 */
proto.services.central.SelectCharityProjectResponse.prototype.addCharityProjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.CharityProject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.SelectCharityProjectResponse} returns this
 */
proto.services.central.SelectCharityProjectResponse.prototype.clearCharityProjectsList = function() {
  return this.setCharityProjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateSupplierCustomerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateSupplierCustomerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateSupplierCustomerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateSupplierCustomerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierCustomerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateSupplierCustomerIdRequest}
 */
proto.services.central.UpdateSupplierCustomerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateSupplierCustomerIdRequest;
  return proto.services.central.UpdateSupplierCustomerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateSupplierCustomerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateSupplierCustomerIdRequest}
 */
proto.services.central.UpdateSupplierCustomerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyTariffId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateSupplierCustomerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateSupplierCustomerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateSupplierCustomerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateSupplierCustomerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string energy_tariff_id = 1;
 * @return {string}
 */
proto.services.central.UpdateSupplierCustomerIdRequest.prototype.getEnergyTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateSupplierCustomerIdRequest} returns this
 */
proto.services.central.UpdateSupplierCustomerIdRequest.prototype.setEnergyTariffId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_customer_id = 2;
 * @return {string}
 */
proto.services.central.UpdateSupplierCustomerIdRequest.prototype.getSupplierCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateSupplierCustomerIdRequest} returns this
 */
proto.services.central.UpdateSupplierCustomerIdRequest.prototype.setSupplierCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateSupplierCustomerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateSupplierCustomerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateSupplierCustomerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateSupplierCustomerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateSupplierCustomerIdResponse}
 */
proto.services.central.UpdateSupplierCustomerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateSupplierCustomerIdResponse;
  return proto.services.central.UpdateSupplierCustomerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateSupplierCustomerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateSupplierCustomerIdResponse}
 */
proto.services.central.UpdateSupplierCustomerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateSupplierCustomerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateSupplierCustomerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateSupplierCustomerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateSupplierCustomerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetEnergyTariffsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetEnergyTariffsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetEnergyTariffsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyTariffsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetEnergyTariffsRequest}
 */
proto.services.central.GetEnergyTariffsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetEnergyTariffsRequest;
  return proto.services.central.GetEnergyTariffsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetEnergyTariffsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetEnergyTariffsRequest}
 */
proto.services.central.GetEnergyTariffsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetEnergyTariffsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetEnergyTariffsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetEnergyTariffsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyTariffsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetEnergyTariffsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetEnergyTariffsRequest} returns this
 */
proto.services.central.GetEnergyTariffsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetEnergyTariffsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetEnergyTariffsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetEnergyTariffsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetEnergyTariffsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyTariffsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsList: jspb.Message.toObjectList(msg.getEnergyTariffsList(),
    types_tariff_pb.EnergyTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetEnergyTariffsResponse}
 */
proto.services.central.GetEnergyTariffsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetEnergyTariffsResponse;
  return proto.services.central.GetEnergyTariffsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetEnergyTariffsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetEnergyTariffsResponse}
 */
proto.services.central.GetEnergyTariffsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.addEnergyTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetEnergyTariffsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetEnergyTariffsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetEnergyTariffsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetEnergyTariffsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.EnergyTariff energy_tariffs = 1;
 * @return {!Array<!proto.types.EnergyTariff>}
 */
proto.services.central.GetEnergyTariffsResponse.prototype.getEnergyTariffsList = function() {
  return /** @type{!Array<!proto.types.EnergyTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.EnergyTariff, 1));
};


/**
 * @param {!Array<!proto.types.EnergyTariff>} value
 * @return {!proto.services.central.GetEnergyTariffsResponse} returns this
*/
proto.services.central.GetEnergyTariffsResponse.prototype.setEnergyTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergyTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyTariff}
 */
proto.services.central.GetEnergyTariffsResponse.prototype.addEnergyTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergyTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetEnergyTariffsResponse} returns this
 */
proto.services.central.GetEnergyTariffsResponse.prototype.clearEnergyTariffsList = function() {
  return this.setEnergyTariffsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCurrentEnergyTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCurrentEnergyTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCurrentEnergyTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentEnergyTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCurrentEnergyTariffRequest}
 */
proto.services.central.GetCurrentEnergyTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCurrentEnergyTariffRequest;
  return proto.services.central.GetCurrentEnergyTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCurrentEnergyTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCurrentEnergyTariffRequest}
 */
proto.services.central.GetCurrentEnergyTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCurrentEnergyTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCurrentEnergyTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCurrentEnergyTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentEnergyTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.GetCurrentEnergyTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetCurrentEnergyTariffRequest} returns this
 */
proto.services.central.GetCurrentEnergyTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetCurrentEnergyTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetCurrentEnergyTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetCurrentEnergyTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentEnergyTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariff: (f = msg.getEnergyTariff()) && types_tariff_pb.EnergyTariff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetCurrentEnergyTariffResponse}
 */
proto.services.central.GetCurrentEnergyTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetCurrentEnergyTariffResponse;
  return proto.services.central.GetCurrentEnergyTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetCurrentEnergyTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetCurrentEnergyTariffResponse}
 */
proto.services.central.GetCurrentEnergyTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.setEnergyTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetCurrentEnergyTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetCurrentEnergyTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetCurrentEnergyTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetCurrentEnergyTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * optional types.EnergyTariff energy_tariff = 1;
 * @return {?proto.types.EnergyTariff}
 */
proto.services.central.GetCurrentEnergyTariffResponse.prototype.getEnergyTariff = function() {
  return /** @type{?proto.types.EnergyTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.EnergyTariff, 1));
};


/**
 * @param {?proto.types.EnergyTariff|undefined} value
 * @return {!proto.services.central.GetCurrentEnergyTariffResponse} returns this
*/
proto.services.central.GetCurrentEnergyTariffResponse.prototype.setEnergyTariff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.GetCurrentEnergyTariffResponse} returns this
 */
proto.services.central.GetCurrentEnergyTariffResponse.prototype.clearEnergyTariff = function() {
  return this.setEnergyTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetCurrentEnergyTariffResponse.prototype.hasEnergyTariff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddEnergyTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddEnergyTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddEnergyTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddEnergyTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedYearlyConsumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    energyTariff: (f = msg.getEnergyTariff()) && types_tariff_pb.EnergyTariff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddEnergyTariffRequest}
 */
proto.services.central.AddEnergyTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddEnergyTariffRequest;
  return proto.services.central.AddEnergyTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddEnergyTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddEnergyTariffRequest}
 */
proto.services.central.AddEnergyTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEstimatedYearlyConsumption(value);
      break;
    case 3:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.setEnergyTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddEnergyTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddEnergyTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddEnergyTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddEnergyTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedYearlyConsumption();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEnergyTariff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.AddEnergyTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddEnergyTariffRequest} returns this
 */
proto.services.central.AddEnergyTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double estimated_yearly_consumption = 2;
 * @return {number}
 */
proto.services.central.AddEnergyTariffRequest.prototype.getEstimatedYearlyConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AddEnergyTariffRequest} returns this
 */
proto.services.central.AddEnergyTariffRequest.prototype.setEstimatedYearlyConsumption = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional types.EnergyTariff energy_tariff = 3;
 * @return {?proto.types.EnergyTariff}
 */
proto.services.central.AddEnergyTariffRequest.prototype.getEnergyTariff = function() {
  return /** @type{?proto.types.EnergyTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.EnergyTariff, 3));
};


/**
 * @param {?proto.types.EnergyTariff|undefined} value
 * @return {!proto.services.central.AddEnergyTariffRequest} returns this
*/
proto.services.central.AddEnergyTariffRequest.prototype.setEnergyTariff = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddEnergyTariffRequest} returns this
 */
proto.services.central.AddEnergyTariffRequest.prototype.clearEnergyTariff = function() {
  return this.setEnergyTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddEnergyTariffRequest.prototype.hasEnergyTariff = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.AddEnergyTariffResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddEnergyTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddEnergyTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddEnergyTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddEnergyTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsList: jspb.Message.toObjectList(msg.getEnergyTariffsList(),
    types_tariff_pb.EnergyTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddEnergyTariffResponse}
 */
proto.services.central.AddEnergyTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddEnergyTariffResponse;
  return proto.services.central.AddEnergyTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddEnergyTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddEnergyTariffResponse}
 */
proto.services.central.AddEnergyTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.addEnergyTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddEnergyTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddEnergyTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddEnergyTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddEnergyTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.EnergyTariff energy_tariffs = 1;
 * @return {!Array<!proto.types.EnergyTariff>}
 */
proto.services.central.AddEnergyTariffResponse.prototype.getEnergyTariffsList = function() {
  return /** @type{!Array<!proto.types.EnergyTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.EnergyTariff, 1));
};


/**
 * @param {!Array<!proto.types.EnergyTariff>} value
 * @return {!proto.services.central.AddEnergyTariffResponse} returns this
*/
proto.services.central.AddEnergyTariffResponse.prototype.setEnergyTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergyTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyTariff}
 */
proto.services.central.AddEnergyTariffResponse.prototype.addEnergyTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergyTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.AddEnergyTariffResponse} returns this
 */
proto.services.central.AddEnergyTariffResponse.prototype.clearEnergyTariffsList = function() {
  return this.setEnergyTariffsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateEnergyTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateEnergyTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateEnergyTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    energyTariff: (f = msg.getEnergyTariff()) && types_tariff_pb.EnergyTariff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateEnergyTariffRequest}
 */
proto.services.central.UpdateEnergyTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateEnergyTariffRequest;
  return proto.services.central.UpdateEnergyTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateEnergyTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateEnergyTariffRequest}
 */
proto.services.central.UpdateEnergyTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.setEnergyTariff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateEnergyTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateEnergyTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateEnergyTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnergyTariff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.UpdateEnergyTariffRequest} returns this
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.EnergyTariff energy_tariff = 2;
 * @return {?proto.types.EnergyTariff}
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.getEnergyTariff = function() {
  return /** @type{?proto.types.EnergyTariff} */ (
    jspb.Message.getWrapperField(this, types_tariff_pb.EnergyTariff, 2));
};


/**
 * @param {?proto.types.EnergyTariff|undefined} value
 * @return {!proto.services.central.UpdateEnergyTariffRequest} returns this
*/
proto.services.central.UpdateEnergyTariffRequest.prototype.setEnergyTariff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.UpdateEnergyTariffRequest} returns this
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.clearEnergyTariff = function() {
  return this.setEnergyTariff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.UpdateEnergyTariffRequest.prototype.hasEnergyTariff = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.UpdateEnergyTariffResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.UpdateEnergyTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.UpdateEnergyTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.UpdateEnergyTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateEnergyTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffsList: jspb.Message.toObjectList(msg.getEnergyTariffsList(),
    types_tariff_pb.EnergyTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.UpdateEnergyTariffResponse}
 */
proto.services.central.UpdateEnergyTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.UpdateEnergyTariffResponse;
  return proto.services.central.UpdateEnergyTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.UpdateEnergyTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.UpdateEnergyTariffResponse}
 */
proto.services.central.UpdateEnergyTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_tariff_pb.EnergyTariff;
      reader.readMessage(value,types_tariff_pb.EnergyTariff.deserializeBinaryFromReader);
      msg.addEnergyTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.UpdateEnergyTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.UpdateEnergyTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.UpdateEnergyTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.UpdateEnergyTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergyTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_tariff_pb.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated types.EnergyTariff energy_tariffs = 1;
 * @return {!Array<!proto.types.EnergyTariff>}
 */
proto.services.central.UpdateEnergyTariffResponse.prototype.getEnergyTariffsList = function() {
  return /** @type{!Array<!proto.types.EnergyTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_tariff_pb.EnergyTariff, 1));
};


/**
 * @param {!Array<!proto.types.EnergyTariff>} value
 * @return {!proto.services.central.UpdateEnergyTariffResponse} returns this
*/
proto.services.central.UpdateEnergyTariffResponse.prototype.setEnergyTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergyTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyTariff}
 */
proto.services.central.UpdateEnergyTariffResponse.prototype.addEnergyTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergyTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.UpdateEnergyTariffResponse} returns this
 */
proto.services.central.UpdateEnergyTariffResponse.prototype.clearEnergyTariffsList = function() {
  return this.setEnergyTariffsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteEnergyTariffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteEnergyTariffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteEnergyTariffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteEnergyTariffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    energyTariffId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteEnergyTariffRequest}
 */
proto.services.central.DeleteEnergyTariffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteEnergyTariffRequest;
  return proto.services.central.DeleteEnergyTariffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteEnergyTariffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteEnergyTariffRequest}
 */
proto.services.central.DeleteEnergyTariffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyTariffId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteEnergyTariffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteEnergyTariffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteEnergyTariffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteEnergyTariffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnergyTariffId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string space_id = 1;
 * @return {string}
 */
proto.services.central.DeleteEnergyTariffRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteEnergyTariffRequest} returns this
 */
proto.services.central.DeleteEnergyTariffRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string energy_tariff_id = 2;
 * @return {string}
 */
proto.services.central.DeleteEnergyTariffRequest.prototype.getEnergyTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteEnergyTariffRequest} returns this
 */
proto.services.central.DeleteEnergyTariffRequest.prototype.setEnergyTariffId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteEnergyTariffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteEnergyTariffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteEnergyTariffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteEnergyTariffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteEnergyTariffResponse}
 */
proto.services.central.DeleteEnergyTariffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteEnergyTariffResponse;
  return proto.services.central.DeleteEnergyTariffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteEnergyTariffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteEnergyTariffResponse}
 */
proto.services.central.DeleteEnergyTariffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteEnergyTariffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteEnergyTariffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteEnergyTariffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteEnergyTariffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.GetAdvancePaymentsRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.services.central.GetAdvancePaymentsRequest.TariffCase = {
  TARIFF_NOT_SET: 0,
  ENERGY_TARIFF_ID: 1,
  GAS_TARIFF_ID: 2
};

/**
 * @return {proto.services.central.GetAdvancePaymentsRequest.TariffCase}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.getTariffCase = function() {
  return /** @type {proto.services.central.GetAdvancePaymentsRequest.TariffCase} */(jspb.Message.computeOneofCase(this, proto.services.central.GetAdvancePaymentsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAdvancePaymentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAdvancePaymentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gasTariffId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAdvancePaymentsRequest}
 */
proto.services.central.GetAdvancePaymentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAdvancePaymentsRequest;
  return proto.services.central.GetAdvancePaymentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAdvancePaymentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAdvancePaymentsRequest}
 */
proto.services.central.GetAdvancePaymentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyTariffId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasTariffId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAdvancePaymentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAdvancePaymentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string energy_tariff_id = 1;
 * @return {string}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.getEnergyTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAdvancePaymentsRequest} returns this
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.setEnergyTariffId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.services.central.GetAdvancePaymentsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAdvancePaymentsRequest} returns this
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.clearEnergyTariffId = function() {
  return jspb.Message.setOneofField(this, 1, proto.services.central.GetAdvancePaymentsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.hasEnergyTariffId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string gas_tariff_id = 2;
 * @return {string}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.getGasTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAdvancePaymentsRequest} returns this
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.setGasTariffId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.services.central.GetAdvancePaymentsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.GetAdvancePaymentsRequest} returns this
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.clearGasTariffId = function() {
  return jspb.Message.setOneofField(this, 2, proto.services.central.GetAdvancePaymentsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.hasGasTariffId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string space_id = 3;
 * @return {string}
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.GetAdvancePaymentsRequest} returns this
 */
proto.services.central.GetAdvancePaymentsRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.central.GetAdvancePaymentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.GetAdvancePaymentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.GetAdvancePaymentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.GetAdvancePaymentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    advancePaymentsList: jspb.Message.toObjectList(msg.getAdvancePaymentsList(),
    proto.services.central.AdvancePayment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.GetAdvancePaymentsResponse}
 */
proto.services.central.GetAdvancePaymentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.GetAdvancePaymentsResponse;
  return proto.services.central.GetAdvancePaymentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.GetAdvancePaymentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.GetAdvancePaymentsResponse}
 */
proto.services.central.GetAdvancePaymentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.central.AdvancePayment;
      reader.readMessage(value,proto.services.central.AdvancePayment.deserializeBinaryFromReader);
      msg.addAdvancePayments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.GetAdvancePaymentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.GetAdvancePaymentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.GetAdvancePaymentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.GetAdvancePaymentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvancePaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.central.AdvancePayment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdvancePayment advance_payments = 1;
 * @return {!Array<!proto.services.central.AdvancePayment>}
 */
proto.services.central.GetAdvancePaymentsResponse.prototype.getAdvancePaymentsList = function() {
  return /** @type{!Array<!proto.services.central.AdvancePayment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.central.AdvancePayment, 1));
};


/**
 * @param {!Array<!proto.services.central.AdvancePayment>} value
 * @return {!proto.services.central.GetAdvancePaymentsResponse} returns this
*/
proto.services.central.GetAdvancePaymentsResponse.prototype.setAdvancePaymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.central.AdvancePayment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.central.AdvancePayment}
 */
proto.services.central.GetAdvancePaymentsResponse.prototype.addAdvancePayments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.central.AdvancePayment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.central.GetAdvancePaymentsResponse} returns this
 */
proto.services.central.GetAdvancePaymentsResponse.prototype.clearAdvancePaymentsList = function() {
  return this.setAdvancePaymentsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.AddAdvancePaymentRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.services.central.AddAdvancePaymentRequest.TariffCase = {
  TARIFF_NOT_SET: 0,
  ENERGY_TARIFF_ID: 1,
  GAS_TARIFF_ID: 2
};

/**
 * @return {proto.services.central.AddAdvancePaymentRequest.TariffCase}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.getTariffCase = function() {
  return /** @type {proto.services.central.AddAdvancePaymentRequest.TariffCase} */(jspb.Message.computeOneofCase(this, proto.services.central.AddAdvancePaymentRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddAdvancePaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddAdvancePaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAdvancePaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gasTariffId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newAdvancePayment: (f = msg.getNewAdvancePayment()) && proto.services.central.AdvancePayment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddAdvancePaymentRequest}
 */
proto.services.central.AddAdvancePaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddAdvancePaymentRequest;
  return proto.services.central.AddAdvancePaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddAdvancePaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddAdvancePaymentRequest}
 */
proto.services.central.AddAdvancePaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyTariffId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasTariffId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 4:
      var value = new proto.services.central.AdvancePayment;
      reader.readMessage(value,proto.services.central.AdvancePayment.deserializeBinaryFromReader);
      msg.setNewAdvancePayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddAdvancePaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddAdvancePaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAdvancePaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewAdvancePayment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.central.AdvancePayment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string energy_tariff_id = 1;
 * @return {string}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.getEnergyTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
 */
proto.services.central.AddAdvancePaymentRequest.prototype.setEnergyTariffId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.services.central.AddAdvancePaymentRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
 */
proto.services.central.AddAdvancePaymentRequest.prototype.clearEnergyTariffId = function() {
  return jspb.Message.setOneofField(this, 1, proto.services.central.AddAdvancePaymentRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.hasEnergyTariffId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string gas_tariff_id = 2;
 * @return {string}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.getGasTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
 */
proto.services.central.AddAdvancePaymentRequest.prototype.setGasTariffId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.services.central.AddAdvancePaymentRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
 */
proto.services.central.AddAdvancePaymentRequest.prototype.clearGasTariffId = function() {
  return jspb.Message.setOneofField(this, 2, proto.services.central.AddAdvancePaymentRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.hasGasTariffId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string space_id = 3;
 * @return {string}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
 */
proto.services.central.AddAdvancePaymentRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AdvancePayment new_advance_payment = 4;
 * @return {?proto.services.central.AdvancePayment}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.getNewAdvancePayment = function() {
  return /** @type{?proto.services.central.AdvancePayment} */ (
    jspb.Message.getWrapperField(this, proto.services.central.AdvancePayment, 4));
};


/**
 * @param {?proto.services.central.AdvancePayment|undefined} value
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
*/
proto.services.central.AddAdvancePaymentRequest.prototype.setNewAdvancePayment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.central.AddAdvancePaymentRequest} returns this
 */
proto.services.central.AddAdvancePaymentRequest.prototype.clearNewAdvancePayment = function() {
  return this.setNewAdvancePayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.AddAdvancePaymentRequest.prototype.hasNewAdvancePayment = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AddAdvancePaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AddAdvancePaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AddAdvancePaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAdvancePaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AddAdvancePaymentResponse}
 */
proto.services.central.AddAdvancePaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AddAdvancePaymentResponse;
  return proto.services.central.AddAdvancePaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AddAdvancePaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AddAdvancePaymentResponse}
 */
proto.services.central.AddAdvancePaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AddAdvancePaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AddAdvancePaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AddAdvancePaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AddAdvancePaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.services.central.DeleteAdvancePaymentRequest.TariffCase = {
  TARIFF_NOT_SET: 0,
  ENERGY_TARIFF_ID: 1,
  GAS_TARIFF_ID: 2
};

/**
 * @return {proto.services.central.DeleteAdvancePaymentRequest.TariffCase}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.getTariffCase = function() {
  return /** @type {proto.services.central.DeleteAdvancePaymentRequest.TariffCase} */(jspb.Message.computeOneofCase(this, proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteAdvancePaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteAdvancePaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAdvancePaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    energyTariffId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gasTariffId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    spaceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    advancePaymentId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteAdvancePaymentRequest}
 */
proto.services.central.DeleteAdvancePaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteAdvancePaymentRequest;
  return proto.services.central.DeleteAdvancePaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteAdvancePaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteAdvancePaymentRequest}
 */
proto.services.central.DeleteAdvancePaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnergyTariffId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasTariffId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvancePaymentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteAdvancePaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteAdvancePaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAdvancePaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdvancePaymentId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string energy_tariff_id = 1;
 * @return {string}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.getEnergyTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteAdvancePaymentRequest} returns this
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.setEnergyTariffId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeleteAdvancePaymentRequest} returns this
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.clearEnergyTariffId = function() {
  return jspb.Message.setOneofField(this, 1, proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.hasEnergyTariffId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string gas_tariff_id = 2;
 * @return {string}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.getGasTariffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteAdvancePaymentRequest} returns this
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.setGasTariffId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.central.DeleteAdvancePaymentRequest} returns this
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.clearGasTariffId = function() {
  return jspb.Message.setOneofField(this, 2, proto.services.central.DeleteAdvancePaymentRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.hasGasTariffId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string space_id = 3;
 * @return {string}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.getSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteAdvancePaymentRequest} returns this
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.setSpaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string advance_payment_id = 4;
 * @return {string}
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.getAdvancePaymentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.DeleteAdvancePaymentRequest} returns this
 */
proto.services.central.DeleteAdvancePaymentRequest.prototype.setAdvancePaymentId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.DeleteAdvancePaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.DeleteAdvancePaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.DeleteAdvancePaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAdvancePaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.DeleteAdvancePaymentResponse}
 */
proto.services.central.DeleteAdvancePaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.DeleteAdvancePaymentResponse;
  return proto.services.central.DeleteAdvancePaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.DeleteAdvancePaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.DeleteAdvancePaymentResponse}
 */
proto.services.central.DeleteAdvancePaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.DeleteAdvancePaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.DeleteAdvancePaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.DeleteAdvancePaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.DeleteAdvancePaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.central.AdvancePayment.prototype.toObject = function(opt_includeInstance) {
  return proto.services.central.AdvancePayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.central.AdvancePayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AdvancePayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startmonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startyear: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    period: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.central.AdvancePayment}
 */
proto.services.central.AdvancePayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.central.AdvancePayment;
  return proto.services.central.AdvancePayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.central.AdvancePayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.central.AdvancePayment}
 */
proto.services.central.AdvancePayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartmonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartyear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.central.AdvancePayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.central.AdvancePayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.central.AdvancePayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.central.AdvancePayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartmonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartyear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPeriod();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.central.AdvancePayment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePayment} returns this
 */
proto.services.central.AdvancePayment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 startMonth = 2;
 * @return {number}
 */
proto.services.central.AdvancePayment.prototype.getStartmonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AdvancePayment} returns this
 */
proto.services.central.AdvancePayment.prototype.setStartmonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 startYear = 3;
 * @return {number}
 */
proto.services.central.AdvancePayment.prototype.getStartyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AdvancePayment} returns this
 */
proto.services.central.AdvancePayment.prototype.setStartyear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.services.central.AdvancePayment.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.central.AdvancePayment} returns this
 */
proto.services.central.AdvancePayment.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string period = 5;
 * @return {string}
 */
proto.services.central.AdvancePayment.prototype.getPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.central.AdvancePayment} returns this
 */
proto.services.central.AdvancePayment.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.services.central);
