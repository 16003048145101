
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { spacesModule } from "@/store/modules/spaces";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import simulations, { simulationModule } from "@/store/modules/simulations";
import { DeleteSimulationRequest } from "zaehlerfreunde-central/advisory_service_pb";
import AddTariffReminder from "../dashboard/AddTariffReminder.vue";
import tariff, { tariffModule } from "@/store/modules/tariff";
import { EnergyTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { paths } from "@/router/routes";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { Simulation } from "zaehlerfreunde-proto-types/simulations_pb";
const stylingModule = namespace("styling");

@Component({
  components: {
    AddTariffReminder,
  },
})
export default class Simulator extends Vue {
  @spacesModule.State selectedSpace: RemoteData<UserError, Space>;
  @simulationModule.Getter spacePastSimulations: RemoteData<UserError, Simulation[]>;
  @stylingModule.State navigationBarHeight: number;
  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff>;
  @simulationModule.State deleteSimulationCall: RemoteCall<UserError>;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  paths = paths;

  @Watch("enabledFeatures")
  onEnabledFeaturesChanged() {
    if (!this.enabledFeatures.has(Feature.SHOP)) {
      this.$router.replace(paths.platform.dashboard);
    }
  }

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    simulations.loadPastSimulations();
    tariff.getCurrentEnergyTariff();
  }

  mounted(): void {
    if (this.selectedSpace) {
      simulations.loadPastSimulations();
      tariff.getCurrentEnergyTariff();
    }
  }

  onMiniPVSimulatorClicked(): void {
    if (this.currentEnergyTariff.data) {
      this.$router.push(paths.platform.miniPvSimulator);
    }
  }

  onDeleteSimulationClicked(event: CustomEvent, simulation: Simulation): void {
    event.stopPropagation();
    const request = new DeleteSimulationRequest();
    request.setSimulation(simulation);
    simulations.deleteSimulation(request);
  }

  getLabel(type: string): string {
    switch (type) {
      case "MINI_PV":
        return "Mini-PV";
      default:
        return "Mini-PV";
    }
  }
}
