
import devices, { devicesModule } from "@/store/modules/devices";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { paths } from "@/router/routes";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { TableEntry } from "@/components/core/Table.vue";
import { getMediumIcon } from "@/utils/device-utils";
import {
  DeviceRegistration,
  VirtualDeviceRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import { spacesModule } from "@/store/modules/spaces";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";

interface DeviceItem {
  selected: boolean;
  name: string;
  type: string;
  medium: Device.Medium;
  value: string;
}

@Component({
  components: {},
})
export default class VirtualDeviceConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  @devicesModule.State registerDeviceCall: RemoteCall<UserError>;
  @devicesModule.State devices: RemoteData<UserError, Device[]>;
  @spacesModule.Getter selectedSpaceId: string;

  selectedDevicesItems: DeviceItem[] = [];
  isReview: boolean = false;
  getMediumIcon = getMediumIcon;

  headers = [
    { value: "selected", sortable: false },
    { text: "Name", value: "name" },
    { text: "Typ", value: "type" },
    { text: "Sparte", value: "medium", sortable: false },
  ];

  get devicesItems(): DeviceItem[] {
    return (this.devices.list ?? []).map((device: Device) => ({
      selected: this.selectedDevicesItems.some((item) => item.value === device.getId()),
      name: device.getName(),
      type: device.getDeviceTypeInfo()?.getName() ?? "",
      medium: device.getMedium(),
      value: device.getId(),
      disabled: true,
    }));
  }

  get selectedDevices(): TableEntry[][] | undefined {
    return this.selectedDevicesItems?.map((deviceItem) => [
      { value: deviceItem.name, bold: true },
      { value: deviceItem.type },
    ]);
  }

  mounted(): void {
    devices.getDevices();
  }

  @Watch("registerDeviceCall")
  onRegisterDeviceCall(): void {
    if (this.registerDeviceCall.succeeded) {
      this.$router.go(-1);
    }
  }

  onDeviceSelected(deviceItem: DeviceItem, selected: boolean) {
    if (selected) {
      this.selectedDevicesItems.push(deviceItem);
    } else {
      this.selectedDevicesItems = this.selectedDevicesItems.filter((item) => item.value !== deviceItem.value);
    }
  }

  registerVirtualDevice(): void {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceType(Device.Type.VIRTUAL_METER);
    registration.setDeviceProvider(Device.Provider.VIRTUAL);
    registration.setDeviceMedium(this.selectedDevicesItems[0]?.medium);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice);

    registration.setSpaceMapping(spaceMapping);

    const details = new VirtualDeviceRegistrationDetails();
    const deviceIds = this.selectedDevicesItems.map((item) => item.value);

    details.setDeviceIdsList(deviceIds);
    registration.setVirtualDevice(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    devices.registerDevice(registration);
  }

  onClose() {
    this.$router.push(paths.platform.devices);
  }
}
