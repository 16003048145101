
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device, DeviceProviderInfo } from "zaehlerfreunde-proto-types/device_pb";
import { Option } from "@/components/core/funnel/OptionSelection.vue";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { GetDeviceProvidersRequest } from "zaehlerfreunde-central/device_service_pb";
import { subscriptionModule } from "@/store/modules/subscription";
import { paths } from "@/router/routes";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { spacesModule } from "@/store/modules/spaces";

@Component
export default class DeviceProvider extends Vue {
  @subscriptionModule.Getter currentPlan: SubscriptionPlan;
  @Prop() deviceType: Device.Type;
  @Prop({ default: false }) canSkipProvidersSelection: boolean;
  @spacesModule.Getter selectedSpaceId: string;
  deviceProviders: RemoteData<UserError, DeviceProviderInfo[]> = initialized;

  async mounted(): Promise<void> {
    try {
      this.deviceProviders = pending;
      const request = new GetDeviceProvidersRequest();
      request.setDeviceType(this.deviceType);

      if (this.selectedSpaceId) {
        request.setSpaceId(this.selectedSpaceId);
      }

      const response = await deviceServiceClient.getDeviceProviders(request, {});
      this.deviceProviders = success(response.getDeviceProvidersList());

      if (this.canSkipProvidersSelection && response.getDeviceProvidersList().length === 1) {
        const provider =
          this.providerOptions.find((x) => x.value === response.getDeviceProvidersList()[0].getValue()) ??
          this.providerOptions[0];

        this.onProviderSelected(provider);
      }
    } catch (error) {
      this.deviceProviders = failure(userErrorFrom(error));
    }
  }

  get providerOptions(): Option[] {
    return this.deviceProviders.list.map((p: DeviceProviderInfo) => ({
      title: p.getName(),
      subtitle: p.getDescription(),
      image: p.getLogoUrl(),
      value: p.getValue(),
      props: { instructionsPath: p.getInstructionsPath() },
    }));
  }

  onProviderSelected(provider: Option): void {
    if (provider.value === Device.Provider.POWERFOX && this.currentPlan === SubscriptionPlan.FREE) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "pro-powerfox" },
      });
    } else {
      this.$emit("provider-selected", provider);
    }
  }
}
