
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { failure, initialized, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetLandlordToTenantProjectRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import SingleProjectDevices from "./SingleProjectDevices.vue";
import DeleteLandlordToTenantProjectDialog from "./DeleteLandlordToTenantProjectDialog.vue";
import { LandlordToTenantProject } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component({
  components: { SingleProjectDevices, DeleteLandlordToTenantProjectDialog },
})
export default class SingleProject extends Vue {
  singleProject: RemoteData<UserError, LandlordToTenantProject> = initialized;
  showDeleteProjectDialog: boolean = false;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  deleteProjectCall: RemoteCall<UserError> = initialized;
  projectId: string | null = null;

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    this.$router.push(paths.admin.devices);
  }

  mounted(): void {
    this.loadProject();
  }
  async loadProject(): Promise<void> {
    this.showDeleteProjectDialog = false;
    this.projectId = this.$route.params.projectId;
    const request = new GetLandlordToTenantProjectRequest();
    request.setProjectId(this.projectId);
    try {
      const response = await landlordToTenantServiceClient.getLandlordToTenantProject(request, {});
      const project = response.getProject();
      if (project) {
        this.singleProject = success(project);
      }
    } catch (error) {
      this.singleProject = failure(userErrorFrom(error));
    }
  }

  goBackToProjects(): void {
    this.$router.go(-1);
  }
}
