
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { failure, initialized, pending, RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import tariff, { tariffModule } from "@/store/modules/tariff";
import { EnergyTariff, SepaDetails } from "zaehlerfreunde-proto-types/tariff_pb";
import spaces from "@/store/modules/spaces";
import { tariffServiceClient } from "@/config/service-clients";
import { DateTime } from "google-type/datetime_pb";
import { SetIsWatchdogEnabledRequest, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";

@Component
export default class EnableWatchdog extends Vue {
  call: RemoteCall<UserError> = initialized;

  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff>;

  @Prop() value: boolean;

  step = 1;

  show: boolean = false;
  supplierCustomerId: string = "";
  meterId: string = "";
  consent: boolean = false;

  sepaInfo = {
    accountHolder: "",
    street: "",
    streetNo: "",
    postcode: "",
    city: "",
    iban: "",
    bic: "",
    birthDate: null,
  };

  mounted(): void {
    this.supplierCustomerId = this.currentEnergyTariff.data?.getSupplier()?.getCustomerId() ?? "";
  }

  get isInfoComplete(): boolean {
    switch (this.step) {
      case 1:
        return true;
      case 2:
        return !!this.supplierCustomerId && !!this.meterId;
      case 3:
        return Object.values(this.sepaInfo).every((info) => !!info) && this.consent;
      default:
        return false;
    }
  }

  get btnLabel(): string {
    switch (this.step) {
      case 1:
        return "Okay!";
      case 2:
        return "Weiter";
      case 3:
        return "Jetzt aktivieren";
      default:
        return "";
    }
  }

  @Watch("value")
  onValueChanged(): void {
    this.show = this.value;
  }

  @Watch("show")
  onShowChanged(): void {
    this.$emit("input", this.show);
  }

  onBackClicked(): void {
    if (this.step <= 1) {
      this.show = false;
    } else {
      this.step -= 1;
    }
  }

  async onNextClicked(): Promise<void> {
    if (this.step < 3) {
      this.step += 1;
    } else if (this.isInfoComplete) {
      try {
        this.call = pending;

        const currentEnergyTariff = this.currentEnergyTariff.data;
        if (this.supplierCustomerId != currentEnergyTariff?.getSupplier()?.getCustomerId()) {
          currentEnergyTariff?.getSupplier()?.setCustomerId(this.supplierCustomerId);
          const request = new UpdateTariffRequest();
          request.setSpaceId(spaces.selectedSpaceId);
          request.setEnergyTariff(currentEnergyTariff ?? undefined);
          await tariffServiceClient.updateTariff(request, {});
        }

        const sepaInfo = new SepaDetails();
        sepaInfo.setAccountHolder(this.sepaInfo.accountHolder);
        sepaInfo.setStreet(this.sepaInfo.street);
        sepaInfo.setStreetNo(this.sepaInfo.streetNo);
        sepaInfo.setPostcode(this.sepaInfo.postcode);
        sepaInfo.setCity(this.sepaInfo.city);
        sepaInfo.setIban(this.sepaInfo.iban);
        sepaInfo.setBic(this.sepaInfo.bic);

        const birthDate = this.sepaInfo.birthDate as unknown as Date;
        const birthDateDateTime = new DateTime();

        birthDateDateTime.setYear(birthDate.getFullYear());
        birthDateDateTime.setMonth(birthDate.getMonth() + 1);
        birthDateDateTime.setDay(birthDate.getDate());

        sepaInfo.setBirthDate(birthDateDateTime);

        const request = new SetIsWatchdogEnabledRequest();
        request.setSpaceId(spaces.selectedSpaceId);
        request.setIsWatchdogEnabled(true);
        request.setSepaDetails(sepaInfo);
        request.setMeterId(this.meterId);

        await tariffServiceClient.setIsWatchdogEnabled(request, {});

        tariff.getTariffSearchInfo();

        this.show = false;
      } catch (error) {
        this.call = failure(userErrorFrom(error));
      }
    }
  }
}
