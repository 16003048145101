import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { namespace } from "vuex-class";
import {
  CreateCheckoutSessionRequest,
  GetCurrentSubscriptionPlanRequest,
  Product,
} from "zaehlerfreunde-central/user_service_pb";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { failure, initialized, isSuccess, pending, RemoteCall, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { userServiceClient } from "@/config/service-clients";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "subscription",
})
class SubscriptionModule extends VuexModule {
  subscriptionPlan: RemoteData<UserError, SubscriptionPlan> = initialized;
  createCheckoutSessionCall: RemoteCall<UserError> = initialized;

  get currentPlan(): SubscriptionPlan {
    if (isSuccess(this.subscriptionPlan)) {
      return this.subscriptionPlan.data;
    } else {
      return SubscriptionPlan.FREE;
    }
  }

  @Action
  async createCheckoutSession(product: Product) {
    try {
      this.setCreateCheckoutSessionCall(pending);
      const request = new CreateCheckoutSessionRequest();
      request.setProduct(product);
      request.setBaseUrl(window.location.origin);

      const response = await userServiceClient.createCheckoutSession(request, {});

      this.setCreateCheckoutSessionCall(success(void 0));
      window.open(response.getCheckoutUrl(), "_self");
    } catch (error) {
      this.setCreateCheckoutSessionCall(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getCurrentSubscriptionPlan() {
    try {
      this.setSubscriptionPlan(pending);
      const request = new GetCurrentSubscriptionPlanRequest();
      const response = await userServiceClient.getCurrentSubscriptionPlan(request, {});
      this.setSubscriptionPlan(success(response.getSubscriptionPlan()));
    } catch (error) {
      this.setSubscriptionPlan(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setSubscriptionPlan(plan: RemoteData<UserError, SubscriptionPlan>) {
    this.subscriptionPlan = plan;
  }

  @Mutation
  setCreateCheckoutSessionCall(call: RemoteCall<UserError>) {
    this.createCheckoutSessionCall = call;
  }
}

export const subscriptionModule = namespace("subscription");
export default getModule(SubscriptionModule);
