
import { paths } from "@/router/routes";
import { RemoteData, initializedFactory } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { SearchParams } from "../devices-table/DevicesTable.vue";
import { LandlordToTenantProject } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";

export interface ProjectTable {
  id: string;
  name: string;
  location: string;
}

export interface ProjectTableControl {
  icon: string;
  event: string;
  color: string;
}

@Component({
  components: {},
})
export default class ProjectsTable extends Vue {
  @Prop({ default: false }) pagination: boolean;
  @Prop({ default: [] }) headerControls: ProjectTableControl[];
  @Prop({ default: initializedFactory }) projectsData: RemoteData<UserError, LandlordToTenantProject[]>;
  @Prop({ default: initializedFactory }) projectsPages: RemoteData<UserError, number>;
  @Prop({ default: false }) showSearchBar: boolean;

  searchParams: SearchParams = {
    search: "",
    page: 1,
  };

  itemsPerPage = 10;
  selectedSpaceForInvite: Space | null = null;

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  mounted(): void {
    const pageQuery = this.$route.query.page;
    this.searchParams.search = (this.$route.query.search as string) ?? "";
    this.searchParams.page = typeof pageQuery === "string" ? parseInt(pageQuery, 10) : 1;
  }

  beforeMount(): void {
    this.$emit("page-changed", this.searchParams.page);
  }

  @Watch("searchParams.page")
  onPageChanged(): void {
    this.updateRoute();
    this.$emit("search-params-input", this.searchParams);
  }
  @Watch("searchParams.search")
  async onSearchChanged(): Promise<void> {
    this.updateRoute();
    if (this.searchParams.search === "") {
      this.$emit("search-params-input", this.searchParams);
    } else {
      const searchTerm = this.searchParams.search;
      await this.sleep(500);
      if (searchTerm === this.searchParams.search) {
        this.$emit("search-params-input", this.searchParams);
      }
    }
  }

  updateRoute(): void {
    this.$router.push({
      path: paths.admin.mieterstrom,
      query: { page: this.searchParams.page.toString(), search: this.searchParams.search },
    });
  }

  get headers() {
    return [
      { text: "Name", value: "name", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "", value: "controls", sortable: false },
    ];
  }

  get projects(): ProjectTable[] {
    return this.projectsData.list.map((p: LandlordToTenantProject) => ({
      id: p.getId(),
      location: p.getLocation(),
      name: p.getName(),
    }));
  }

  get totalPages(): number {
    return this.projectsPages?.data ?? 1;
  }

  handleRowClick(project: { id: string }): void {
    this.$router.push({
      path: `${paths.admin.mieterstrom}/${project.id}`,
    });
  }

  onItemControlsClicked(event: Event, project: ProjectTable, customEvent: string) {
    event.stopPropagation();
    this.$emit(customEvent, project.id);
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }
}
