var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('zf-dialog',{attrs:{"max-width":"650px","title":_vm.title,"subtitle":_vm.subtitle},on:{"close":function($event){return _vm.$emit('input', false)}},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [(_vm.automationDetail === null)?_c('div',[_c('zf-btn',{attrs:{"color":"primary","disabled":!_vm.validInput,"loading":_vm.addAutomationCall.pending},on:{"click":_vm.onSaveClicked}},[_vm._v(" Speichern ")]),_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Abbrechen ")])],1):_c('div',[_c('zf-btn',{attrs:{"color":"primary","disabled":!_vm.validInput,"loading":_vm.addAutomationCall.pending},on:{"click":_vm.onUpdateClicked}},[_vm._v(" Aktualisieren ")]),_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Abbrechen ")])],1)]},proxy:true}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('zf-alert',{attrs:{"remoteData":_vm.addAutomationCall}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-tag","label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{class:{
      'd-flex': _vm.triggers.length > 0,
      'justify-space-between': true,
      'align-center': true,
    }},[_c('div',{staticClass:"color-gray"},[_vm._v("Wenn alle eintreten")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.triggers.length === 0)?_c('zf-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"block":"","large":""}},'zf-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Auslöser hinzufügen")],1):_c('zf-btn',_vm._g(_vm._b({staticClass:"top-right",attrs:{"icon":""}},'zf-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('div',{staticClass:"d-flex flex-column white-background gap pa-2 zf-theme"},[_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addDeviceReadingTrigger()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lightning-bolt")]),_vm._v("Messwerte")],1),_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addPriceTrigger()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trending-up")]),_vm._v("EPEX Spot Preis")],1),_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addStateOfChargeTrigger()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-battery-charging")]),_vm._v("Ladestatus")],1)],1)])],1),_c('zf-group',_vm._l((_vm.triggers),function(trigger,i){return _c('trigger-component',{key:i,attrs:{"editable":true},on:{"delete":function($event){_vm.triggers = _vm.triggers.filter((_, j) => i !== j)}},model:{value:(_vm.triggers[i]),callback:function ($$v) {_vm.$set(_vm.triggers, i, $$v)},expression:"triggers[i]"}})}),1),_c('div',{staticClass:"line"}),_c('div',{class:{
      'd-flex': _vm.actions.length > 0,
      'justify-space-between': true,
      'align-center': true,
    }},[_c('div',{staticClass:"color-gray"},[_vm._v("Führe folgendes aus")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.actions.length === 0)?_c('zf-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"block":"","large":""}},'zf-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Aktion hinzufügen")],1):_c('zf-btn',_vm._g(_vm._b({staticClass:"top-right",attrs:{"icon":""}},'zf-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('div',{staticClass:"d-flex flex-column white-background gap pa-2 zf-theme"},[_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addDeviceAction()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-toggle-switch")]),_vm._v("Gerät steuern")],1)],1),_c('div',{staticClass:"d-flex flex-column white-background gap pa-2 zf-theme"},[_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addWallboxAction()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ev-station")]),_vm._v("Steuerung Wallbox")],1)],1),_c('div',{staticClass:"d-flex flex-column white-background gap pa-2 zf-theme"},[_c('zf-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addNotificationAction()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bell")]),_vm._v("Benachrichtigung senden")],1)],1)])],1),_c('zf-group',_vm._l((_vm.actions),function(action,i){return _c('action-component',{key:i,attrs:{"editable":true},on:{"delete":function($event){_vm.actions = _vm.actions.filter((_, j) => i !== j)}},model:{value:(_vm.actions[i]),callback:function ($$v) {_vm.$set(_vm.actions, i, $$v)},expression:"actions[i]"}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }