
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Country, Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetAllCountriesRequest, UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { formatNumerOptional, parseFloatOptional } from "@/utils/number-utils";

@Component
export default class ChangeSpaceData extends Vue {
  @Prop({ default: false }) value: boolean;
  @Prop() space: Space;
  openDialog: boolean = false;
  updateSpaceDataCall: RemoteCall<UserError> = initialized;
  countriesList: RemoteData<UserError, Country[]> = initialized;

  address = {
    addressLineOne: "",
    addressLineTwo: "",
    zipCode: "",
    city: "",
  };
  selectedCountry: Country | null = null;
  spaceName: string = "";
  ingestPrice: string = "";

  @Watch("value")
  openDialogFunction(): void {
    this.openDialog = this.value;
  }

  @Watch("space")
  onSpaceChanged(): void {
    this.spaceName = this.space?.getName() ?? "";
    this.ingestPrice = formatNumerOptional(this.space?.getIngestPrice()) ?? "";
    this.address.addressLineOne = this.space?.getAddress()?.getAddressLineOne() ?? "";
    this.address.addressLineTwo = this.space?.getAddress()?.getAddressLineTwo() ?? "";
    this.address.city = this.space?.getAddress()?.getCity() ?? "";
    this.address.zipCode = this.space?.getAddress()?.getPostcode() ?? "";
    this.selectedCountry = this.space?.getAddress()?.getCountry() ?? null;
  }

  async mounted(): Promise<void> {
    this.onSpaceChanged();
    const request = new GetAllCountriesRequest();
    this.countriesList = pending;

    try {
      const response = await spaceServiceClient.getAllCountries(request, {});
      this.countriesList = success(response.getCountryList());
    } catch (error) {
      this.countriesList = failure(userErrorFrom(error));
    }
  }

  countryName(country: Country): string {
    return country.getCountryName();
  }

  countryValue(country: Country): string {
    return country.getId();
  }

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoComplete(): boolean {
    return this.space.hasAddress()
      ? !!this.spaceName &&
          !!this.address.addressLineOne &&
          !!this.address.zipCode &&
          !!this.address.city &&
          !!this.selectedCountry?.getId()
      : !!this.spaceName;
  }

  async onSaveClicked(): Promise<void> {
    const request = new UpdateSpaceRequest();
    if (this.space === null) {
      this.updateSpaceDataCall = failure(userErrorFrom(null));
    }

    request.setSpaceId(this.space?.getId() ?? "");
    request.setName(this.spaceName);

    const ingestPrice = parseFloatOptional(this.ingestPrice);

    if (ingestPrice && ingestPrice >= 0) {
      request.setIngestPrice(ingestPrice);
    }

    if (this.space.hasAddress()) {
      request.setAddressLineOne(this.address.addressLineOne);
      request.setAddressLineTwo(this.address.addressLineTwo);
      request.setCity(this.address.city);
      request.setPostcode(this.address.zipCode);
      request.setCountryId(this.selectedCountry?.getId() ?? "");
    }

    this.updateSpaceDataCall = pending;

    try {
      await spaceServiceClient.updateSpace(request, {});

      this.$emit("input", false);
      this.$emit("spaceUpdated");

      this.updateSpaceDataCall = success(void 0);
    } catch (error) {
      this.updateSpaceDataCall = failure(userErrorFrom(error));
    }
  }
}
