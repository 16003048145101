
import { readingsServiceClient, spaceServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import devices, { devicesModule } from "@/store/modules/devices";
import spaces from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteData, success, successOptional } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { parseFloatOptional } from "@/utils/number-utils";
import { Vue, Component, Watch } from "vue-property-decorator";
import { GetMarketPremiumInfosRequest } from "zaehlerfreunde-central/reading_service_pb";
import {
  GetMarketPremiumConfigsRequest,
  UpdateMarketPremiumConfigsRequest,
} from "zaehlerfreunde-central/space_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import {
  EnergySource,
  MarketPremiumConfigs,
  MarketPremiumInfo,
  MarketValueType,
} from "zaehlerfreunde-proto-types/market_premium_pb";
import { Month, MonthYear } from "zaehlerfreunde-proto-types/time_data_pb";
import MarketPremiumItem from "./MarketPremiumItem.vue";

interface EnergySourceOption {
  value: EnergySource;
  text: string;
}

interface TimeWindow {
  month?: Month;
  year: number;
}

interface TimeWindowOption {
  value: TimeWindow;
  text: string;
}

const months = [
  Month.DECEMBER,
  Month.NOVEMBER,
  Month.OCTOBER,
  Month.SEPTEMBER,
  Month.AUGUST,
  Month.JULY,
  Month.JUNE,
  Month.MAY,
  Month.APRIL,
  Month.MARCH,
  Month.FEBRUARY,
  Month.JANUARY,
];

const monthLabels = {
  [Month.JANUARY]: "Januar",
  [Month.FEBRUARY]: "Februar",
  [Month.MARCH]: "März",
  [Month.APRIL]: "April",
  [Month.MAY]: "Mai",
  [Month.JUNE]: "Juni",
  [Month.JULY]: "Juli",
  [Month.AUGUST]: "August",
  [Month.SEPTEMBER]: "September",
  [Month.OCTOBER]: "Oktober",
  [Month.NOVEMBER]: "November",
  [Month.DECEMBER]: "Dezember",
};

const numSelectableYears = 10;

@Component({
  components: {
    MarketPremiumItem,
  },
})
export default class MarketPremium extends Vue {
  energySourceOptions: EnergySourceOption[] = [
    {
      value: EnergySource.HYDROPOWER,
      text: "Wasserkraft",
    },
    {
      value: EnergySource.LANDFILLGAS,
      text: "Deponiegas",
    },
    {
      value: EnergySource.SEWAGEGAS,
      text: "Klärgas",
    },
    {
      value: EnergySource.MINEGAS,
      text: "Grubengas",
    },
    {
      value: EnergySource.BIOMASS,
      text: "Biomasse",
    },
    {
      value: EnergySource.GEOTHERMAL,
      text: "Geothermie",
    },
    {
      value: EnergySource.WINDONSHORE,
      text: "Wind an Land",
    },
    {
      value: EnergySource.WINDOFFSHORE,
      text: "Wind auf See",
    },
    {
      value: EnergySource.SOLAR,
      text: "Solar",
    },
  ];

  consecutiveNegativePricesOptions = [
    {
      value: 1,
      text: "1",
    },
    {
      value: 2,
      text: "2",
    },
    {
      value: 3,
      text: "3",
    },
    {
      value: 4,
      text: "4",
    },
    {
      value: 5,
      text: "5",
    },
    {
      value: 6,
      text: "6",
    },
  ];

  selectedEnergySource: EnergySource | null = null;
  selectedApplicationPeriod: "monthly" | "yearly" = "monthly";
  selectedTimeWindow: TimeWindow | null = null;
  referenceValue: number | null = null;
  referenceValueStr: string = "";
  referenveValueEdited: boolean = false;
  selectedConsecutiveNegativePrices: number | null = null;

  marketPremiumConfigs: RemoteData<UserError, MarketPremiumConfigs> = initialized;
  marketPremiumInfos: RemoteData<UserError, MarketPremiumInfo[]> = initialized;

  @devicesModule.Getter mainDevice: RemoteData<UserError, Device | null>;

  paths = paths;

  @Watch("selectedApplicationPeriod")
  setDefaultTimeWindow() {
    const now = new Date();
    if (this.selectedApplicationPeriod === "monthly") {
      this.selectedTimeWindow = {
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      };
    } else {
      this.selectedTimeWindow = {
        year: now.getFullYear(),
      };
    }
  }

  applicationPeriods = [
    {
      value: "monthly",
      text: "Monatsmarktwert",
    },
    {
      value: "yearly",
      text: "Jahresmarktwert",
    },
  ];

  get timeWindowOptions(): TimeWindowOption[] {
    const now = new Date();
    const currentYear = new Date().getFullYear();
    const options: TimeWindowOption[] = [];

    if (this.selectedApplicationPeriod === "monthly") {
      for (var i = 0; i < numSelectableYears; i++) {
        options.push(
          ...months
            .filter((m) => i > 0 || now.getMonth() >= m - 1)
            .map((month) => ({
              value: {
                month,
                year: currentYear - i,
              },
              text: `${monthLabels[month]} ${currentYear - i}`,
            }))
        );
      }
    } else {
      for (i = 0; i < numSelectableYears; i++) {
        options.push({
          value: { year: currentYear - i },
          text: `${currentYear - i}`,
        });
      }
    }

    return options;
  }

  mounted(): void {
    this.getMarketPremiumConfigs();
    this.setDefaultTimeWindow();
    devices.getMainDevice();
  }

  onSaveReferenceValueClicked(): void {
    this.referenceValue = parseFloatOptional(this.referenceValueStr) ?? null;
    this.referenveValueEdited = false;
    this.updateMarketPremiumConfigs();
  }

  @Watch("selectedEnergySource")
  @Watch("selectedApplicationPeriod")
  @Watch("selectedTimeWindow")
  @Watch("referenceValue")
  @Watch("selectedConsecutiveNegativePrices")
  async getMarketPremiumInfos(): Promise<void> {
    const request = new GetMarketPremiumInfosRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    const selectedTimeWindow = this.selectedTimeWindow;
    const selectedEnergySource = this.selectedEnergySource;

    if (!selectedTimeWindow || selectedEnergySource === null) {
      return;
    }

    const marketValueType = new MarketValueType();
    if (this.selectedApplicationPeriod == "monthly") {
      marketValueType.setMonth(
        new MonthYear().setMonth(selectedTimeWindow.month ?? Month.JANUARY).setYear(selectedTimeWindow.year)
      );
    } else {
      marketValueType.setYear(selectedTimeWindow.year);
    }

    request.setMarketValueType(marketValueType);
    request.setEnergySource(selectedEnergySource);

    if (this.selectedConsecutiveNegativePrices) {
      request.setNumConsecutiveNegativePrices(this.selectedConsecutiveNegativePrices);
    }

    if (this.referenceValue) {
      request.setReferenceValue(this.referenceValue ?? 0);
    }

    try {
      this.marketPremiumInfos = pending;
      const response = await readingsServiceClient.getMarketPremiumInfos(request, {});
      this.marketPremiumInfos = success(response.getInfosList());
    } catch (error) {
      this.marketPremiumInfos = failure(userErrorFrom(error));
    }
  }

  async updateMarketPremiumConfigs(): Promise<void> {
    try {
      const request = new UpdateMarketPremiumConfigsRequest();
      request.setSpaceId(spaces.selectedSpaceId);

      const configs = new MarketPremiumConfigs();
      configs.setUseYearlyMarketValue(this.selectedApplicationPeriod === "yearly");

      if (this.selectedEnergySource !== null) {
        configs.setEnergySource(this.selectedEnergySource);
      }

      if (this.referenceValue) {
        configs.setReferenceValue(this.referenceValue);
      }

      if (this.selectedConsecutiveNegativePrices) {
        configs.setNumConsecutiveNegativePrices(this.selectedConsecutiveNegativePrices);
      }

      request.setConfigs(configs);
      await spaceServiceClient.updateMarketPremiumConfigs(request, {});
    } catch (error) {
      console.error(error);
    }
  }

  async getMarketPremiumConfigs(): Promise<void> {
    try {
      const request = new GetMarketPremiumConfigsRequest();
      request.setSpaceId(spaces.selectedSpaceId);

      this.marketPremiumConfigs = pending;
      const response = await spaceServiceClient.getMarketPremiumConfigs(request, {});
      const configs = response.getConfigs();

      if (configs) {
        this.marketPremiumConfigs = success(configs);
        this.selectedApplicationPeriod = configs.getUseYearlyMarketValue() ? "yearly" : "monthly";

        if ((configs as any).hasEnergySource()) {
          this.selectedEnergySource = configs.getEnergySource();
        }

        if ((configs as any).hasReferenceValue()) {
          this.referenceValue = configs.getReferenceValue();
          this.referenceValueStr = configs.getReferenceValue().toFixed(2);
        }

        if ((configs as any).hasNumConsecutiveNegativePrices()) {
          this.selectedConsecutiveNegativePrices = configs.getNumConsecutiveNegativePrices();
        }
      }
    } catch (error) {
      this.marketPremiumConfigs = failure(userErrorFrom(error));
    }
  }
}
