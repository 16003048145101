
import { formatNumber, formatNumerOptional } from "@/utils/number-utils";

import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EnergyTariff, GasTariff, WaterTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import DeleteTariff from "./DeleteTariff.vue";
import AddUpdateWaterTariff from "./AddUpdateWaterTariff.vue";
import AddUpdateGasTariff from "./AddUpdateGasTariff.vue";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import AddUpdateEnergyTariff from "./AddUpdateEnergyTariff.vue";
import moment from "moment";

@Component({
  components: {
    AddUpdateEnergyTariff,
    AddUpdateWaterTariff,
    AddUpdateGasTariff,
    DeleteTariff,
  },
})
export default class TariffCard extends Vue {
  @Prop() deviceMedium: Device.Medium;
  @Prop() tariffData: GasTariff | WaterTariff | EnergyTariff;
  @Prop() spaceId: string;
  @Prop({ default: true }) userHasEditPermissions: boolean;

  zfProtoDeviceMedium = Device.Medium;
  showUpdateTariffDialog = false;
  showDeleteTariffDialog = false;

  get energyTariffContent(): string[][] {
    let energyTariff = this.tariffData as EnergyTariff;
    const content = [
      ["Anbieter", energyTariff.getSupplier()?.getName() ?? ""],
      ...(energyTariff.getIsVariable()
        ? this.variableDetails
        : [
            [
              "Arbeitspreis",
              formatNumerOptional(energyTariff.getFixedPriceDetails()?.getPricePerKwh()) ?? "",
              "Cent/kWh",
            ],
          ]),
      ["Ökostrom", energyTariff.getIsGreen() ? "Ja" : "Nein"],
      ["Grundpreis", formatNumber(energyTariff.getBasePrice()), "€/Monat"],
    ];

    if (energyTariff.getKgCo2PerKwh() > 0) {
      content.push(["CO2 Ausstoß", formatNumber(energyTariff.getKgCo2PerKwh()), "kg/kWh"]);
    }

    if (energyTariff.getStartDate()) {
      content.push(["Startdatum", new Date(energyTariff.getStartDate()).toLocaleDateString()]);
    }

    content.push(["Kündbar ab dem", new Date(energyTariff.getEarliestCancelDate()).toLocaleDateString()]);

    return content;
  }

  get variableDetails(): string[][] {
    let energyTariff = this.tariffData as EnergyTariff;
    const priceDetails = energyTariff.getVariablePriceDetails();

    if (!priceDetails) {
      return [];
    }

    const content = [
      ["Arbeitspreis", `Börsenpreis + ${formatNumber(priceDetails.getSurchargePerKwh() ?? 0)}`, "Cent/kWh"],
    ];

    if (priceDetails.getMinPricePerKwh() && priceDetails.getMaxPricePerKwh()) {
      content.push(["Minimalpreis", formatNumber(priceDetails.getMinPricePerKwh()), "Cent/kWh"]);
    }

    if (priceDetails.getMaxPricePerKwh()) {
      content.push(["Maximalpreis", formatNumber(priceDetails.getMaxPricePerKwh()), "Cent/kWh"]);
    }

    return content;
  }

  get waterTariffContent(): string[][] {
    let waterTariff = this.tariffData as WaterTariff;
    const content = [
      ["Anbieter", waterTariff.getSupplierName() ?? ""],
      ["Arbeitspreis", formatNumerOptional(waterTariff.getPricePerM3()) ?? "", "€/m3"],
      ["Grundpreis", formatNumber(waterTariff.getBasePrice()), "€/Monat"],
    ];

    if (waterTariff.getStartDate()) {
      content.push(["Startdatum", new Date(waterTariff.getStartDate()).toLocaleDateString()]);
    }

    return content;
  }

  get gasTariffContent(): string[][] {
    let gasTariff = this.tariffData as GasTariff;
    const content = [
      ["Anbieter", gasTariff.getSupplierName() ?? ""],
      ["Arbeitspreis", formatNumerOptional(gasTariff.getPricePerKwh()) ?? "", "Cent/kWh"],
      ["Grundpreis", formatNumber(gasTariff.getBasePrice()), "€/Monat"],
      ["Ökogas", gasTariff.getIsGreen() ? "Ja" : "Nein"],
    ];

    if (gasTariff.getKgCo2PerKwh() > 0) {
      content.push(["CO2 Ausstoß", formatNumber(gasTariff.getKgCo2PerKwh()), "kg/kWh"]);
    }

    if (gasTariff.getStartDate()) {
      content.push(["Startdatum", new Date(gasTariff.getStartDate()).toLocaleDateString()]);
    }

    return content;
  }

  get tariffContent(): string[][] {
    switch (this.deviceMedium) {
      case Device.Medium.ELECTRICITY:
        return this.energyTariffContent;
      case Device.Medium.WATER:
        return this.waterTariffContent;
      case Device.Medium.GAS:
        return this.gasTariffContent;
    }
    return [];
  }
}
