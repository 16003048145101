
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteLandlordToTenantProjectRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";

@Component
export default class DeleteLandlordToTenantProjectDialog extends Vue {
  @Prop() projectId: string;

  deleteProjectCall: RemoteCall<UserError> = initialized;

  async onDeleteClicked(): Promise<void> {
    try {
      this.deleteProjectCall = pending;
      const request = new DeleteLandlordToTenantProjectRequest();
      request.setProjectId(this.projectId);

      await landlordToTenantServiceClient.deleteLandlordToTenantProject(request, {});

      this.deleteProjectCall = success(void 0);

      this.$router.go(-1);
    } catch (error) {
      this.deleteProjectCall = failure(userErrorFrom(error));
    }
  }
}
