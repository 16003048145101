
import { Vue, Component, Prop } from "vue-property-decorator";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import SpacesTable from "@/components/spaces-table/SpacesTable.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { GetSpacesRequest } from "zaehlerfreunde-central/space_service_pb";
import AddSpaceToUserDialog from "./AddSpaceToUserDialog.vue";
import { User } from "zaehlerfreunde-proto-types/user_pb";

import RemoveUserDialog from "@/components/remove-user-dialog/RemoveUserDialog.vue";
@Component({ components: { SpacesTable, AddSpaceToUserDialog, RemoveUserDialog } })
export default class UserSpaces extends Vue {
  @Prop({ default: null }) selectedUser: User | null;
  spacesList: RemoteData<UserError, Space[]> = initialized;
  addSpaceDialog: boolean = false;
  removeUserDialog: boolean = false;
  selectedSpaceId: string = "";

  headerControls = [
    {
      icon: "mdi-plus-circle",
      event: "add-space",
    },
  ];

  itemControls = [
    {
      icon: "mdi-link-variant-off",
      event: "remove-space",
    },
  ];

  mounted(): void {
    this.loadUserSpaces();
  }

  spaceConnected(): void {
    this.addSpaceDialog = false;
    this.loadUserSpaces();
  }

  async loadUserSpaces(): Promise<void> {
    this.removeUserDialog = false;
    this.spacesList = pending;
    const spaceRequest = new GetSpacesRequest();
    spaceRequest.setUserId(this.$route.params.userId);
    try {
      const response = await spaceServiceClient.getSpaces(spaceRequest, {});

      this.spacesList = success(response.getSpacesList());
    } catch (error) {
      this.spacesList = failure(userErrorFrom(error));
    }
  }
  openRemoveUserDialog(spaceId: string): void {
    this.selectedSpaceId = spaceId;
    this.removeUserDialog = true;
  }
}
