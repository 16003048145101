
import devices, { devicesModule } from "@/store/modules/devices";
import { spacesModule } from "@/store/modules/spaces";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import DeleteDevice from "./DeleteDevice.vue";
import CsvExport from "@/components/CSVExport.vue";
import { subscriptionModule } from "@/store/modules/subscription";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { getMediumColor, getMediumIcon, getMediumName } from "@/utils/device-utils";
import { paths } from "@/router/routes";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import DeviceSettings from "./DeviceSettings.vue";

@Component({
  components: {
    DeviceSettings,
    DeleteDevice,
    CsvExport,
  },
})
export default class Devices extends Vue {
  @devicesModule.Getter devicesWithoutChildSpaces: RemoteData<UserError, Device[]>;
  @spacesModule.State selectedSpace: Space | null;
  @spacesModule.Getter selectedSpaceName: string;
  @subscriptionModule.Getter currentPlan: SubscriptionPlan;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @partnerModule.Getter supportEmail: string;

  DeviceStatus = Device.Status;
  DeviceMedium = Device.Medium;
  getMediumIcon = getMediumIcon;
  getMediumName = getMediumName;
  getMediumColor = getMediumColor;

  deviceToDelete: Device | null = null;
  displayDetailsOfDevice: Device | null = null;
  showCsvExportDialog: boolean = false;

  metaInfo(): { title: string } {
    return {
      title: "Geräte",
    };
  }

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    this.loadConnectedDevices();
  }

  mounted(): void {
    this.loadConnectedDevices();
  }

  loadConnectedDevices(): void {
    if (this.selectedSpace) {
      devices.getDevices();
    }
  }

  onConnectDeviceClicked(): void {
    if (this.partner.data?.getType() === Partner.Type.B2B && this.currentPlan === SubscriptionPlan.FREE) {
      this.$router.push({ path: paths.platform.account.subscriptions, query: { requiredFeature: "b2b" } });
    } else if (this.currentPlan === SubscriptionPlan.FREE && this.devicesWithoutChildSpaces.list.length > 0) {
      this.$router.push({ path: paths.platform.account.subscriptions, query: { requiredFeature: "multiple-devices" } });
    } else {
      this.$router.push(paths.platform.connectDevice);
    }
  }

  onDeleteDeviceClicked(event: CustomEvent, device: Device): void {
    event.stopPropagation();
    this.deviceToDelete = device;
  }

  onCsvExportClicked(): void {
    if (this.currentPlan === SubscriptionPlan.FREE) {
      this.$router.push({ path: paths.platform.account.subscriptions, query: { requiredFeature: "csv-export" } });
    } else {
      this.showCsvExportDialog = true;
    }
  }
}
