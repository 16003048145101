// source: types/device.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.Device', null, global);
goog.exportSymbol('proto.types.Device.Medium', null, global);
goog.exportSymbol('proto.types.Device.Provider', null, global);
goog.exportSymbol('proto.types.Device.Status', null, global);
goog.exportSymbol('proto.types.Device.Type', null, global);
goog.exportSymbol('proto.types.DeviceMetaData', null, global);
goog.exportSymbol('proto.types.DeviceProviderInfo', null, global);
goog.exportSymbol('proto.types.DeviceTypeInfo', null, global);
goog.exportSymbol('proto.types.MetaDataKind', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Device.repeatedFields_, null);
};
goog.inherits(proto.types.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Device.displayName = 'proto.types.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceMetaData.displayName = 'proto.types.DeviceMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceTypeInfo.displayName = 'proto.types.DeviceTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DeviceProviderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DeviceProviderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DeviceProviderInfo.displayName = 'proto.types.DeviceProviderInfo';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Device.repeatedFields_ = [25,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 20, ""),
    status: jspb.Message.getFieldWithDefault(msg, 21, 0),
    latestReadingTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    firstReadingTime: jspb.Message.getFieldWithDefault(msg, 35, 0),
    provider: jspb.Message.getFieldWithDefault(msg, 22, 0),
    deviceProviderInfo: (f = msg.getDeviceProviderInfo()) && proto.types.DeviceProviderInfo.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 23, 0),
    deviceTypeInfo: (f = msg.getDeviceTypeInfo()) && proto.types.DeviceTypeInfo.toObject(includeInstance, f),
    isMainDevice: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    metaDataList: jspb.Message.toObjectList(msg.getMetaDataList(),
    proto.types.DeviceMetaData.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 26, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 27, ""),
    medium: jspb.Message.getFieldWithDefault(msg, 28, 0),
    mediumsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    imageUrl: jspb.Message.getFieldWithDefault(msg, 31, ""),
    ownerUserId: jspb.Message.getFieldWithDefault(msg, 33, ""),
    externalDeviceId: jspb.Message.getFieldWithDefault(msg, 34, ""),
    belongsToChildSpace: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    childSpaceId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Device}
 */
proto.types.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Device;
  return proto.types.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Device}
 */
proto.types.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 21:
      var value = /** @type {!proto.types.Device.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestReadingTime(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstReadingTime(value);
      break;
    case 22:
      var value = /** @type {!proto.types.Device.Provider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 29:
      var value = new proto.types.DeviceProviderInfo;
      reader.readMessage(value,proto.types.DeviceProviderInfo.deserializeBinaryFromReader);
      msg.setDeviceProviderInfo(value);
      break;
    case 23:
      var value = /** @type {!proto.types.Device.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 30:
      var value = new proto.types.DeviceTypeInfo;
      reader.readMessage(value,proto.types.DeviceTypeInfo.deserializeBinaryFromReader);
      msg.setDeviceTypeInfo(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMainDevice(value);
      break;
    case 25:
      var value = new proto.types.DeviceMetaData;
      reader.readMessage(value,proto.types.DeviceMetaData.deserializeBinaryFromReader);
      msg.addMetaData(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 28:
      var value = /** @type {!proto.types.Device.Medium} */ (reader.readEnum());
      msg.setMedium(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.types.Device.Medium>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMediums(values[i]);
      }
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerUserId(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDeviceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBelongsToChildSpace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChildSpaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 35));
  if (f != null) {
    writer.writeInt64(
      35,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getDeviceProviderInfo();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.types.DeviceProviderInfo.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getDeviceTypeInfo();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.types.DeviceTypeInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsMainDevice();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getMetaDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.types.DeviceMetaData.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getMediumsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getOwnerUserId();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getBelongsToChildSpace();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.Device.Status = {
  CONNECTED: 0,
  CONNECTING: 1,
  FAILED: 2
};

/**
 * @enum {number}
 */
proto.types.Device.Provider = {
  BLUEMETERING: 0,
  DISCOVERGY: 1,
  SMARTME: 2,
  BSED: 3,
  EASEE: 4,
  SHELLY: 5,
  POWERFOX: 6,
  METERGRID: 7,
  WATTLINE: 8,
  ZAEHLERHELDEN: 9,
  MSCONS: 10,
  IMOVIS: 11,
  IMPORTED: 12,
  NON_SMART_METER_PROVIDER: 13,
  GO_E: 14,
  MYSTROM: 15,
  VIESSMANN: 16,
  TSUN: 17,
  EXTERNAL: 18,
  EDA: 19,
  AUXILIUS: 20,
  TIBBER: 21,
  OCPP: 22,
  BITSHAKE: 23,
  LOBARO: 24,
  VIRTUAL: 25,
  SOLAREDGE: 26,
  METIUNDO: 27,
  CALCULATION: 29,
  ENBW: 30,
  IOMETER: 31
};

/**
 * @enum {number}
 */
proto.types.Device.Type = {
  SMART_METER: 0,
  OPTICAL_METER_SENSOR: 1,
  WALLBOX: 2,
  SMART_PLUG: 3,
  SMART_RELAY: 4,
  VIRTUAL_LANDLORD_TO_TENANT_METER: 5,
  ENERGY_METER: 6,
  NON_SMART_METER: 7,
  HEAT_PUMP: 8,
  MICRO_INVERTER: 9,
  VIRTUAL_METER: 10
};

/**
 * @enum {number}
 */
proto.types.Device.Medium = {
  ELECTRICITY: 0,
  GAS: 1,
  WATER: 2,
  HEATING: 3,
  STATE_OF_CHARGE: 4
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Device.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 20;
 * @return {string}
 */
proto.types.Device.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional Status status = 21;
 * @return {!proto.types.Device.Status}
 */
proto.types.Device.prototype.getStatus = function() {
  return /** @type {!proto.types.Device.Status} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.types.Device.Status} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional int64 latest_reading_time = 2;
 * @return {number}
 */
proto.types.Device.prototype.getLatestReadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setLatestReadingTime = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearLatestReadingTime = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasLatestReadingTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 first_reading_time = 35;
 * @return {number}
 */
proto.types.Device.prototype.getFirstReadingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setFirstReadingTime = function(value) {
  return jspb.Message.setField(this, 35, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearFirstReadingTime = function() {
  return jspb.Message.setField(this, 35, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasFirstReadingTime = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional Provider provider = 22;
 * @return {!proto.types.Device.Provider}
 */
proto.types.Device.prototype.getProvider = function() {
  return /** @type {!proto.types.Device.Provider} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.types.Device.Provider} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional DeviceProviderInfo device_provider_info = 29;
 * @return {?proto.types.DeviceProviderInfo}
 */
proto.types.Device.prototype.getDeviceProviderInfo = function() {
  return /** @type{?proto.types.DeviceProviderInfo} */ (
    jspb.Message.getWrapperField(this, proto.types.DeviceProviderInfo, 29));
};


/**
 * @param {?proto.types.DeviceProviderInfo|undefined} value
 * @return {!proto.types.Device} returns this
*/
proto.types.Device.prototype.setDeviceProviderInfo = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearDeviceProviderInfo = function() {
  return this.setDeviceProviderInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasDeviceProviderInfo = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional Type type = 23;
 * @return {!proto.types.Device.Type}
 */
proto.types.Device.prototype.getType = function() {
  return /** @type {!proto.types.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.types.Device.Type} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional DeviceTypeInfo device_type_info = 30;
 * @return {?proto.types.DeviceTypeInfo}
 */
proto.types.Device.prototype.getDeviceTypeInfo = function() {
  return /** @type{?proto.types.DeviceTypeInfo} */ (
    jspb.Message.getWrapperField(this, proto.types.DeviceTypeInfo, 30));
};


/**
 * @param {?proto.types.DeviceTypeInfo|undefined} value
 * @return {!proto.types.Device} returns this
*/
proto.types.Device.prototype.setDeviceTypeInfo = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearDeviceTypeInfo = function() {
  return this.setDeviceTypeInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasDeviceTypeInfo = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool is_main_device = 24;
 * @return {boolean}
 */
proto.types.Device.prototype.getIsMainDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setIsMainDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * repeated DeviceMetaData meta_data = 25;
 * @return {!Array<!proto.types.DeviceMetaData>}
 */
proto.types.Device.prototype.getMetaDataList = function() {
  return /** @type{!Array<!proto.types.DeviceMetaData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.DeviceMetaData, 25));
};


/**
 * @param {!Array<!proto.types.DeviceMetaData>} value
 * @return {!proto.types.Device} returns this
*/
proto.types.Device.prototype.setMetaDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.types.DeviceMetaData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DeviceMetaData}
 */
proto.types.Device.prototype.addMetaData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.types.DeviceMetaData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearMetaDataList = function() {
  return this.setMetaDataList([]);
};


/**
 * optional int64 created_at = 26;
 * @return {number}
 */
proto.types.Device.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string error_message = 27;
 * @return {string}
 */
proto.types.Device.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setErrorMessage = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearErrorMessage = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasErrorMessage = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional Medium medium = 28;
 * @return {!proto.types.Device.Medium}
 */
proto.types.Device.prototype.getMedium = function() {
  return /** @type {!proto.types.Device.Medium} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.types.Device.Medium} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setMedium = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * repeated Medium mediums = 5;
 * @return {!Array<!proto.types.Device.Medium>}
 */
proto.types.Device.prototype.getMediumsList = function() {
  return /** @type {!Array<!proto.types.Device.Medium>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.types.Device.Medium>} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setMediumsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.types.Device.Medium} value
 * @param {number=} opt_index
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.addMediums = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearMediumsList = function() {
  return this.setMediumsList([]);
};


/**
 * optional string image_url = 31;
 * @return {string}
 */
proto.types.Device.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string owner_user_id = 33;
 * @return {string}
 */
proto.types.Device.prototype.getOwnerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setOwnerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string external_device_id = 34;
 * @return {string}
 */
proto.types.Device.prototype.getExternalDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setExternalDeviceId = function(value) {
  return jspb.Message.setField(this, 34, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearExternalDeviceId = function() {
  return jspb.Message.setField(this, 34, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasExternalDeviceId = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional bool belongs_to_child_space = 3;
 * @return {boolean}
 */
proto.types.Device.prototype.getBelongsToChildSpace = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setBelongsToChildSpace = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string child_space_id = 4;
 * @return {string}
 */
proto.types.Device.prototype.getChildSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.setChildSpaceId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Device} returns this
 */
proto.types.Device.prototype.clearChildSpaceId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Device.prototype.hasChildSpaceId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceMetaData}
 */
proto.types.DeviceMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceMetaData;
  return proto.types.DeviceMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceMetaData}
 */
proto.types.DeviceMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.types.MetaDataKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.types.DeviceMetaData.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceMetaData} returns this
 */
proto.types.DeviceMetaData.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.types.DeviceMetaData.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceMetaData} returns this
 */
proto.types.DeviceMetaData.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MetaDataKind kind = 3;
 * @return {!proto.types.MetaDataKind}
 */
proto.types.DeviceMetaData.prototype.getKind = function() {
  return /** @type {!proto.types.MetaDataKind} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.MetaDataKind} value
 * @return {!proto.types.DeviceMetaData} returns this
 */
proto.types.DeviceMetaData.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mainDeviceAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    canSkipProviderSelection: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceTypeInfo}
 */
proto.types.DeviceTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceTypeInfo;
  return proto.types.DeviceTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceTypeInfo}
 */
proto.types.DeviceTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Device.Type} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMainDeviceAllowed(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSkipProviderSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMainDeviceAllowed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCanSkipProviderSelection();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional Device.Type value = 1;
 * @return {!proto.types.Device.Type}
 */
proto.types.DeviceTypeInfo.prototype.getValue = function() {
  return /** @type {!proto.types.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Device.Type} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.DeviceTypeInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.types.DeviceTypeInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.types.DeviceTypeInfo.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string icon = 5;
 * @return {string}
 */
proto.types.DeviceTypeInfo.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool main_device_allowed = 6;
 * @return {boolean}
 */
proto.types.DeviceTypeInfo.prototype.getMainDeviceAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setMainDeviceAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool can_skip_provider_selection = 7;
 * @return {boolean}
 */
proto.types.DeviceTypeInfo.prototype.getCanSkipProviderSelection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.DeviceTypeInfo} returns this
 */
proto.types.DeviceTypeInfo.prototype.setCanSkipProviderSelection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DeviceProviderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DeviceProviderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DeviceProviderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceProviderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    instructionsPath: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DeviceProviderInfo}
 */
proto.types.DeviceProviderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DeviceProviderInfo;
  return proto.types.DeviceProviderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DeviceProviderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DeviceProviderInfo}
 */
proto.types.DeviceProviderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Device.Provider} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstructionsPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DeviceProviderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DeviceProviderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DeviceProviderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DeviceProviderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Device.Provider value = 1;
 * @return {!proto.types.Device.Provider}
 */
proto.types.DeviceProviderInfo.prototype.getValue = function() {
  return /** @type {!proto.types.Device.Provider} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Device.Provider} value
 * @return {!proto.types.DeviceProviderInfo} returns this
 */
proto.types.DeviceProviderInfo.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.DeviceProviderInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceProviderInfo} returns this
 */
proto.types.DeviceProviderInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.types.DeviceProviderInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceProviderInfo} returns this
 */
proto.types.DeviceProviderInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo_url = 4;
 * @return {string}
 */
proto.types.DeviceProviderInfo.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceProviderInfo} returns this
 */
proto.types.DeviceProviderInfo.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string instructions_path = 5;
 * @return {string}
 */
proto.types.DeviceProviderInfo.prototype.getInstructionsPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.DeviceProviderInfo} returns this
 */
proto.types.DeviceProviderInfo.prototype.setInstructionsPath = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.DeviceProviderInfo} returns this
 */
proto.types.DeviceProviderInfo.prototype.clearInstructionsPath = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.DeviceProviderInfo.prototype.hasInstructionsPath = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.types.MetaDataKind = {
  MARKET_LOCATION_NUMBER: 0,
  MEASURING_LOCATION_NUMBER: 1,
  SERIAL_NUMBER: 2,
  CONVERSION_FACTOR: 3,
  SMARTME_ID: 4,
  CUSTOMER_NAME: 5,
  STREET: 6,
  ZIP_CODE: 7,
  CITY: 8,
  EDA_METERING_POINT: 9,
  AUXILIUS_METERING_CODE: 10,
  METER_PIN: 11,
  PROVIDER: 12,
  OCPP_WEBSOCKET_URL: 13,
  LOBARO_GATEWAY_ID: 14,
  LOBARO_ID_DLL: 15,
  SOLAREDGE_SITE_ID: 16
};

goog.object.extend(exports, proto.types);
