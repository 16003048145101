
import spaces from "@/store/modules/spaces";
import tariff from "@/store/modules/tariff";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { tariffServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SetIsWatchdogEnabledRequest } from "zaehlerfreunde-central/tariff_service_pb";

@Component
export default class DisableWatchdog extends Vue {
  @Prop() value: boolean;

  call: RemoteCall<UserError> = initialized;

  async onDisableClicked(): Promise<void> {
    try {
      this.call = pending;
      const request = new SetIsWatchdogEnabledRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setIsWatchdogEnabled(false);
      await tariffServiceClient.setIsWatchdogEnabled(request, {});
      this.call = success(void 0);
      tariff.getTariffSearchInfo();
      this.$emit("input", false);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }
}
