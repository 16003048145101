
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import CurrentMonth from "./CurrentMonth.vue";
import MonthlyPayments from "../costs/MonthlyPayments.vue";
import CostPrediction from "../costs/CostPrediction.vue";
import {
  AdvancePaymentWidgetData,
  GetAdvancePaymentWidgetDataRequest,
  GetCurrentMonthWidgetDataRequest,
} from "zaehlerfreunde-central/ui_service_pb";
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { EnergyTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { devicesModule } from "@/store/modules/devices";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { uiServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import AddTariffReminder from "../AddTariffReminder.vue";

@Component({
  components: {
    CurrentMonth,
    MonthlyPayments,
    CostPrediction,
    AddTariffReminder,
  },
})
export default class CurrentMonthCosts extends Vue {
  @Ref("carousel") readonly carousel: HTMLDivElement;
  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff>;
  @tariffModule.Getter currentGasTariff: RemoteData<UserError, EnergyTariff>;
  @devicesModule.Getter mainDevice: RemoteData<UserError, Device>;

  advancePaymentData: RemoteData<UserError, AdvancePaymentWidgetData | null> = initialized;
  selectedDeviceId: string = "";
  CurrentMonthDataType = GetCurrentMonthWidgetDataRequest.DataType;

  get showAdvancePaymentWidget(): boolean {
    return (
      this.mainDevice.data?.getMedium() == Device.Medium.ELECTRICITY &&
      !(this.currentEnergyTariff.data?.getIsVariable() ?? true) &&
      this.mainDevice.data?.getId() == this.selectedDeviceId
    );
  }

  @Watch("selectedDeviceId")
  loadAdvancePaymentData(): void {
    if (this.selectedDeviceId) {
      this.advancePaymentData = pending;
      const request = new GetAdvancePaymentWidgetDataRequest();
      request.setDeviceId(this.selectedDeviceId);
      request.setSpaceId(spaces.selectedSpaceId);
      uiServiceClient
        .getAdvancePaymentWidgetData(request, {})
        .then((response) => (this.advancePaymentData = success(response.getData() ?? null)))
        .catch((error) => (this.advancePaymentData = failure(userErrorFrom(error))));
    }
  }
}
