/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_tariff_service_pb from '../../services/central/tariff_service_pb';


export class TariffServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetTariffs = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetTariffs',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetTariffsRequest,
    services_central_tariff_service_pb.GetTariffsResponse,
    (request: services_central_tariff_service_pb.GetTariffsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetTariffsResponse.deserializeBinary
  );

  getTariffs(
    request: services_central_tariff_service_pb.GetTariffsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetTariffsResponse>;

  getTariffs(
    request: services_central_tariff_service_pb.GetTariffsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetTariffsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetTariffsResponse>;

  getTariffs(
    request: services_central_tariff_service_pb.GetTariffsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetTariffsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetTariffs',
        request,
        metadata || {},
        this.methodInfoGetTariffs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetTariffs',
    request,
    metadata || {},
    this.methodInfoGetTariffs);
  }

  methodInfoGetCurrentTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetCurrentTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetCurrentTariffRequest,
    services_central_tariff_service_pb.GetCurrentTariffResponse,
    (request: services_central_tariff_service_pb.GetCurrentTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetCurrentTariffResponse.deserializeBinary
  );

  getCurrentTariff(
    request: services_central_tariff_service_pb.GetCurrentTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetCurrentTariffResponse>;

  getCurrentTariff(
    request: services_central_tariff_service_pb.GetCurrentTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCurrentTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetCurrentTariffResponse>;

  getCurrentTariff(
    request: services_central_tariff_service_pb.GetCurrentTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCurrentTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetCurrentTariff',
        request,
        metadata || {},
        this.methodInfoGetCurrentTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetCurrentTariff',
    request,
    metadata || {},
    this.methodInfoGetCurrentTariff);
  }

  methodInfoAddTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/AddTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.AddTariffRequest,
    services_central_tariff_service_pb.AddTariffResponse,
    (request: services_central_tariff_service_pb.AddTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.AddTariffResponse.deserializeBinary
  );

  addTariff(
    request: services_central_tariff_service_pb.AddTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.AddTariffResponse>;

  addTariff(
    request: services_central_tariff_service_pb.AddTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.AddTariffResponse>;

  addTariff(
    request: services_central_tariff_service_pb.AddTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/AddTariff',
        request,
        metadata || {},
        this.methodInfoAddTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/AddTariff',
    request,
    metadata || {},
    this.methodInfoAddTariff);
  }

  methodInfoUpdateTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/UpdateTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.UpdateTariffRequest,
    services_central_tariff_service_pb.UpdateTariffResponse,
    (request: services_central_tariff_service_pb.UpdateTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.UpdateTariffResponse.deserializeBinary
  );

  updateTariff(
    request: services_central_tariff_service_pb.UpdateTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.UpdateTariffResponse>;

  updateTariff(
    request: services_central_tariff_service_pb.UpdateTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.UpdateTariffResponse>;

  updateTariff(
    request: services_central_tariff_service_pb.UpdateTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/UpdateTariff',
        request,
        metadata || {},
        this.methodInfoUpdateTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/UpdateTariff',
    request,
    metadata || {},
    this.methodInfoUpdateTariff);
  }

  methodInfoDeleteTariff = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/DeleteTariff',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.DeleteTariffRequest,
    services_central_tariff_service_pb.DeleteTariffResponse,
    (request: services_central_tariff_service_pb.DeleteTariffRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.DeleteTariffResponse.deserializeBinary
  );

  deleteTariff(
    request: services_central_tariff_service_pb.DeleteTariffRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.DeleteTariffResponse>;

  deleteTariff(
    request: services_central_tariff_service_pb.DeleteTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteTariffResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.DeleteTariffResponse>;

  deleteTariff(
    request: services_central_tariff_service_pb.DeleteTariffRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteTariffResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/DeleteTariff',
        request,
        metadata || {},
        this.methodInfoDeleteTariff,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/DeleteTariff',
    request,
    metadata || {},
    this.methodInfoDeleteTariff);
  }

  methodInfoUpdateSupplierCustomerId = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/UpdateSupplierCustomerId',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse,
    (request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse.deserializeBinary
  );

  updateSupplierCustomerId(
    request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse>;

  updateSupplierCustomerId(
    request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse>;

  updateSupplierCustomerId(
    request: services_central_tariff_service_pb.UpdateSupplierCustomerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.UpdateSupplierCustomerIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/UpdateSupplierCustomerId',
        request,
        metadata || {},
        this.methodInfoUpdateSupplierCustomerId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/UpdateSupplierCustomerId',
    request,
    metadata || {},
    this.methodInfoUpdateSupplierCustomerId);
  }

  methodInfoGetSearchResults = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetSearchResults',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetSearchResultsRequest,
    services_central_tariff_service_pb.GetSearchResultsResponse,
    (request: services_central_tariff_service_pb.GetSearchResultsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetSearchResultsResponse.deserializeBinary
  );

  getSearchResults(
    request: services_central_tariff_service_pb.GetSearchResultsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetSearchResultsResponse>;

  getSearchResults(
    request: services_central_tariff_service_pb.GetSearchResultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchResultsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetSearchResultsResponse>;

  getSearchResults(
    request: services_central_tariff_service_pb.GetSearchResultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchResultsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetSearchResults',
        request,
        metadata || {},
        this.methodInfoGetSearchResults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetSearchResults',
    request,
    metadata || {},
    this.methodInfoGetSearchResults);
  }

  methodInfoGetPendingSwitch = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetPendingSwitch',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetPendingSwitchRequest,
    services_central_tariff_service_pb.GetPendingSwitchResponse,
    (request: services_central_tariff_service_pb.GetPendingSwitchRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetPendingSwitchResponse.deserializeBinary
  );

  getPendingSwitch(
    request: services_central_tariff_service_pb.GetPendingSwitchRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetPendingSwitchResponse>;

  getPendingSwitch(
    request: services_central_tariff_service_pb.GetPendingSwitchRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPendingSwitchResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetPendingSwitchResponse>;

  getPendingSwitch(
    request: services_central_tariff_service_pb.GetPendingSwitchRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetPendingSwitchResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetPendingSwitch',
        request,
        metadata || {},
        this.methodInfoGetPendingSwitch,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetPendingSwitch',
    request,
    metadata || {},
    this.methodInfoGetPendingSwitch);
  }

  methodInfoGetSearchInfo = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetSearchInfo',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetSearchInfoRequest,
    services_central_tariff_service_pb.GetSearchInfoResponse,
    (request: services_central_tariff_service_pb.GetSearchInfoRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetSearchInfoResponse.deserializeBinary
  );

  getSearchInfo(
    request: services_central_tariff_service_pb.GetSearchInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetSearchInfoResponse>;

  getSearchInfo(
    request: services_central_tariff_service_pb.GetSearchInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchInfoResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetSearchInfoResponse>;

  getSearchInfo(
    request: services_central_tariff_service_pb.GetSearchInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetSearchInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetSearchInfo',
        request,
        metadata || {},
        this.methodInfoGetSearchInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetSearchInfo',
    request,
    metadata || {},
    this.methodInfoGetSearchInfo);
  }

  methodInfoGetEstimatedConsumption = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetEstimatedConsumption',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    services_central_tariff_service_pb.GetEstimatedConsumptionResponse,
    (request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetEstimatedConsumptionResponse.deserializeBinary
  );

  getEstimatedConsumption(
    request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetEstimatedConsumptionResponse>;

  getEstimatedConsumption(
    request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetEstimatedConsumptionResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetEstimatedConsumptionResponse>;

  getEstimatedConsumption(
    request: services_central_tariff_service_pb.GetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetEstimatedConsumptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetEstimatedConsumption',
        request,
        metadata || {},
        this.methodInfoGetEstimatedConsumption,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetEstimatedConsumption',
    request,
    metadata || {},
    this.methodInfoGetEstimatedConsumption);
  }

  methodInfoSetEstimatedConsumption = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/SetEstimatedConsumption',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    services_central_tariff_service_pb.SetEstimatedConsumptionResponse,
    (request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.SetEstimatedConsumptionResponse.deserializeBinary
  );

  setEstimatedConsumption(
    request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.SetEstimatedConsumptionResponse>;

  setEstimatedConsumption(
    request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetEstimatedConsumptionResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.SetEstimatedConsumptionResponse>;

  setEstimatedConsumption(
    request: services_central_tariff_service_pb.SetEstimatedConsumptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetEstimatedConsumptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/SetEstimatedConsumption',
        request,
        metadata || {},
        this.methodInfoSetEstimatedConsumption,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/SetEstimatedConsumption',
    request,
    metadata || {},
    this.methodInfoSetEstimatedConsumption);
  }

  methodInfoSetIsWatchdogEnabled = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/SetIsWatchdogEnabled',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    services_central_tariff_service_pb.SetIsWatchdogEnabledResponse,
    (request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.SetIsWatchdogEnabledResponse.deserializeBinary
  );

  setIsWatchdogEnabled(
    request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.SetIsWatchdogEnabledResponse>;

  setIsWatchdogEnabled(
    request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetIsWatchdogEnabledResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.SetIsWatchdogEnabledResponse>;

  setIsWatchdogEnabled(
    request: services_central_tariff_service_pb.SetIsWatchdogEnabledRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SetIsWatchdogEnabledResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/SetIsWatchdogEnabled',
        request,
        metadata || {},
        this.methodInfoSetIsWatchdogEnabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/SetIsWatchdogEnabled',
    request,
    metadata || {},
    this.methodInfoSetIsWatchdogEnabled);
  }

  methodInfoGetCharityProjects = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetCharityProjects',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetCharityProjectsRequest,
    services_central_tariff_service_pb.GetCharityProjectsResponse,
    (request: services_central_tariff_service_pb.GetCharityProjectsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetCharityProjectsResponse.deserializeBinary
  );

  getCharityProjects(
    request: services_central_tariff_service_pb.GetCharityProjectsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetCharityProjectsResponse>;

  getCharityProjects(
    request: services_central_tariff_service_pb.GetCharityProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCharityProjectsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetCharityProjectsResponse>;

  getCharityProjects(
    request: services_central_tariff_service_pb.GetCharityProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetCharityProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetCharityProjects',
        request,
        metadata || {},
        this.methodInfoGetCharityProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetCharityProjects',
    request,
    metadata || {},
    this.methodInfoGetCharityProjects);
  }

  methodInfoSelectCharityProject = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/SelectCharityProject',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.SelectCharityProjectRequest,
    services_central_tariff_service_pb.SelectCharityProjectResponse,
    (request: services_central_tariff_service_pb.SelectCharityProjectRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.SelectCharityProjectResponse.deserializeBinary
  );

  selectCharityProject(
    request: services_central_tariff_service_pb.SelectCharityProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.SelectCharityProjectResponse>;

  selectCharityProject(
    request: services_central_tariff_service_pb.SelectCharityProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SelectCharityProjectResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.SelectCharityProjectResponse>;

  selectCharityProject(
    request: services_central_tariff_service_pb.SelectCharityProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.SelectCharityProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/SelectCharityProject',
        request,
        metadata || {},
        this.methodInfoSelectCharityProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/SelectCharityProject',
    request,
    metadata || {},
    this.methodInfoSelectCharityProject);
  }

  methodInfoGetAdvancePayments = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/GetAdvancePayments',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    services_central_tariff_service_pb.GetAdvancePaymentsResponse,
    (request: services_central_tariff_service_pb.GetAdvancePaymentsRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.GetAdvancePaymentsResponse.deserializeBinary
  );

  getAdvancePayments(
    request: services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.GetAdvancePaymentsResponse>;

  getAdvancePayments(
    request: services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetAdvancePaymentsResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.GetAdvancePaymentsResponse>;

  getAdvancePayments(
    request: services_central_tariff_service_pb.GetAdvancePaymentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.GetAdvancePaymentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/GetAdvancePayments',
        request,
        metadata || {},
        this.methodInfoGetAdvancePayments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/GetAdvancePayments',
    request,
    metadata || {},
    this.methodInfoGetAdvancePayments);
  }

  methodInfoAddAdvancePayment = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/AddAdvancePayment',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.AddAdvancePaymentRequest,
    services_central_tariff_service_pb.AddAdvancePaymentResponse,
    (request: services_central_tariff_service_pb.AddAdvancePaymentRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.AddAdvancePaymentResponse.deserializeBinary
  );

  addAdvancePayment(
    request: services_central_tariff_service_pb.AddAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.AddAdvancePaymentResponse>;

  addAdvancePayment(
    request: services_central_tariff_service_pb.AddAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddAdvancePaymentResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.AddAdvancePaymentResponse>;

  addAdvancePayment(
    request: services_central_tariff_service_pb.AddAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.AddAdvancePaymentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/AddAdvancePayment',
        request,
        metadata || {},
        this.methodInfoAddAdvancePayment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/AddAdvancePayment',
    request,
    metadata || {},
    this.methodInfoAddAdvancePayment);
  }

  methodInfoDeleteAdvancePayment = new grpcWeb.MethodDescriptor(
    '/services.central.TariffService/DeleteAdvancePayment',
    grpcWeb.MethodType.UNARY,
    services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    services_central_tariff_service_pb.DeleteAdvancePaymentResponse,
    (request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest) => {
      return request.serializeBinary();
    },
    services_central_tariff_service_pb.DeleteAdvancePaymentResponse.deserializeBinary
  );

  deleteAdvancePayment(
    request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_tariff_service_pb.DeleteAdvancePaymentResponse>;

  deleteAdvancePayment(
    request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteAdvancePaymentResponse) => void): grpcWeb.ClientReadableStream<services_central_tariff_service_pb.DeleteAdvancePaymentResponse>;

  deleteAdvancePayment(
    request: services_central_tariff_service_pb.DeleteAdvancePaymentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_tariff_service_pb.DeleteAdvancePaymentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.TariffService/DeleteAdvancePayment',
        request,
        metadata || {},
        this.methodInfoDeleteAdvancePayment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.TariffService/DeleteAdvancePayment',
    request,
    metadata || {},
    this.methodInfoDeleteAdvancePayment);
  }

}

