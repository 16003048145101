
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { dateTimeToDate, dateToDateTime } from "@/utils/proto-utils";
import { tariffServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GasTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { AddTariffRequest, AdvancePayment, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import TariffAdvancePayments from "./TariffAdvancePayments.vue";
import tariff from "@/store/modules/tariff";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {
    TariffAdvancePayments,
  },
})
export default class AddUpdateGasTariff extends Vue {
  call: RemoteCall<UserError> = initialized;
  addAdvancePaymentCall: RemoteCall<UserError> = initialized;

  @Prop({ default: null }) gasTariff: GasTariff | null;
  @Prop() value: boolean;
  @Prop({ default: "" }) spaceId: string;

  medium: Device.Medium = Device.Medium.GAS;
  supplier: string = "";
  pricePerKwh: string = "";
  basePrice: string = "";
  co2PerKWh: string = "201";
  energyValue: string = "";
  correctionFactor: string = "";
  startDate: Date | null = null;
  isGreenEnergy: boolean = false;
  newAdvancePayment: AdvancePayment | null = null;
  advancePaymentsKey: number = 0;

  get infoComplete(): boolean {
    return !!this.supplier && !!this.basePrice && !!this.pricePerKwh;
  }

  pricePerKwhRule(input: string): string | boolean {
    const price = Number(input);
    if (price < 1 && price > 0) {
      return "Preis in Cent/kWh nicht €/kWh";
    } else {
      return true;
    }
  }

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }

  mounted(): void {
    this.fetchForm();
  }

  async addUpdateGasTariff(): Promise<void> {
    const gasTariff = new GasTariff();

    gasTariff.setSupplierName(this.supplier);
    gasTariff.setStartDate((this.startDate ?? new Date()).toDateString());
    gasTariff.setBasePrice(parseFloat(this.basePrice));
    gasTariff.setIsGreen(this.isGreenEnergy);
    gasTariff.setPricePerKwh(parseFloat(this.pricePerKwh));
    gasTariff.setCorrectionFactor(parseFloat(this.correctionFactor));
    gasTariff.setEnergyValue(parseFloat(this.energyValue));

    const gCo2PerKwh = parseFloat(this.co2PerKWh);
    if (!isNaN(gCo2PerKwh)) {
      gasTariff.setKgCo2PerKwh(gCo2PerKwh / 1000);
    }

    try {
      this.call = pending;

      if (this.gasTariff) {
        gasTariff.setId(this.gasTariff.getId());
        const request = new UpdateTariffRequest();
        request.setGasTariff(gasTariff);
        request.setSpaceId(this.spaceId);
        if (this.newAdvancePayment) {
          request.setNewAdvancePayment(this.newAdvancePayment);
          this.newAdvancePayment = null;
        }
        const response = await tariffServiceClient.updateTariff(request, {});
        let gasTariffs = response.getGasTariffsResponse()?.getGasTariffsList() as GasTariff[];
        tariff.setGasTariffs(success(gasTariffs));
      } else {
        const request = new AddTariffRequest();
        request.setSpaceId(this.spaceId);
        request.setGasTariff(gasTariff);
        if (this.newAdvancePayment) {
          request.setNewAdvancePayment(this.newAdvancePayment);
          this.newAdvancePayment = null;
        }
        const response = await tariffServiceClient.addTariff(request, {});
        let gasTariffs = response.getGasTariffsResponse()?.getGasTariffsList() as GasTariff[];
        tariff.setGasTariffs(success(gasTariffs));
        this.resetForm();
      }

      this.call = success(void 0);
      this.$emit("tariff-updated");
      this.$emit("input", false);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }

  resetForm() {
    this.supplier = "";
    this.pricePerKwh = "";
    this.basePrice = "";
    this.startDate = null;
    this.isGreenEnergy = false;
    this.energyValue = "";
    this.correctionFactor = "";
    this.co2PerKWh = "201";
  }

  onCancel() {
    this.fetchForm();
    this.$emit("input", false);
  }

  onChangedAdvancePaymentDetails(newAdvancePayment: AdvancePayment | null) {
    this.newAdvancePayment = newAdvancePayment;
  }

  fetchForm() {
    if (this.gasTariff) {
      this.supplier = this.gasTariff.getSupplierName();
      this.basePrice = this.gasTariff.getBasePrice().toFixed(2);
      this.isGreenEnergy = this.gasTariff.getIsGreen();
      this.pricePerKwh = this.gasTariff.getPricePerKwh().toFixed(2) ?? "";
      this.energyValue = this.gasTariff.getEnergyValue().toFixed(3) ?? "";
      this.correctionFactor = this.gasTariff.getCorrectionFactor().toFixed(3) ?? "";

      if (this.gasTariff.getKgCo2PerKwh()) {
        this.co2PerKWh = (this.gasTariff.getKgCo2PerKwh() * 1000).toFixed(0);
      }

      if (this.gasTariff.getStartDate()) {
        this.startDate = new Date(this.gasTariff.getStartDate());
      }
    }
  }
}
