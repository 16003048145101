/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_advisory_service_pb from '../../services/central/advisory_service_pb';


export class AdvisoryServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoRunMiniPVSimulation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/RunMiniPVSimulation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.RunMiniPVSimulationRequest,
    services_central_advisory_service_pb.RunMiniPVSimulationResponse,
    (request: services_central_advisory_service_pb.RunMiniPVSimulationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.RunMiniPVSimulationResponse.deserializeBinary
  );

  runMiniPVSimulation(
    request: services_central_advisory_service_pb.RunMiniPVSimulationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.RunMiniPVSimulationResponse>;

  runMiniPVSimulation(
    request: services_central_advisory_service_pb.RunMiniPVSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.RunMiniPVSimulationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.RunMiniPVSimulationResponse>;

  runMiniPVSimulation(
    request: services_central_advisory_service_pb.RunMiniPVSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.RunMiniPVSimulationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/RunMiniPVSimulation',
        request,
        metadata || {},
        this.methodInfoRunMiniPVSimulation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/RunMiniPVSimulation',
    request,
    metadata || {},
    this.methodInfoRunMiniPVSimulation);
  }

  methodInfoGetSimulations = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetSimulations',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetSimulationsRequest,
    services_central_advisory_service_pb.GetSimulationsResponse,
    (request: services_central_advisory_service_pb.GetSimulationsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetSimulationsResponse.deserializeBinary
  );

  getSimulations(
    request: services_central_advisory_service_pb.GetSimulationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetSimulationsResponse>;

  getSimulations(
    request: services_central_advisory_service_pb.GetSimulationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetSimulationsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetSimulationsResponse>;

  getSimulations(
    request: services_central_advisory_service_pb.GetSimulationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetSimulationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetSimulations',
        request,
        metadata || {},
        this.methodInfoGetSimulations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetSimulations',
    request,
    metadata || {},
    this.methodInfoGetSimulations);
  }

  methodInfoLoadMiniPVSimulation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/LoadMiniPVSimulation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.LoadMiniPVSimulationRequest,
    services_central_advisory_service_pb.LoadMiniPVSimulationResponse,
    (request: services_central_advisory_service_pb.LoadMiniPVSimulationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.LoadMiniPVSimulationResponse.deserializeBinary
  );

  loadMiniPVSimulation(
    request: services_central_advisory_service_pb.LoadMiniPVSimulationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.LoadMiniPVSimulationResponse>;

  loadMiniPVSimulation(
    request: services_central_advisory_service_pb.LoadMiniPVSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.LoadMiniPVSimulationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.LoadMiniPVSimulationResponse>;

  loadMiniPVSimulation(
    request: services_central_advisory_service_pb.LoadMiniPVSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.LoadMiniPVSimulationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/LoadMiniPVSimulation',
        request,
        metadata || {},
        this.methodInfoLoadMiniPVSimulation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/LoadMiniPVSimulation',
    request,
    metadata || {},
    this.methodInfoLoadMiniPVSimulation);
  }

  methodInfoGetTips = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetTips',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetTipsRequest,
    services_central_advisory_service_pb.GetTipsResponse,
    (request: services_central_advisory_service_pb.GetTipsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetTipsResponse.deserializeBinary
  );

  getTips(
    request: services_central_advisory_service_pb.GetTipsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetTipsResponse>;

  getTips(
    request: services_central_advisory_service_pb.GetTipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetTipsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetTipsResponse>;

  getTips(
    request: services_central_advisory_service_pb.GetTipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetTipsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetTips',
        request,
        metadata || {},
        this.methodInfoGetTips,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetTips',
    request,
    metadata || {},
    this.methodInfoGetTips);
  }

  methodInfoTrackTipInteraction = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/TrackTipInteraction',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.TrackTipInteractionRequest,
    services_central_advisory_service_pb.TrackTipInteractionResponse,
    (request: services_central_advisory_service_pb.TrackTipInteractionRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.TrackTipInteractionResponse.deserializeBinary
  );

  trackTipInteraction(
    request: services_central_advisory_service_pb.TrackTipInteractionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.TrackTipInteractionResponse>;

  trackTipInteraction(
    request: services_central_advisory_service_pb.TrackTipInteractionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.TrackTipInteractionResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.TrackTipInteractionResponse>;

  trackTipInteraction(
    request: services_central_advisory_service_pb.TrackTipInteractionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.TrackTipInteractionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/TrackTipInteraction',
        request,
        metadata || {},
        this.methodInfoTrackTipInteraction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/TrackTipInteraction',
    request,
    metadata || {},
    this.methodInfoTrackTipInteraction);
  }

  methodInfoGetEnergyConsumers = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetEnergyConsumers',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetEnergyConsumersRequest,
    services_central_advisory_service_pb.GetEnergyConsumersResponse,
    (request: services_central_advisory_service_pb.GetEnergyConsumersRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetEnergyConsumersResponse.deserializeBinary
  );

  getEnergyConsumers(
    request: services_central_advisory_service_pb.GetEnergyConsumersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetEnergyConsumersResponse>;

  getEnergyConsumers(
    request: services_central_advisory_service_pb.GetEnergyConsumersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetEnergyConsumersResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetEnergyConsumersResponse>;

  getEnergyConsumers(
    request: services_central_advisory_service_pb.GetEnergyConsumersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetEnergyConsumersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetEnergyConsumers',
        request,
        metadata || {},
        this.methodInfoGetEnergyConsumers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetEnergyConsumers',
    request,
    metadata || {},
    this.methodInfoGetEnergyConsumers);
  }

  methodInfoSetEnergyConsumers = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/SetEnergyConsumers',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.SetEnergyConsumersRequest,
    services_central_advisory_service_pb.SetEnergyConsumersResponse,
    (request: services_central_advisory_service_pb.SetEnergyConsumersRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.SetEnergyConsumersResponse.deserializeBinary
  );

  setEnergyConsumers(
    request: services_central_advisory_service_pb.SetEnergyConsumersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.SetEnergyConsumersResponse>;

  setEnergyConsumers(
    request: services_central_advisory_service_pb.SetEnergyConsumersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.SetEnergyConsumersResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.SetEnergyConsumersResponse>;

  setEnergyConsumers(
    request: services_central_advisory_service_pb.SetEnergyConsumersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.SetEnergyConsumersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/SetEnergyConsumers',
        request,
        metadata || {},
        this.methodInfoSetEnergyConsumers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/SetEnergyConsumers',
    request,
    metadata || {},
    this.methodInfoSetEnergyConsumers);
  }

  methodInfoDeleteSimulation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/DeleteSimulation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.DeleteSimulationRequest,
    services_central_advisory_service_pb.DeleteSimulationResponse,
    (request: services_central_advisory_service_pb.DeleteSimulationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.DeleteSimulationResponse.deserializeBinary
  );

  deleteSimulation(
    request: services_central_advisory_service_pb.DeleteSimulationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.DeleteSimulationResponse>;

  deleteSimulation(
    request: services_central_advisory_service_pb.DeleteSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.DeleteSimulationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.DeleteSimulationResponse>;

  deleteSimulation(
    request: services_central_advisory_service_pb.DeleteSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.DeleteSimulationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/DeleteSimulation',
        request,
        metadata || {},
        this.methodInfoDeleteSimulation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/DeleteSimulation',
    request,
    metadata || {},
    this.methodInfoDeleteSimulation);
  }

  methodInfoAddAutomation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/AddAutomation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.AddAutomationRequest,
    services_central_advisory_service_pb.AddAutomationResponse,
    (request: services_central_advisory_service_pb.AddAutomationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.AddAutomationResponse.deserializeBinary
  );

  addAutomation(
    request: services_central_advisory_service_pb.AddAutomationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.AddAutomationResponse>;

  addAutomation(
    request: services_central_advisory_service_pb.AddAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.AddAutomationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.AddAutomationResponse>;

  addAutomation(
    request: services_central_advisory_service_pb.AddAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.AddAutomationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/AddAutomation',
        request,
        metadata || {},
        this.methodInfoAddAutomation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/AddAutomation',
    request,
    metadata || {},
    this.methodInfoAddAutomation);
  }

  methodInfoDeleteAutomation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/DeleteAutomation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.DeleteAutomationRequest,
    services_central_advisory_service_pb.DeleteAutomationResponse,
    (request: services_central_advisory_service_pb.DeleteAutomationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.DeleteAutomationResponse.deserializeBinary
  );

  deleteAutomation(
    request: services_central_advisory_service_pb.DeleteAutomationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.DeleteAutomationResponse>;

  deleteAutomation(
    request: services_central_advisory_service_pb.DeleteAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.DeleteAutomationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.DeleteAutomationResponse>;

  deleteAutomation(
    request: services_central_advisory_service_pb.DeleteAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.DeleteAutomationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/DeleteAutomation',
        request,
        metadata || {},
        this.methodInfoDeleteAutomation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/DeleteAutomation',
    request,
    metadata || {},
    this.methodInfoDeleteAutomation);
  }

  methodInfoGetAutomations = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetAutomations',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetAutomationsRequest,
    services_central_advisory_service_pb.GetAutomationsResponse,
    (request: services_central_advisory_service_pb.GetAutomationsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetAutomationsResponse.deserializeBinary
  );

  getAutomations(
    request: services_central_advisory_service_pb.GetAutomationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetAutomationsResponse>;

  getAutomations(
    request: services_central_advisory_service_pb.GetAutomationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetAutomationsResponse>;

  getAutomations(
    request: services_central_advisory_service_pb.GetAutomationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetAutomations',
        request,
        metadata || {},
        this.methodInfoGetAutomations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetAutomations',
    request,
    metadata || {},
    this.methodInfoGetAutomations);
  }

  methodInfoGetDispatchedActions = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetDispatchedActions',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetDispatchedActionsRequest,
    services_central_advisory_service_pb.GetDispatchedActionsResponse,
    (request: services_central_advisory_service_pb.GetDispatchedActionsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetDispatchedActionsResponse.deserializeBinary
  );

  getDispatchedActions(
    request: services_central_advisory_service_pb.GetDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetDispatchedActionsResponse>;

  getDispatchedActions(
    request: services_central_advisory_service_pb.GetDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetDispatchedActionsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetDispatchedActionsResponse>;

  getDispatchedActions(
    request: services_central_advisory_service_pb.GetDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetDispatchedActionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetDispatchedActions',
        request,
        metadata || {},
        this.methodInfoGetDispatchedActions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetDispatchedActions',
    request,
    metadata || {},
    this.methodInfoGetDispatchedActions);
  }

  methodInfoRetryDispatchedAction = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/RetryDispatchedAction',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.RetryDispatchedActionRequest,
    services_central_advisory_service_pb.RetryDispatchedActionResponse,
    (request: services_central_advisory_service_pb.RetryDispatchedActionRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.RetryDispatchedActionResponse.deserializeBinary
  );

  retryDispatchedAction(
    request: services_central_advisory_service_pb.RetryDispatchedActionRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.RetryDispatchedActionResponse>;

  retryDispatchedAction(
    request: services_central_advisory_service_pb.RetryDispatchedActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.RetryDispatchedActionResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.RetryDispatchedActionResponse>;

  retryDispatchedAction(
    request: services_central_advisory_service_pb.RetryDispatchedActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.RetryDispatchedActionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/RetryDispatchedAction',
        request,
        metadata || {},
        this.methodInfoRetryDispatchedAction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/RetryDispatchedAction',
    request,
    metadata || {},
    this.methodInfoRetryDispatchedAction);
  }

  methodInfoGetAutomationDetail = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetAutomationDetail',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetAutomationDetailRequest,
    services_central_advisory_service_pb.GetAutomationDetailResponse,
    (request: services_central_advisory_service_pb.GetAutomationDetailRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetAutomationDetailResponse.deserializeBinary
  );

  getAutomationDetail(
    request: services_central_advisory_service_pb.GetAutomationDetailRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetAutomationDetailResponse>;

  getAutomationDetail(
    request: services_central_advisory_service_pb.GetAutomationDetailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationDetailResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetAutomationDetailResponse>;

  getAutomationDetail(
    request: services_central_advisory_service_pb.GetAutomationDetailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationDetailResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetAutomationDetail',
        request,
        metadata || {},
        this.methodInfoGetAutomationDetail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetAutomationDetail',
    request,
    metadata || {},
    this.methodInfoGetAutomationDetail);
  }

  methodInfoGetAutomationDispatchedActions = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/GetAutomationDispatchedActions',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.GetAutomationDispatchedActionsRequest,
    services_central_advisory_service_pb.GetAutomationDispatchedActionsResponse,
    (request: services_central_advisory_service_pb.GetAutomationDispatchedActionsRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.GetAutomationDispatchedActionsResponse.deserializeBinary
  );

  getAutomationDispatchedActions(
    request: services_central_advisory_service_pb.GetAutomationDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.GetAutomationDispatchedActionsResponse>;

  getAutomationDispatchedActions(
    request: services_central_advisory_service_pb.GetAutomationDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationDispatchedActionsResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.GetAutomationDispatchedActionsResponse>;

  getAutomationDispatchedActions(
    request: services_central_advisory_service_pb.GetAutomationDispatchedActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.GetAutomationDispatchedActionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/GetAutomationDispatchedActions',
        request,
        metadata || {},
        this.methodInfoGetAutomationDispatchedActions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/GetAutomationDispatchedActions',
    request,
    metadata || {},
    this.methodInfoGetAutomationDispatchedActions);
  }

  methodInfoUpdateAutomation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/UpdateAutomation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.UpdateAutomationRequest,
    services_central_advisory_service_pb.UpdateAutomationResponse,
    (request: services_central_advisory_service_pb.UpdateAutomationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.UpdateAutomationResponse.deserializeBinary
  );

  updateAutomation(
    request: services_central_advisory_service_pb.UpdateAutomationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.UpdateAutomationResponse>;

  updateAutomation(
    request: services_central_advisory_service_pb.UpdateAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.UpdateAutomationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.UpdateAutomationResponse>;

  updateAutomation(
    request: services_central_advisory_service_pb.UpdateAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.UpdateAutomationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/UpdateAutomation',
        request,
        metadata || {},
        this.methodInfoUpdateAutomation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/UpdateAutomation',
    request,
    metadata || {},
    this.methodInfoUpdateAutomation);
  }

  methodInfoTriggerAutomation = new grpcWeb.MethodDescriptor(
    '/services.central.AdvisoryService/TriggerAutomation',
    grpcWeb.MethodType.UNARY,
    services_central_advisory_service_pb.TriggerAutomationRequest,
    services_central_advisory_service_pb.TriggerAutomationResponse,
    (request: services_central_advisory_service_pb.TriggerAutomationRequest) => {
      return request.serializeBinary();
    },
    services_central_advisory_service_pb.TriggerAutomationResponse.deserializeBinary
  );

  triggerAutomation(
    request: services_central_advisory_service_pb.TriggerAutomationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_advisory_service_pb.TriggerAutomationResponse>;

  triggerAutomation(
    request: services_central_advisory_service_pb.TriggerAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.TriggerAutomationResponse) => void): grpcWeb.ClientReadableStream<services_central_advisory_service_pb.TriggerAutomationResponse>;

  triggerAutomation(
    request: services_central_advisory_service_pb.TriggerAutomationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_advisory_service_pb.TriggerAutomationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.AdvisoryService/TriggerAutomation',
        request,
        metadata || {},
        this.methodInfoTriggerAutomation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.AdvisoryService/TriggerAutomation',
    request,
    metadata || {},
    this.methodInfoTriggerAutomation);
  }

}

