
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import TariffDetails from "./tariff-details/TariffDetails.vue";
import SavingsCalculationExplanation from "./SavingsCalculationExplanation.vue";
import { tariffModule } from "@/store/modules/tariff";
import { EnergyTariff, SearchResult, TariffSearchInfo, TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";

@Component({
  components: {
    TariffDetails,
    SavingsCalculationExplanation,
  },
})
export default class TariffSearchResultsTable extends Vue {
  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff>;
  @tariffModule.Getter pendingTariffSwitch: RemoteData<UserError, TariffSwitch | null>;
  @tariffModule.Getter tariffSearchResults: RemoteData<UserError, SearchResult[]>;
  @tariffModule.Getter tariffSearchInfo: RemoteData<UserError, TariffSearchInfo>;

  showMore: boolean = false;
  showTariffDetails: boolean = false;
  detailsTariff: SearchResult | null = null;
  showSavingsCalculationExplanation: boolean = false;

  get visibleSearchResults(): SearchResult[] {
    const pendingSwitchTariffSearchResult = this.pendingTariffSwitch.data?.getSearchResult();
    if (pendingSwitchTariffSearchResult) {
      return [pendingSwitchTariffSearchResult];
    } else {
      return this.tariffSearchResults.list.slice(0, this.showMore ? this.tariffSearchResults.list.length : 1);
    }
  }

  get singleSwitchEnabled(): boolean {
    return (
      this.pendingTariffSwitch.succeeded &&
      !this.pendingTariffSwitch.data &&
      this.tariffSearchInfo.succeeded &&
      !this.tariffSearchInfo.data?.getIsWatchdogEnabled()
    );
  }

  get isFlexibleTariff(): boolean {
    return this.currentEnergyTariff.data?.getIsVariable() || false;
  }

  onOfferClicked(tariff: SearchResult): void {
    window.open(tariff.getSupplier()?.getLink(), "_blank");
  }

  onTariffDetailsClicked(tariff: SearchResult): void {
    this.showTariffDetails = true;
    this.detailsTariff = tariff;
  }

  onSavingsCalculationExplanationButtonClicked(): void {
    this.showSavingsCalculationExplanation = true;
  }
}
