
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { subscriptionModule } from "@/store/modules/subscription";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import AccountDropdown from "./AccountDropdown.vue";

@Component({
  components: {
    AccountDropdown,
  },
})
export default class PlatformTopBar extends Vue {
  @Prop({ default: false }) showSideMenuButton: boolean;
  @Prop({ default: "" }) title: string;

  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @subscriptionModule.Getter currentPlan: SubscriptionPlan;

  SubscriptionPlan = SubscriptionPlan;

  paths = paths;

  showSideMenuClicked(): void {
    this.$emit("show-side-menu");
  }

  get planName(): string {
    if (this.currentPlan === SubscriptionPlan.B2B) return "Business Version";
    else if (this.currentPlan === SubscriptionPlan.PRO) return "Pro Version";
    else return "Free Version";
  }
}
