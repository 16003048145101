
import { TableEntry } from "@/components/core/Table.vue";
import { SpaceItem } from "@/components/create-space/CreateSpaceDialog.vue";
import { spaceServiceClient } from "@/config/service-clients";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { getSpaceAddress } from "@/utils/space-utils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UpdateSpaceRequest } from "zaehlerfreunde-central/space_service_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";

@Component
export default class ChangeChildSpaces extends Vue {
  @Prop() value: boolean;
  @Prop() selectedSpace: Space | null;
  @Prop() childSpaces: Space[];

  @spacesModule.Getter spacesWithoutParentSpace: Space[];

  updateChildSpacesCall: RemoteCall<UserError> = initialized;

  headers = [
    { value: "selected", sortable: false },
    { text: "Name", value: "name" },
    { text: "Address", value: "address", sortable: false },
  ];

  selectedChildSpaceItems: SpaceItem[] = [];
  isReview: boolean = false;

  @Watch("value")
  showExistingChildSpaces(): void {
    if (this.selectedSpace?.getId()) {
      this.selectedChildSpaceItems = this.childSpaces.map((s) => this.toSpaceItem(s));
    }
  }

  get selectedChildSpaces(): TableEntry[][] | undefined {
    return this.selectedChildSpaceItems?.map((spaceItem) => [
      { value: spaceItem.name, bold: true },
      { value: spaceItem.address },
    ]);
  }

  get title(): string {
    return "Untergeordnetes Objekt von " + this.selectedSpace?.getName();
  }

  get updateChildSpacesItems(): SpaceItem[] {
    if (this.selectedSpace?.getId()) {
      const availableSpaces = [
        ...this.childSpaces,
        ...this.spacesWithoutParentSpace.filter(
          (s) => s.getId() !== this.selectedSpace?.getId() && s.getId() !== this.selectedSpace?.getParentSpaceId()
        ),
      ];

      return availableSpaces?.map((space: Space) => this.toSpaceItem(space));
    }
    return [];
  }

  onChildSpaceSelected(childSpaceItem: SpaceItem, selected: boolean) {
    if (selected) {
      this.selectedChildSpaceItems.push(childSpaceItem);
    } else {
      this.selectedChildSpaceItems = this.selectedChildSpaceItems.filter((item) => item.value !== childSpaceItem.value);
    }
  }

  toSpaceItem(space: Space): SpaceItem {
    return {
      selected: this.selectedChildSpaceItems.some((item) => item.value === space.getId()),
      name: space.getName(),
      address: getSpaceAddress(space),
      categoryIcon: space.getCategoryIcon(),
      value: space.getId(),
    };
  }

  onClose(): void {
    this.$emit("input", false);
    this.isReview = false;
  }

  async onSaveClicked(): Promise<void> {
    this.updateChildSpacesCall = pending;

    if (this.selectedSpace?.getId()) {
      const request = new UpdateSpaceRequest();
      request.setSpaceId(this.selectedSpace?.getId());

      const childSpaces = new UpdateSpaceRequest.ChildSpaces();
      childSpaces.setChildSpaceIdsList(this.selectedChildSpaceItems.map((s) => s.value));
      request.setChildSpaces(childSpaces);

      try {
        await spaceServiceClient.updateSpace(request, {});
        this.updateChildSpacesCall = success(void 0);
        spaces.getSpaces();
        this.onClose();
      } catch (error) {
        this.updateChildSpacesCall = failure(userErrorFrom(error));
      }
    }
  }
}
