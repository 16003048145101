
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ZaehlerfreundeLogo from "./ZaehlerfreundeLogo.vue";
import SpaceSelect from "../SpaceSelect.vue";
import { spacesModule } from "@/store/modules/spaces";
import { themeModule } from "@/store/modules/theme";
import { PartnerLogo } from "zaehlerfreunde-proto-types/partners_pb";
import { paths } from "@/router/routes";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import admin, { adminModule } from "@/store/modules/admin";

import PartnerSelect from "../../pages/platform/Partner/PartnerSelect.vue";
import ChildPartnerMenu from "@/pages/platform/Partner/ChildPartnerMenu.vue";

interface MenuItemsGroup {
  name: string;
  menuItems: MenuItem[];
}

interface MenuItem {
  logo: string;
  display: string;
  path: string;
  requiresSpace: boolean;
}

@Component({
  components: {
    ZaehlerfreundeLogo,
    SpaceSelect,
    PartnerSelect,
    ChildPartnerMenu,
  },
})
export default class AdminSideMenu extends Vue {
  @Prop({ default: "Dashboard" }) selectedMenuItem: string;
  @Prop({ default: false }) sideMenuTemporary: boolean;

  @themeModule.Getter partnerLogo: PartnerLogo | null;
  @spacesModule.Getter spaceSelected: boolean;
  @adminModule.State isSuperAdminUser: RemoteData<UserError, boolean>;

  showMenu: boolean = false;

  paths = paths;

  @Watch("sideMenuTemporary")
  onSideMenuTemporaryChanged(): void {
    this.showMenu = false;
  }

  get isSuperAdmin(): boolean {
    return this.isSuperAdminUser.data ?? false;
  }

  menuItemGroups: MenuItemsGroup[] = [
    {
      name: "Allgemein",
      menuItems: [
        { logo: "mdi-account", display: "Nutzer", path: paths.admin.users, requiresSpace: false },
        { logo: "mdi-home", display: "Objekte", path: paths.admin.spaces, requiresSpace: false },
        { logo: "mdi-connection", display: "Geräte", path: paths.admin.devices, requiresSpace: false },
        {
          logo: "mdi-account-multiple-plus",
          display: "Einladungen",
          path: paths.admin.invitesPath,
          requiresSpace: false,
        },
      ],
    },
    {
      name: "Verwaltung",
      menuItems: [
        {
          logo: "mdi-account-key",
          display: "Admins",
          path: paths.admin.admins,
          requiresSpace: false,
        },
        {
          logo: "mdi-database-import",
          display: "Datenimport",
          path: paths.admin.import,
          requiresSpace: false,
        },
        {
          logo: "mdi-database-export",
          display: "Datenexport",
          path: paths.admin.export,
          requiresSpace: false,
        },
        {
          logo: "mdi-palette",
          display: "Darstellung",
          path: paths.admin.appearance,
          requiresSpace: false,
        },
        {
          logo: "mdi-cog",
          display: "Einstellungen",
          path: paths.admin.settings,
          requiresSpace: false,
        },
        {
          logo: "mdi-share",
          display: "Datenweiterleitung",
          path: paths.admin.forwarding,
          requiresSpace: false,
        },
        {
          logo: "mdi-solar-power-variant",
          display: "Mieterstrom",
          path: paths.admin.mieterstrom,
          requiresSpace: false,
        },
        {
          logo: "mdi-api",
          display: "API",
          path: paths.admin.api,
          requiresSpace: false,
        },
      ],
    },
  ];

  show(): void {
    this.showMenu = true;
  }

  beforeMount(): void {
    admin.checkSuperAdminStatus();
  }

  onMenuItemClicked(menuItem: MenuItem): void {
    if (this.selectedMenuItem === menuItem.display) {
      this.showMenu = false;
    } else {
      this.$router.push(menuItem.path);
    }
  }
}
