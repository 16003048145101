// source: types/features.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.Feature', null, global);
/**
 * @enum {number}
 */
proto.types.Feature = {
  AUTOMATIONS: 0,
  SIMULATOR: 1,
  TARIFF_SWITCH: 2,
  TIPS: 3,
  SHOP: 4,
  ENERGY_TARIFF: 5,
  WATER_TARIFF: 6,
  GAS_TARIFF: 7,
  LATEST_STATUS: 8,
  CURRENT_MONTH_OVERVIEW: 9,
  SPACE_COMPARISON: 10,
  INVOICES: 11,
  DEVICES_CONFIG: 12,
  SPACE_CONFIG: 13,
  MARKET_PREMIUM: 14,
  VIRTUAL_SUM_METER: 15,
  SPACE_KPIS: 16,
  CHILD_PARTNER_CREATION: 17
};

goog.object.extend(exports, proto.types);
