// source: types/space.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.Address', null, global);
goog.exportSymbol('proto.types.Country', null, global);
goog.exportSymbol('proto.types.Space', null, global);
goog.exportSymbol('proto.types.Space.Category', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Space = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Space.repeatedFields_, null);
};
goog.inherits(proto.types.Space, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Space.displayName = 'proto.types.Space';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Address.displayName = 'proto.types.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Country.displayName = 'proto.types.Country';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Space.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Space.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Space.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Space} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Space.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerUserId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ingestPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    category: jspb.Message.getFieldWithDefault(msg, 9, 0),
    categoryName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    categoryIcon: jspb.Message.getFieldWithDefault(msg, 11, ""),
    associatedUsersIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    parentSpaceId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    address: (f = msg.getAddress()) && proto.types.Address.toObject(includeInstance, f),
    addressLineOne: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLineTwo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    city: jspb.Message.getFieldWithDefault(msg, 6, ""),
    country: (f = msg.getCountry()) && proto.types.Country.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Space}
 */
proto.types.Space.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Space;
  return proto.types.Space.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Space} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Space}
 */
proto.types.Space.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerUserId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIngestPrice(value);
      break;
    case 9:
      var value = /** @type {!proto.types.Space.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryIcon(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssociatedUsersIds(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentSpaceId(value);
      break;
    case 15:
      var value = new proto.types.Address;
      reader.readMessage(value,proto.types.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineOne(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineTwo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 13:
      var value = new proto.types.Country;
      reader.readMessage(value,proto.types.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Space.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Space.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Space} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Space.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIngestPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCategoryIcon();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAssociatedUsersIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.types.Address.serializeBinaryToWriter
    );
  }
  f = message.getAddressLineOne();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.types.Country.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.types.Space.Category = {
  HOTEL: 0,
  RESTAURANT: 1,
  HOUSEHOLD: 2,
  OFFICE: 3,
  PV_SYSTEM: 4,
  COMMERCIAL: 5,
  BIOGAS: 6,
  RETAIL: 7,
  SINGLE_FAMILY_HOME: 8,
  PRODUCTION: 9,
  FLAT: 10,
  WIND_ENERGY: 11,
  DOCTORS_OFFICE: 12,
  GROUPING: 13,
  APARTMENT_BUILDING: 14,
  REGION: 15,
  ROOM: 16
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Space.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.Space.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_user_id = 7;
 * @return {string}
 */
proto.types.Space.prototype.getOwnerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setOwnerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double ingest_price = 8;
 * @return {number}
 */
proto.types.Space.prototype.getIngestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setIngestPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional Category category = 9;
 * @return {!proto.types.Space.Category}
 */
proto.types.Space.prototype.getCategory = function() {
  return /** @type {!proto.types.Space.Category} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.types.Space.Category} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string category_name = 10;
 * @return {string}
 */
proto.types.Space.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string category_icon = 11;
 * @return {string}
 */
proto.types.Space.prototype.getCategoryIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setCategoryIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string associated_users_ids = 12;
 * @return {!Array<string>}
 */
proto.types.Space.prototype.getAssociatedUsersIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setAssociatedUsersIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.addAssociatedUsersIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.clearAssociatedUsersIdsList = function() {
  return this.setAssociatedUsersIdsList([]);
};


/**
 * optional string parent_space_id = 14;
 * @return {string}
 */
proto.types.Space.prototype.getParentSpaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setParentSpaceId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.clearParentSpaceId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Space.prototype.hasParentSpaceId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Address address = 15;
 * @return {?proto.types.Address}
 */
proto.types.Space.prototype.getAddress = function() {
  return /** @type{?proto.types.Address} */ (
    jspb.Message.getWrapperField(this, proto.types.Address, 15));
};


/**
 * @param {?proto.types.Address|undefined} value
 * @return {!proto.types.Space} returns this
*/
proto.types.Space.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Space.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string address_line_one = 3;
 * @return {string}
 */
proto.types.Space.prototype.getAddressLineOne = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setAddressLineOne = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_line_two = 4;
 * @return {string}
 */
proto.types.Space.prototype.getAddressLineTwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setAddressLineTwo = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.clearAddressLineTwo = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Space.prototype.hasAddressLineTwo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string postcode = 5;
 * @return {string}
 */
proto.types.Space.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city = 6;
 * @return {string}
 */
proto.types.Space.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Country country = 13;
 * @return {?proto.types.Country}
 */
proto.types.Space.prototype.getCountry = function() {
  return /** @type{?proto.types.Country} */ (
    jspb.Message.getWrapperField(this, proto.types.Country, 13));
};


/**
 * @param {?proto.types.Country|undefined} value
 * @return {!proto.types.Space} returns this
*/
proto.types.Space.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Space} returns this
 */
proto.types.Space.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Space.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressLineOne: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLineTwo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: (f = msg.getCountry()) && proto.types.Country.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Address}
 */
proto.types.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Address;
  return proto.types.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Address}
 */
proto.types.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineOne(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineTwo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = new proto.types.Country;
      reader.readMessage(value,proto.types.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressLineOne();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.types.Country.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Address.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_line_one = 2;
 * @return {string}
 */
proto.types.Address.prototype.getAddressLineOne = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.setAddressLineOne = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line_two = 3;
 * @return {string}
 */
proto.types.Address.prototype.getAddressLineTwo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.setAddressLineTwo = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.clearAddressLineTwo = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Address.prototype.hasAddressLineTwo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string postcode = 4;
 * @return {string}
 */
proto.types.Address.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.types.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Country country = 6;
 * @return {?proto.types.Country}
 */
proto.types.Address.prototype.getCountry = function() {
  return /** @type{?proto.types.Country} */ (
    jspb.Message.getWrapperField(this, proto.types.Country, 6));
};


/**
 * @param {?proto.types.Country|undefined} value
 * @return {!proto.types.Address} returns this
*/
proto.types.Address.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Address} returns this
 */
proto.types.Address.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Address.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Country}
 */
proto.types.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Country;
  return proto.types.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Country}
 */
proto.types.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.Country.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Country} returns this
 */
proto.types.Country.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_name = 2;
 * @return {string}
 */
proto.types.Country.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Country} returns this
 */
proto.types.Country.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.types);
