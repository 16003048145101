
import { readingsServiceClient } from "@/config/service-clients";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { dateToDateTime } from "@/utils/proto-utils";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Measurement } from "zaehlerfreunde-proto-types/device_reading_pb";
import { TimeInterval } from "zaehlerfreunde-proto-types/time_data_pb";
import {
  DeviceReadingsFilter,
  ExportReadingsRequest,
  FileFormat,
  PartnerDevices,
} from "zaehlerfreunde-central/reading_service_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component
export default class ExportDataDilaog extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  @Prop() value: boolean;

  date: Date | null = null;
  today: Date = new Date();
  call: RemoteCall<UserError> = initialized;

  maxAge: number | null = null;

  maxAgeOptions: { text: string; value: number }[] = [
    { text: "1 Tag", value: 1 },
    { text: "2 Wochen", value: 14 },
    { text: "1 Monat", value: 30 },
    { text: "3 Monate", value: 90 },
  ];

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }

  async exportData(): Promise<void> {
    if (this.date && this.maxAge) {
      try {
        const filter = new DeviceReadingsFilter();
        filter.setMeasurement(Measurement.TOTAL);

        const partnerDevices = new PartnerDevices();

        if (this.selectedChildPartner.data) partnerDevices.setPartnerId(this.selectedChildPartner.data.getId());

        const endDate = moment(this.date).endOf("day").toDate();
        const startDate = new Date(this.date.valueOf());
        startDate.setDate(startDate.getDate() - this.maxAge);

        this.call = pending;
        const request = new ExportReadingsRequest();
        request.setByPartner(partnerDevices);
        request.setStartTime(dateToDateTime(startDate));
        request.setEndTime(dateToDateTime(endDate));
        request.setFileFormat(FileFormat.XAP_E3_CSV);
        request.setFilter(filter);
        request.setTimeInterval(TimeInterval.DAY);
        request.setIncludeOnlyLatestReadings(true);

        const response = await readingsServiceClient.exportReadings(request, {});
        this.call = success(void 0);

        const blob = new Blob([response.getContent()], { type: "text/csv;charset=utf-8," });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objUrl);
        link.setAttribute("download", response.getFileName());
        link.click();
      } catch (error) {
        this.call = failure(userErrorFrom(error));
      }
    }
  }
}
