
import { Vue, Component, Watch } from 'vue-property-decorator';
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import {
  DownloadInvoiceResponse,
  Invoice
} from "../../../../../../gen/js/services/userservice/contract_pb";
import account, { accountModule } from "@/store/modules/account";
import IoUtils, { FileType } from "@/utils/io-utils";

export interface InvoiceModel {
  id: string
  periodStart: string,
  periodEnd: string,
  issueDate: string,
  dueDate: string,
  total: string,
  hasHtml: boolean
}
@Component({})
export default class InvoicesOverview extends Vue {

  @accountModule.State invoices: RemoteData<UserError, Invoice[]>;
  @accountModule.State downloadInvoiceResponse: RemoteData<UserError, DownloadInvoiceResponse>;
  @accountModule.State invoiceHTML: RemoteData<UserError, string>;

  itemsPerPage = 10;
  page = 1;
  invoiceHTMLContent: string = ""
  selectedInvoice: InvoiceModel
  showInvoiceDialog = false
  totalPages = 0
  pageSize = 10
  totalResult=0

  headers: { text: string; value: string }[] = [
    { text: "Von", value: "periodStart" },
    { text: "Bis", value: "periodEnd" },
    { text: "Fälligkeitsdatum", value: "dueDate" },
    { text: "Ausgabetag", value: "issueDate" },
    { text: "Gesamtbetrag", value: "total" },
    { text: "", value: "download" },
    { text: "", value: "details" },
  ];

  metaInfo(): { title: string } {
    return {
      title: "Rechnungen",
    };
  }

  mounted(): void {
    account.getInvoices().then(() =>
      this.totalPages = this.totalResult/ this.pageSize<1?1:this.totalResult/ this.pageSize)
  }

  onCloseButtonClicked(): void {
    this.showInvoiceDialog = false
  }

  viewDetails(invoice: InvoiceModel): void {

    account.getInvoiceHTML(invoice.id).then(() => {
      this.showInvoiceDialog = true
      this.selectedInvoice = invoice;

      this.invoiceHTMLContent = this.invoiceHTML.data ?? ""

      //remove invoice page footer style
      this.invoiceHTMLContent = this.invoiceHTMLContent.replace(/page-footer/gi, "");
    })
  }

  downloadInvoice(invoice: InvoiceModel): void {
    account.downloadInvoice(invoice.id).then(()=> {
      if (this.downloadInvoiceResponse.succeeded) {
        IoUtils.fetchFile(this.downloadInvoiceResponse.data?.getFileBytes() as Uint8Array, FileType.PDF, `Metergrid_Invoice_${invoice.issueDate}`)
      }
    }
  
    )
  }

  @Watch("page")
  onPageChanged(): void {
    account.getInvoices(this.page)
  }

  get invoicesList(): InvoiceModel[] {

    let result: InvoiceModel[] = []
    this.totalResult=0
    this.invoices.list.forEach(inv => {
      result.push(
        {
          id: inv.getId(),
          periodStart: inv.getPeriodStart(),
          periodEnd: inv.getPeriodEnd(),
          issueDate: inv.getIssueDate(),
          dueDate: inv.getDueDate(),
          total: `${inv.getTotal()} €`,
          hasHtml: inv.getHasHtml()
        })
        this.totalResult++
    })
    return result;
  }

}
