
import { getAuthService } from "@/auth";
import { userServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import account from "@/store/modules/account";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { partnerAuthConfig } from "@/config/auth-config";
import { Vue, Component } from "vue-property-decorator";
import { AcceptInviteRequest, GetInviteEmailRequest } from "zaehlerfreunde-central/user_service_pb";

@Component
export default class AcceptInvite extends Vue {
  acceptInviteCall: RemoteCall<UserError> = initialized;
  getInviteCall: RemoteCall<UserError> = initialized;
  email: string = "";

  async mounted(): Promise<void> {
    const authService = getAuthService();
    const isAuthenticated = await authService.isAuthenticated;
    const inviteId = this.$route.params["inviteId"];
    let partnerId = this.$route.params["partnerId"];

    if (isAuthenticated) {
      this.acceptInviteCall = pending;

      const authConfig = await partnerAuthConfig;

      try {
        if (!partnerId) {
          partnerId = authConfig.getPartnerId();
        }
        await account.getRegistrationStatus(partnerId);
        await userServiceClient.acceptInvite(new AcceptInviteRequest().setInviteId(inviteId), {});
        this.acceptInviteCall = success(void 0);
        this.$router.push(paths.platform.dashboard);
      } catch (error) {
        this.acceptInviteCall = failure(userErrorFrom(error));
      }
    } else {
      this.getInviteCall = pending;

      try {
        const request = new GetInviteEmailRequest();
        request.setInviteId(inviteId);
        const response = await userServiceClient.getInviteEmail(request, {});
        this.email = response.getEmail();

        this.getInviteCall = success(void 0);
      } catch (error) {
        this.getInviteCall = failure(userErrorFrom(error));
      }
      authService.loginWithRedirect({
        appState: {
          targetUrl: this.$route.fullPath,
        },
        authorizationParams: { screen_hint: "signup", login_hint: this.email },
      });
    }
  }
}
