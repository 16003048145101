
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { tariffServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { WaterTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { AddTariffRequest, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import tariff from "@/store/modules/tariff";

@Component
export default class AddUpdateWaterTariff extends Vue {
  call: RemoteCall<UserError> = initialized;

  @Prop({ default: null }) waterTariff: WaterTariff | null;
  @Prop() value: boolean;
  @Prop({ default: "" }) spaceId: string;

  supplier: string = "";
  pricePerM3: string = "";
  basePrice: string = "";
  startDate: Date | null = null;

  get infoComplete(): boolean {
    return !!this.supplier && !!this.basePrice && !!this.pricePerM3;
  }

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }

  mounted(): void {
    this.fetchForm();
  }

  async addUpdateWaterTariff(): Promise<void> {
    const waterTariff = new WaterTariff();

    waterTariff.setSupplierName(this.supplier);
    waterTariff.setStartDate((this.startDate ?? new Date()).toDateString());
    waterTariff.setBasePrice(parseFloat(this.basePrice));
    waterTariff.setPricePerM3(parseFloat(this.pricePerM3));

    try {
      this.call = pending;

      if (this.waterTariff) {
        waterTariff.setId(this.waterTariff.getId());
        const request = new UpdateTariffRequest();
        request.setWaterTariff(waterTariff);
        request.setSpaceId(this.spaceId);
        const response = await tariffServiceClient.updateTariff(request, {});
        let waterTariffs = response.getWaterTariffsResponse()?.getWaterTariffsList() as WaterTariff[];
        tariff.setWaterTariffs(success(waterTariffs));
      } else {
        const request = new AddTariffRequest();
        request.setSpaceId(this.spaceId);
        request.setWaterTariff(waterTariff);
        const response = await tariffServiceClient.addTariff(request, {});
        let waterTariffs = response.getWaterTariffsResponse()?.getWaterTariffsList() as WaterTariff[];
        tariff.setWaterTariffs(success(waterTariffs));
        this.resetForm();
      }

      this.call = success(void 0);
      this.$emit("tariff-updated");
      this.$emit("input", false);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }

  resetForm() {
    this.supplier = "";
    this.pricePerM3 = "";
    this.basePrice = "";
    this.startDate = null;
  }

  onCancel() {
    this.fetchForm();
    this.$emit("input", false);
  }

  fetchForm() {
    if (this.waterTariff) {
      this.supplier = this.waterTariff.getSupplierName();
      this.basePrice = this.waterTariff.getBasePrice().toFixed(2) ?? "";
      this.pricePerM3 = this.waterTariff.getPricePerM3().toFixed(2) ?? "";

      if (this.waterTariff.getStartDate()) {
        this.startDate = new Date(this.waterTariff.getStartDate());
      }
    }
  }
}
