
import { spaceServiceClient } from "@/config/service-clients";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Address, Country, Space } from "zaehlerfreunde-proto-types/space_pb";
import { CreateSpaceRequest, GetAllCountriesRequest, SpaceCategoryItem } from "zaehlerfreunde-central/space_service_pb";
import SelectSpaceCategory from "./SelectSpaceCategory.vue";
import SpaceSelect from "../SpaceSelect.vue";
import { getSpaceAddress } from "@/utils/space-utils";
import { adminModule } from "@/store/modules/admin";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

export interface SpaceItem {
  selected: boolean;
  name: string;
  address: string;
  categoryIcon: string;
  value: string;
}

@Component({
  components: {
    SelectSpaceCategory,
    SpaceSelect,
  },
})
export default class CreateSpaceDialog extends Vue {
  @Prop({ default: true }) addOwnerToSpace: boolean;
  @Prop({ default: false }) isAdminView: boolean;

  @spacesModule.State spaces: RemoteData<UserError, Space[]>;
  @spacesModule.Getter spacesWithoutParentSpace: Space[];
  @adminModule.Getter partnerSpacesWithoutParentSpace: Space[];
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  createSpaceCall: RemoteCall<UserError> = initialized;
  countriesList: RemoteData<UserError, Country[]> = initialized;

  selectedCategory: SpaceCategoryItem | null = null;
  spaceName: string = "";
  selectedParentSpace: Space | null = null;

  step = 1;

  address = {
    addressLineOne: "",
    addressLineTwo: "",
    zipCode: "",
    city: "",
  };
  selectedCountry: Country | null = null;

  showChildSpaces: boolean = false;

  headers = [
    { value: "selected", sortable: false },
    { text: "Name", value: "name" },
    { text: "Address", value: "address", sortable: false },
  ];
  selectedChildSpaceItems: SpaceItem[] = [];

  get addressRequired(): boolean {
    return this.selectedCategory?.getHasAddress() ?? false;
  }

  get isLastStep(): boolean {
    return false;
  }

  @Watch("selectedCategory")
  onSelectedCategoryChanged(): void {
    if (this.selectedCategory?.getHasAddress()) {
      this.step += 1;
    }
  }

  async mounted(): Promise<void> {
    const request = new GetAllCountriesRequest();

    try {
      const response = await spaceServiceClient.getAllCountries(request, {});
      this.countriesList = success(response.getCountryList());
    } catch (error) {
      this.countriesList = failure(userErrorFrom(error));
    }

    const city = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/")[1];
    this.updateSelectedCountry(city);
    if (this.spaces.list) {
      this.showChildSpaces = true;
    }
  }

  get childSpacesItems(): SpaceItem[] {
    let orphanSpaces = this.isAdminView ? this.partnerSpacesWithoutParentSpace : this.spacesWithoutParentSpace;

    return orphanSpaces?.map((space: Space) => ({
      selected: this.selectedChildSpaceItems.some((item) => item.value === space.getId()),
      name: space.getName(),
      address: getSpaceAddress(space),
      categoryIcon: space.getCategoryIcon(),
      value: space.getId(),
    }));
  }

  onChildSpaceSelected(childSpaceItem: SpaceItem, selected: boolean) {
    if (selected) {
      this.selectedChildSpaceItems.push(childSpaceItem);
    } else {
      this.selectedChildSpaceItems = this.selectedChildSpaceItems.filter((item) => item.value !== childSpaceItem.value);
    }
  }

  countryName(country: Country): string {
    return country.getCountryName();
  }

  countryValue(country: Country): string {
    return country.getId();
  }

  updateSelectedCountry(city: string) {
    switch (city) {
      case "Berlin":
        this.selectedCountry = this.countriesList.list.find((country) => country.getId() === "DEU") || null;
        break;
      case "Zurich":
        this.selectedCountry = this.countriesList.list.find((country) => country.getId() === "CHE") || null;
        break;
      case "Vienna":
        this.selectedCountry = this.countriesList.list.find((country) => country.getId() === "AUT") || null;
        break;
    }
  }

  parentSpaceName(space: Space): string {
    return space.getName();
  }

  parentSpaceValue(space: Space): string {
    return space.getId();
  }

  parentSpaceAddress(space: Space): string {
    const address = space.getAddress();

    if (address) {
      return address.getAddressLineOne() + ", " + address.getPostcode() + ", " + address.getCity();
    } else {
      return space.getCategoryName();
    }
  }

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoComplete(): boolean {
    return (
      !!this.selectedCategory &&
      !!this.spaceName &&
      (!this.addressRequired ||
        (!!this.address.addressLineOne &&
          !!this.address.zipCode &&
          !!this.address.city &&
          !!this.selectedCountry?.getId()))
    );
  }

  async onSaveClicked(): Promise<void> {
    if (this.selectedCategory) {
      const request = new CreateSpaceRequest();

      request.setAddOwnerToSpace(this.addOwnerToSpace);
      request.setName(this.spaceName);

      if (this.addressRequired && this.selectedCountry) {
        const address = new Address();
        address.setAddressLineOne(this.address.addressLineOne);
        address.setAddressLineTwo(this.address.addressLineTwo);
        address.setCity(this.address.city);
        address.setPostcode(this.address.zipCode);
        address.setCountry(this.selectedCountry);
        request.setAddress(address);
      }

      request.setCategory(this.selectedCategory.getCategory());
      if (this.selectedParentSpace?.getId()) {
        request.setParentSpaceId(this.selectedParentSpace?.getId());
      }

      if (this.selectedChildSpaceItems.length) {
        request.setChildSpaceIdsList(this.selectedChildSpaceItems.map((item) => item.value));
      }

      if (this.isAdminView && this.selectedChildPartner.data)
        request.setPartnerId(this.selectedChildPartner.data.getId());

      try {
        this.createSpaceCall = pending;
        await spaceServiceClient.createSpace(request, {});
        this.createSpaceCall = success(void 0);
        this.$emit("space-created");
        spaces.getSpaces();
      } catch (error) {
        this.createSpaceCall = failure(userErrorFrom(error));
      }
    }
  }
}
