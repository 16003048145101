
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import RegisterSensor from "./RegisterSensor.vue";

@Component({
  components: {
    RegisterSensor,
  },
})
export default class BsedConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  isSensorRegistered = false;
}
