
import CreateSpaceDialog from "@/components/create-space/CreateSpaceDialog.vue";
import { paths } from "@/router/routes";
import { RemoteData, initializedFactory } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { SearchParams } from "../devices-table/DevicesTable.vue";

export interface SpaceTable {
  id: string;
  addressLineOne: string;
  zipCode: string;
  city: string;
  name: string;
  isOwner: boolean;
  icon: string;
}

export interface TableControl {
  icon: string;
  event: string;
  color: string;
}

@Component({
  components: {
    CreateSpaceDialog,
  },
})
export default class SpacesTable extends Vue {
  @Prop({ default: false }) showAddSpaceButton: boolean;
  @Prop({ default: false }) showAddDeviceToSpaceButton: boolean;
  @Prop({ default: null }) spaceOwnerId: string | null;
  @Prop({ default: false }) pagination: boolean;
  @Prop({ default: [] }) itemControls: TableControl[];
  @Prop({ default: [] }) headerControls: TableControl[];
  @Prop({ default: initializedFactory }) spacesData: RemoteData<UserError, Space[]>;
  @Prop({ default: initializedFactory }) spacePages: RemoteData<UserError, number>;
  @Prop({ default: false }) showSearchBar: boolean;

  searchParams: SearchParams = {
    search: "",
    page: 1,
  };

  itemsPerPage = 10;
  selectedSpaceForInvite: Space | null = null;

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  mounted(): void {
    const pageQuery = this.$route.query.page;
    this.searchParams.search = (this.$route.query.search as string) ?? "";
    this.searchParams.page = typeof pageQuery === "string" ? parseInt(pageQuery, 10) : 1;
  }

  @Watch("searchParams.page")
  onPageChanged(): void {
    this.updateRoute();
    this.$emit("search-params-input", this.searchParams);
  }
  @Watch("searchParams.search")
  async onSearchChanged(): Promise<void> {
    this.updateRoute();
    if (this.searchParams.search === "") {
      this.$emit("search-params-input", this.searchParams);
    } else {
      const searchTerm = this.searchParams.search;
      await this.sleep(500);
      if (searchTerm === this.searchParams.search) {
        this.$emit("search-params-input", this.searchParams);
      }
    }
  }

  updateRoute(): void {
    this.$router.push({
      path: paths.admin.spaces,
      query: { page: this.searchParams.page.toString(), search: this.searchParams.search },
    });
  }

  get headers() {
    return [
      { text: "", value: "icon", sortable: false },
      { text: "Objektname", value: "name", sortable: false },
      { text: "Adresszeile 1", value: "addressLineOne", sortable: false },
      { text: "PLZ", value: "zipCode", sortable: false },
      { text: "Stadt", value: "city", sortable: false },
      { text: "", value: "controls", sortable: false, align: "right" },
      ...(!this.spaceOwnerId ? [] : [{ text: "", value: "isOwner", sortable: false }]),
    ];
  }

  get spaces(): SpaceTable[] {
    return this.spacesData.list.map((s: Space) => ({
      id: s.getId(),
      addressLineOne: s.getAddress()?.getAddressLineOne() ?? "",
      zipCode: s.getAddress()?.getPostcode() ?? "",
      city: s.getAddress()?.getCity() ?? "",
      name: s.getName(),
      isOwner: s.getOwnerUserId() === this.spaceOwnerId,
      icon: s.getCategoryIcon(),
    }));
  }

  get totalPages(): number {
    return this.spacePages?.data ?? 1;
  }

  handleRowClick(space: { id: string; addressLineOne: string; zipCode: string; city: string; name: string }): void {
    this.$router.push({
      path: `${paths.admin.spaces}/${space.id}`,
    });
  }

  onItemControlsClicked(event: Event, space: SpaceTable, customEvent: string) {
    event.stopPropagation();
    this.$emit(customEvent, space.id);
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }
}
