
import { failure, initialized, RemoteData, success } from "@/store/utils/remote-data";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UserError, userErrorFrom } from "@/types/user-error";
import { GetLandlordToTenantProjectMetersRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import DeleteLandlordToTenantMeterDialog from "./DeleteLandlordToTenantMeterDialog.vue";
import ProjectMetersTable, {
  ProjectMeterTable,
} from "@/components/landlord-tenants-project-table/ProjectMetersTable.vue";
import AddLandlordToTenantMeterDialog from "./AddLandlordToTenantMeterDialog.vue";
import { LandlordToTenantProjectMeter } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
@Component({
  components: { ProjectMetersTable, DeleteLandlordToTenantMeterDialog, AddLandlordToTenantMeterDialog },
})
export default class SingleProjectDevices extends Vue {
  @Prop({ default: null }) projectId: string | null;
  projectMeters: RemoteData<UserError, LandlordToTenantProjectMeter[]> = initialized;
  deleteMeterDialog: boolean = false;
  addLandlordToTenantMeterDialog: boolean = false;
  selectedMeterId: string | null = null;
  selectedDeviceId: string | null = null;
  deviceName: string | null = null;
  itemControls = [
    {
      icon: "mdi-trash-can-outline",
      event: "delete-meter",
    },
  ];
  headerControls = [
    {
      icon: "mdi-plus-circle-outline",
      event: "add-meter",
    },
  ];

  @Watch("projectId")
  onProjectIdUpdated(): void {
    this.getProjectMeters();
  }

  async getProjectMeters(): Promise<void> {
    this.deleteMeterDialog = false;
    const request = new GetLandlordToTenantProjectMetersRequest();
    if (this.projectId) {
      request.setProjectId(this.projectId);
    }
    try {
      const response = await landlordToTenantServiceClient.getLandlordToTenantProjectMeters(request, {});
      this.projectMeters = success(response.getMetersList());
    } catch (error) {
      this.projectMeters = failure(userErrorFrom(error));
    }
  }

  deleteSelectedMeter(meter: ProjectMeterTable): void {
    this.deleteMeterDialog = true;
    this.selectedMeterId = meter.id;
    if (meter.type == "VIRTUAL") {
      this.selectedDeviceId = meter.deviceId;
      this.deviceName = meter.name;
    } else {
      this.selectedDeviceId = null;
      this.deviceName = null;
    }
  }
}
