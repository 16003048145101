
import { devicesModule } from "@/store/modules/devices";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ComparisonOperator, DeviceReadingTrigger } from "zaehlerfreunde-proto-types/automations_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";

@Component
export default class DeviceReadingTriggerComponent extends Vue {
  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  @Prop()
  trigger: DeviceReadingTrigger;

  @Prop({ default: false })
  editing: boolean;

  selectedDevice: Device | null = null;
  selectedOperator: ComparisonOperator = ComparisonOperator.GREATER_THAN;
  selectedThreshold: number = 0;
  selectedDirection: Direction = Direction.OUT;

  @Watch("selectedDevice")
  onSelectedDeviceChanged(): void {
    this.trigger.setDeviceId(this.selectedDevice?.getId() ?? "");
    this.$emit("valid", this.selectedDevice !== null);
  }

  @Watch("selectedOperator")
  onSelectedOperatorChanged(): void {
    this.trigger.setOperator(this.selectedOperator);
  }

  @Watch("selectedThreshold")
  onSelectedThresholdChanged(): void {
    this.trigger.setComparand(this.selectedThreshold);
  }

  @Watch("selectedDirection")
  onSelectedDirection(): void {
    this.trigger.setDirection(this.selectedDirection);
  }

  mounted(): void {
    if (this.trigger.getDeviceId() !== "") {
      this.onValueUpdated();
    } else {
      this.onSelectedOperatorChanged();
      this.onSelectedDirection();
    }
  }

  @Watch("trigger")
  onValueUpdated(): void {
    this.selectedDevice = this.devices.list.find((d) => d.getId() === this.trigger.getDeviceId()) ?? null;
    this.selectedOperator = this.trigger.getOperator();
    this.selectedThreshold = this.trigger.getComparand();
    this.selectedDirection = this.trigger.getDirection();
  }

  directionLabel(direction: Direction): string {
    switch (direction) {
      case Direction.IN:
        return "Bezug";
      case Direction.OUT:
        return "Einspeisung";
      default:
        return "";
    }
  }

  operatorLabel(operator: ComparisonOperator) {
    switch (operator) {
      case ComparisonOperator.GREATER_THAN:
        return "größer";
      case ComparisonOperator.LESS_THAN:
        return "kleiner";
      default:
        return "";
    }
  }

  directions = [
    {
      label: this.directionLabel(Direction.OUT),
      value: Direction.OUT,
    },
    {
      label: this.directionLabel(Direction.IN),
      value: Direction.IN,
    },
  ];

  operators = [
    {
      label: this.operatorLabel(ComparisonOperator.GREATER_THAN),
      value: ComparisonOperator.GREATER_THAN,
    },
    {
      label: this.operatorLabel(ComparisonOperator.LESS_THAN),
      value: ComparisonOperator.LESS_THAN,
    },
  ];
}
