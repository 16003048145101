
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  Action,
  Automation,
  DeviceAction,
  DeviceReadingTrigger,
  NotificationAction,
  PriceTrigger,
  Trigger,
  WallboxAction,
  StateOfChargeTrigger,
} from "zaehlerfreunde-proto-types/automations_pb";
import { Measurement } from "zaehlerfreunde-proto-types/device_reading_pb";
import { EnergySpotPrice } from "zaehlerfreunde-proto-types/spot_price_pb";
import TriggerComponent from "./triggers/Trigger.vue";
import ActionComponent from "./actions/Action.vue";
import { AddAutomationRequest } from "zaehlerfreunde-central/advisory_service_pb";
import { UpdateAutomationRequest } from "zaehlerfreunde-central/advisory_service_pb";
import spaces from "@/store/modules/spaces";
import { advisoryServiceClient } from "@/config/service-clients";

@Component({
  components: {
    TriggerComponent,
    ActionComponent,
  },
})
export default class AddAutomationDialog extends Vue {
  @Prop() value: boolean;
  @Prop() title: string;
  @Prop() subtitle: string;
  @Prop() automationDetail: Automation | null;

  showDialog: boolean = false;
  addAutomationCall: RemoteCall<UserError> = initialized;
  name: string = "";
  triggers: Trigger[] = [];
  actions: Action[] = [];

  @Watch("value")
  onValueChanged() {
    this.showDialog = this.value;
    if (!this.showDialog && !this.automationDetail) {
      this.name = "";
      this.triggers = [];
      this.actions = [];
    }
  }

  @Watch("automationDetail")
  onAutomationChanged() {
    if (!this.automationDetail) {
      return;
    }

    this.actions = this.automationDetail.getActionsList();
    this.triggers = this.automationDetail.getTriggersList();

    this.name = this.automationDetail.getName();
  }

  get validInput(): boolean {
    const validTriggers =
      this.triggers.length > 0 &&
      this.triggers.every((t) => {
        console.log("Type case is: " + t.getTypeCase());
        switch (t.getTypeCase()) {
          case Trigger.TypeCase.READING_TRIGGER:
            return !!t.getReadingTrigger()?.getDeviceId();
          case Trigger.TypeCase.PRICE_TRIGGER:
            return true;
          case Trigger.TypeCase.STATE_OF_CHARGE_TRIGGER:
            return !!t.getStateOfChargeTrigger()?.getDeviceId();
          default:
            return false;
        }
      });

    const validActions =
      this.actions.length > 0 &&
      this.actions.every((a) => {
        switch (a.getActionCase()) {
          case Action.ActionCase.DEVICE_ACTION:
            return !!a.getDeviceAction()?.getDeviceId();
          case Action.ActionCase.WALLBOX_ACTION:
            return !!a.getWallboxAction()?.getDeviceId();
          case Action.ActionCase.NOTIFICATION_ACTION:
            return (
              (a.getNotificationAction()?.getEmailsList().length ?? 0) +
                (a.getNotificationAction()?.getPhoneNumbersList().length ?? 0) >
              0
            );
          default:
            return false;
        }
      });

    console.log("Valid triggers: " + validTriggers);
    console.log("Valid actions: " + validActions);

    return !!this.name && validTriggers && validActions;
  }

  addDeviceReadingTrigger() {
    this.triggers = [
      ...this.triggers,
      new Trigger().setReadingTrigger(new DeviceReadingTrigger().setMeasurement(Measurement.THROUGHPUT)),
    ];
  }

  addPriceTrigger() {
    const priceTrigger = new PriceTrigger();
    priceTrigger.setType(EnergySpotPrice.Type.DAY_AHEAD);
    priceTrigger.setExchange(EnergySpotPrice.Exchange.EPEX);

    this.triggers = [...this.triggers, new Trigger().setPriceTrigger(priceTrigger)];
  }

  addStateOfChargeTrigger() {
    const stateOfChargeTrigger = new StateOfChargeTrigger();
    this.triggers = [...this.triggers, new Trigger().setStateOfChargeTrigger(stateOfChargeTrigger)];
  }

  addDeviceAction() {
    const deviceAction = new DeviceAction();
    this.actions = [...this.actions, new Action().setDeviceAction(deviceAction)];
  }

  addWallboxAction() {
    const wallboxAction = new WallboxAction();
    this.actions = [...this.actions, new Action().setWallboxAction(wallboxAction)];
  }

  addNotificationAction() {
    const notificationAction = new NotificationAction();
    this.actions = [...this.actions, new Action().setNotificationAction(notificationAction)];
  }

  async onSaveClicked(): Promise<void> {
    const automation = new Automation();
    automation.setName(this.name);
    automation.setTriggersList(this.triggers);
    automation.setActionsList(this.actions);
    automation.setSpaceId(spaces.selectedSpaceId);

    const request = new AddAutomationRequest();
    request.setSpaceId(spaces.selectedSpaceId);
    request.setAutomation(automation);

    this.addAutomationCall = pending;

    try {
      await advisoryServiceClient.addAutomation(request, {});
      this.$emit("automation-created");
      this.$emit("input", false);

      this.addAutomationCall = success(void 0);
    } catch (error) {
      this.addAutomationCall = failure(userErrorFrom(error));
    }
  }

  async onUpdateClicked(): Promise<void> {
    const automation = new Automation();
    automation.setId(this.automationDetail?.getId() ?? "");
    automation.setName(this.name);
    automation.setTriggersList(this.triggers);
    automation.setActionsList(this.actions);
    automation.setSpaceId(spaces.selectedSpaceId);

    const request = new UpdateAutomationRequest();
    request.setAutomation(automation);

    this.addAutomationCall = pending;

    try {
      await advisoryServiceClient.updateAutomation(request, {});
      this.$emit("automation-updated");
      this.$emit("input", false);

      this.addAutomationCall = success(void 0);
    } catch (error) {
      this.addAutomationCall = failure(userErrorFrom(error));
    }
  }
}
