
import subscription, { subscriptionModule } from "@/store/modules/subscription";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";
import { CancelSubscriptionRequest, Product } from "zaehlerfreunde-central/user_service_pb";
import SubscriptionPlans, { ActivateButton } from "@/components/subscription/SubscriptionPlans.vue";
import { userServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import devices, { devicesModule } from "@/store/modules/devices";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {
    SubscriptionPlans,
  },
})
export default class Subscription extends Vue {
  @subscriptionModule.State subscriptionPlan: RemoteData<UserError, SubscriptionPlan>;
  @subscriptionModule.State createCheckoutSessionCall: RemoteCall<UserError>;
  cancelProSubscriptionCall: RemoteCall<UserError> = initialized;
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  cancelReasons = [
    "Zu teuer",
    "Meine Geräte werden nicht unterstützt",
    "Fehlender Funktionsumfang",
    "Die kostenlose Version reicht mir",
    "Sonstiges",
  ];

  otherReason: string = "";

  selectedCancelReason: string | null = null;

  showCancelSubscriptionDialog: boolean = false;

  DeviceProvider = Device.Provider;

  get hasPowerfoxDeviceConnected(): boolean {
    return this.devices.list.filter((d) => d.getProvider() == this.DeviceProvider.POWERFOX).length > 0;
  }

  get isB2BPartner(): boolean {
    return this.partner.data?.getType() === Partner.Type.B2B;
  }

  get freePlanButton(): ActivateButton {
    return {
      text: "Ausgewählt",
      show: this.subscriptionPlan.data === SubscriptionPlan.FREE,
      disabled: true,
    };
  }

  get proPlanButton(): ActivateButton {
    return {
      text: this.subscriptionPlan.data === SubscriptionPlan.FREE ? "Jetzt kostenlos testen" : "Abo kündigen",
      show: true,
      disabled: false,
      event: this.subscriptionPlan.data === SubscriptionPlan.FREE ? "activateProSubscription" : "cancelSubscription",
      errorColor: this.subscriptionPlan.data === SubscriptionPlan.PRO,
    };
  }

  get b2bPlanButton(): ActivateButton {
    return {
      text: this.subscriptionPlan.data === SubscriptionPlan.FREE ? "Jetzt kostenlos testen" : "Abo kündigen",
      show: true,
      disabled: false,
      event: this.subscriptionPlan.data === SubscriptionPlan.FREE ? "activateB2BSubscription" : "cancelSubscription",
      errorColor: this.subscriptionPlan.data === SubscriptionPlan.B2B,
    };
  }

  get requiredFeature(): string | null {
    const requiredFeature = this.$route.query["requiredFeature"];
    if (typeof requiredFeature === "string") {
      return requiredFeature;
    } else if (requiredFeature) {
      return requiredFeature[0];
    } else {
      return null;
    }
  }

  onActivateProSubscription(): void {
    subscription.createCheckoutSession(Product.PRO_SUBSCRIPTION);
  }

  onActivateB2BSubscription(): void {
    subscription.createCheckoutSession(Product.B2B_SUBSCRIPTION);
  }

  async onCancelSubscription(): Promise<void> {
    var cancelReason = "";
    if (this.selectedCancelReason) {
      if (this.selectedCancelReason === "Sonstiges") {
        cancelReason = this.otherReason;
      } else {
        cancelReason = this.selectedCancelReason;
      }
    }

    try {
      this.cancelProSubscriptionCall = pending;
      const request = new CancelSubscriptionRequest();
      request.setReason(cancelReason);
      await userServiceClient.cancelSubscription(request, {});
      await new Promise((f) => setTimeout(f, 4000));
      this.cancelProSubscriptionCall = success(void 0);

      subscription.getCurrentSubscriptionPlan();
      spaces.getSpaces();
      devices.getDevices();

      this.showCancelSubscriptionDialog = false;
    } catch (error) {
      this.cancelProSubscriptionCall = failure(userErrorFrom(error));
    }
  }
}
