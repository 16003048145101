
import { tariffServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import tariff from "@/store/modules/tariff";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SetEstimatedConsumptionRequest } from "zaehlerfreunde-central/tariff_service_pb";

@Component
export default class DisableWatchdog extends Vue {
  @Prop() value: boolean;

  estimatedConsumption = "";

  call: RemoteCall<UserError> = initialized;

  async onSaveClicked(): Promise<void> {
    try {
      this.call = pending;

      const request = new SetEstimatedConsumptionRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setEstimatedConsumption(parseFloat(this.estimatedConsumption));

      await tariffServiceClient.setEstimatedConsumption(request, {});

      this.call = success(void 0);

      tariff.getEstimatedConsumption();
      this.$emit("input", false);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }
}
