
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Prop, Vue } from "vue-property-decorator";
import DisableWatchdog from "./DisableWatchdog.vue";
import UpdateEstimatedConsumption from "./UpdateEstimatedConsumption.vue";
import tariff, { tariffModule } from "@/store/modules/tariff";
import TariffCard from "./TariffCard.vue";
import { EnergyTariff, GasTariff, TariffSearchInfo, WaterTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { themeModule } from "@/store/modules/theme";
import AddTariffReminder from "@/pages/platform/dashboard/AddTariffReminder.vue";

@Component({
  components: {
    DisableWatchdog,
    TariffCard,
    AddTariffReminder,
    UpdateEstimatedConsumption,
  },
})
export default class TariffInfos extends Vue {
  @tariffModule.Getter energyTariffs: RemoteData<UserError, EnergyTariff[]>;
  @tariffModule.Getter gasTariffs: RemoteData<UserError, GasTariff[]>;
  @tariffModule.Getter waterTariffs: RemoteData<UserError, WaterTariff[]>;
  @tariffModule.Getter tariffSearchInfo: RemoteData<UserError, TariffSearchInfo>;
  @tariffModule.Getter estimatedConsumption: RemoteData<UserError, number>;
  @themeModule.Getter apperance: "light" | "dark";

  showDisableWatchdogDialog: boolean = false;
  showUpdateEstimatedConsumptionDialog: boolean = false;
  zfProtoDeviceMedium = Device.Medium;

  @Prop() deviceMedium: Device.Medium;

  mounted(): void {
    tariff.getEstimatedConsumption();
  }
}
