
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { EnergyTariff, SearchResult } from "zaehlerfreunde-proto-types/tariff_pb";
import SavingsCalculationExplanation from "./SavingsCalculationExplanation.vue";

@Component({
  components: {
    SavingsCalculationExplanation,
  },
})
export default class Savings extends Vue {
  @tariffModule.Getter tariffSearchResults: RemoteData<UserError, SearchResult[]>;
  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff>;

  showSavingsCalculationExplanation: boolean = false;

  onCalculationExplanationButtonClicked(): void {
    this.showSavingsCalculationExplanation = true;
  }
}
