
import { deviceServiceClient, readingsServiceClient } from "@/config/service-clients";
import { consumptionModule } from "@/store/modules/consumption";
import { devicesModule } from "@/store/modules/devices";
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { dateToDateTime } from "@/utils/proto-utils";
import { Vue, Component } from "vue-property-decorator";
import { GetDeviceReadingStatsRequest } from "zaehlerfreunde-central/device_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { DeviceReading, Measurement, MeasurementWithLabel } from "zaehlerfreunde-proto-types/device_reading_pb";
import { DeviceReadingsFilter, FileFormat } from "zaehlerfreunde-central/reading_service_pb";
import { ExportReadingsRequest } from "zaehlerfreunde-central/reading_service_pb";

@Component
export default class CSVExport extends Vue {
  @devicesModule.State devices: RemoteData<UserError, Device[]>;
  @consumptionModule.State deviceIngestionReadingsForCSVExport: RemoteData<UserError, DeviceReading[]>;
  @consumptionModule.State deviceConsumptionReadingsForCSVExport: RemoteData<UserError, DeviceReading[]>;

  show: boolean = true;
  startDate: Date | null = null;
  endDate: Date | null = null;
  selectedDevice: Device | null = null;
  resolution: number | null;
  availableMeasurements: RemoteData<UserError, MeasurementWithLabel[]> = initialized;
  selectedMeasurement: Measurement | null = null;

  exportCSVCall: RemoteCall<UserError> = initialized;

  resolutions: { text: string; value: number }[] = [
    { text: "3 min", value: 3 },
    { text: "15 min", value: 15 },
    { text: "1 Stunde", value: 60 },
    { text: "1 Tag", value: 60 * 24 },
  ];

  getDeviceName(device: Device): string {
    return device.getName();
  }

  getDeviceId(device: Device): string {
    return device.getId();
  }

  hideDialog(): void {
    this.show = false;
    this.$emit("closed");
  }

  getLabel(measurement: MeasurementWithLabel): string {
    return measurement.getLabel();
  }

  onDeviceSelected(device: Device): void {
    this.selectedMeasurement = null;
    this.getAvailableMeasurements(device);
    this.selectedDevice = device;
  }

  async getAvailableMeasurements(device: Device): Promise<void> {
    this.availableMeasurements = pending;
    const request = new GetDeviceReadingStatsRequest();
    request.setDeviceId(device.getId());
    try {
      const response = await deviceServiceClient.getDeviceReadingStats(request, {});
      const measurementsList = response.getDeviceReadingStats()?.getMeasurementsList() ?? [];
      this.availableMeasurements = success(measurementsList);
    } catch (error) {
      this.availableMeasurements = failure(userErrorFrom(error));
    }
  }

  async downloadData(): Promise<void> {
    const filter = new DeviceReadingsFilter();

    const request = new ExportReadingsRequest();
    if (this.selectedDevice && this.startDate && this.endDate && this.resolution && this.selectedMeasurement !== null) {
      try {
        this.exportCSVCall = pending;
        const id = new ExportReadingsRequest.DeviceIds().addDeviceIds(this.selectedDevice?.getId());
        filter.setMeasurement(this.selectedMeasurement);
        request.setByIds(id);
        request.setFileFormat(FileFormat.STANDARD_CSV);
        request.setFilter(filter);
        request.setStartTime(dateToDateTime(this.startDate));
        request.setEndTime(dateToDateTime(this.endDate).setHours(24));
        request.setResolution(this.resolution);

        const response = await readingsServiceClient.exportReadings(request, {});

        this.exportCSVCall = success(void 0);

        const blob = new Blob([response.getContent()], { type: "text/csv;charset=utf-8," });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objUrl);
        link.setAttribute("download", response.getFileName());
        link.click();
      } catch (error) {
        this.exportCSVCall = failure(userErrorFrom(error));
      }
    }
  }

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }
}
