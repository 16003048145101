import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { RemoteSpaceData, SpaceData } from "../utils/space-data";
import { failure, initialized, pending, RemoteData, success, successOptional } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import spaces from "./spaces";
import { spaceServiceClient } from "@/config/service-clients";
import { namespace } from "vuex-class";
import {
  AccumulatedValue,
  DeviceStatus,
  GetAccumulatedValueRequest,
  GetDeviceStatusesRequest,
} from "zaehlerfreunde-central/reading_service_pb";
import { readingsServiceClient } from "@/config/service-clients";
import { dateToDateTime, momentToDateTime } from "@/utils/proto-utils";
import moment from "moment";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";
import { GetConsumptionTargetRequest, SetConsumptionTargetRequest } from "zaehlerfreunde-central/space_service_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "consumption",
})
class ConsumptionModule extends VuexModule {
  deviceStatuses: RemoteData<UserError, DeviceStatus[]> = initialized;
  currentMonthConsumptionBySpaceId: RemoteSpaceData<UserError, AccumulatedValue> = {};
  consumptionTargetBySpaceId: SpaceData<RemoteData<UserError, number>> = {};

  get consumptionTarget(): RemoteData<UserError, number> {
    return this.consumptionTargetBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get currentMonthConsumptionOfSelectedSpace(): RemoteData<UserError, AccumulatedValue> {
    return this.currentMonthConsumptionBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  @Action
  async getCurrentMonthConsumptionOfSelectedSpace() {
    const spaceId = spaces.selectedSpaceId;
    const request = new GetAccumulatedValueRequest();
    request.setSpaceId(spaceId);
    request.setStartDate(momentToDateTime(moment().startOf("month")));
    request.setEndDate(dateToDateTime(new Date()));
    request.setDirection(Direction.IN);

    try {
      this.setCurrentMonthConsumption({ spaceId, currentMonthConsumption: pending });
      const response = await readingsServiceClient.getAccumulatedValue(request, {});
      this.setCurrentMonthConsumption({ spaceId, currentMonthConsumption: successOptional(response.getValue()) });
    } catch (error) {
      this.setCurrentMonthConsumption({ spaceId, currentMonthConsumption: failure(userErrorFrom(error)) });
    }
  }

  @Action
  async getDeviceStatuses(silent: boolean = false) {
    const spaceId = spaces.selectedSpaceId;

    const request = new GetDeviceStatusesRequest();
    request.setSpaceId(spaceId);

    try {
      if (!silent) {
        this.setDeviceStatuses(pending);
      }

      const response = await readingsServiceClient.getDeviceStatuses(request, {});

      if (spaces.selectedSpaceId === spaceId) {
        this.setDeviceStatuses(success(response.getDeviceStatusesList()));
      }
    } catch (error) {
      this.setDeviceStatuses(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getConsumptionTarget() {
    const request = new GetConsumptionTargetRequest();
    request.setSpaceId(spaces.selectedSpaceId);

    try {
      this.setConsumptionTarget(pending);
      const response = await spaceServiceClient.getConsumptionTarget(request, {});

      this.setConsumptionTarget(success(response.getTargetKwh()));
    } catch (error) {
      this.setConsumptionTarget(failure(userErrorFrom(error)));
    }
  }

  @Action
  async updateConsumptionTarget(target: number) {
    const request = new SetConsumptionTargetRequest();
    request.setSpaceId(spaces.selectedSpaceId);
    request.setTargetKwh(target);

    try {
      this.setConsumptionTarget(pending);
      await spaceServiceClient.setConsumptionTarget(request, {});

      this.setConsumptionTarget(success(target));
    } catch (error) {
      this.setConsumptionTarget(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setDeviceStatuses(deviceStatuses: RemoteData<UserError, DeviceStatus[]>) {
    this.deviceStatuses = deviceStatuses;
  }

  @Mutation
  setConsumptionTarget(consumptionTarget: RemoteData<UserError, number>) {
    this.consumptionTargetBySpaceId = {
      ...this.consumptionTargetBySpaceId,
      [spaces.selectedSpaceId]: consumptionTarget,
    };
  }

  @Mutation
  setCurrentMonthConsumption(payload: {
    spaceId: string;
    currentMonthConsumption: RemoteData<UserError, AccumulatedValue>;
  }) {
    this.currentMonthConsumptionBySpaceId = {
      ...this.currentMonthConsumptionBySpaceId,
      [payload.spaceId]: payload.currentMonthConsumption,
    };
  }
}

export const consumptionModule = namespace("consumption");
export default getModule(ConsumptionModule);
