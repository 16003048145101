import { render, staticRenderFns } from "./SpacesTable.vue?vue&type=template&id=112c7271&scoped=true"
import script from "./SpacesTable.vue?vue&type=script&lang=ts"
export * from "./SpacesTable.vue?vue&type=script&lang=ts"
import style0 from "./SpacesTable.vue?vue&type=style&index=0&id=112c7271&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112c7271",
  null
  
)

export default component.exports