
import tariff from "@/store/modules/tariff";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { tariffServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { EnergyTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { AddTariffRequest, AdvancePayment, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import TariffAdvancePayments from "./TariffAdvancePayments.vue";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Component({
  components: {
    TariffAdvancePayments,
  },
})
export default class AddUpdateEnergyTariff extends Vue {
  call: RemoteCall<UserError> = initialized;
  addAdvancePaymentCall: RemoteCall<UserError> = initialized;

  @Prop({ default: null }) energyTariff: EnergyTariff | null;
  @Prop({ default: false }) startDateRequiredProp: boolean;
  @Prop() value: boolean;
  @Prop({ default: false }) askForEnergyConsumption: boolean;
  @Prop({ default: "" }) spaceId: string;

  medium: Device.Medium = Device.Medium.ELECTRICITY;
  supplier: string = "";
  pricePerKwh: string = "";
  surchargePerKwh: string = "";
  basePrice: string = "";
  greenEnergy: boolean = false;
  co2PerKWh: string = "";
  earliestCancelDate: Date | null = null;
  startDate: Date | null = null;
  maxPricePerKWh: string = "";
  minPricePerKWh: string = "";
  isVariableTariff: boolean = false;
  monthlyAdvancePayments: string = "";
  yearlyConsumption: string = "";
  newAdvancePayment: AdvancePayment | null = null;
  advancePaymentsKey: number = 0;

  startDateRequired: boolean = false;

  get infoComplete(): boolean {
    return (
      !!this.supplier &&
      !!this.basePrice &&
      !!this.earliestCancelDate &&
      (!this.startDateRequired || !!this.startDate) &&
      ((this.isVariableTariff && !!this.surchargePerKwh) || (!this.isVariableTariff && !!this.pricePerKwh)) &&
      (!this.askForEnergyConsumption || !!this.yearlyConsumption)
    );
  }

  get yearlyConsumptionNumber(): number {
    return parseFloat(this.yearlyConsumption);
  }

  pricePerKwhRule(input: string): string | boolean {
    const price = Number(input);
    if (price < 1 && price > 0) {
      return "Preis in Cent/kWh nicht €/kWh";
    } else {
      return true;
    }
  }

  requiredRule(input: string): string | boolean {
    return input.length > 0 ? true : "Dieses Feld ist erforderlich";
  }

  mounted(): void {
    this.fetchForm();
  }

  async addUpdateEnergyTariff(): Promise<void> {
    const energyTariff = new EnergyTariff();

    const supplier = new EnergyTariff.Supplier();
    supplier.setName(this.supplier);
    energyTariff.setSupplier(supplier);
    const startDate = this.startDate;

    if (startDate) {
      energyTariff.setStartDate(startDate.toDateString());
    }

    energyTariff.setEarliestCancelDate((this.earliestCancelDate ?? new Date()).toDateString());
    energyTariff.setBasePrice(parseFloat(this.basePrice));
    energyTariff.setIsVariable(this.isVariableTariff);
    energyTariff.setIsGreen(this.greenEnergy);

    const gCo2PerKwh = parseFloat(this.co2PerKWh);
    if (!isNaN(gCo2PerKwh)) {
      energyTariff.setKgCo2PerKwh(gCo2PerKwh / 1000);
    }

    if (this.isVariableTariff) {
      const priceDetails = new EnergyTariff.VariablePriceDetails();
      priceDetails.setSurchargePerKwh(parseFloat(this.surchargePerKwh));

      const maxPrice = parseFloat(this.maxPricePerKWh);
      if (!isNaN(maxPrice)) {
        priceDetails.setMaxPricePerKwh(maxPrice);
      }

      const minPrice = parseFloat(this.minPricePerKWh);
      if (!isNaN(minPrice)) {
        priceDetails.setMinPricePerKwh(-1 * minPrice);
      }

      energyTariff.setVariablePriceDetails(priceDetails);
    } else {
      const priceDetails = new EnergyTariff.FixedPriceDetails();
      priceDetails.setPricePerKwh(parseFloat(this.pricePerKwh));
      energyTariff.setFixedPriceDetails(priceDetails);
    }

    try {
      this.call = pending;

      if (this.energyTariff) {
        energyTariff.setId(this.energyTariff.getId());
        const request = new UpdateTariffRequest();
        request.setEnergyTariff(energyTariff);
        request.setSpaceId(this.spaceId);
        if (this.newAdvancePayment) {
          request.setNewAdvancePayment(this.newAdvancePayment);
          this.newAdvancePayment = null;
        }
        const response = await tariffServiceClient.updateTariff(request, {});
        let energyTariffs = response.getEnergyTariffsResponse()?.getEnergyTariffsList() as EnergyTariff[];
        tariff.setEnergyTariffs(success(energyTariffs));
      } else {
        const request = new AddTariffRequest();
        request.setSpaceId(this.spaceId);
        request.setEnergyTariff(energyTariff);
        if (this.askForEnergyConsumption) {
          request.setEstimatedYearlyConsumption(parseFloat(this.yearlyConsumption));
        }
        if (this.newAdvancePayment) {
          request.setNewAdvancePayment(this.newAdvancePayment);
          this.newAdvancePayment = null;
        }

        const response = await tariffServiceClient.addTariff(request, {});
        let energyTariffs = response.getEnergyTariffsResponse()?.getEnergyTariffsList() as EnergyTariff[];
        tariff.setEnergyTariffs(success(energyTariffs));
        this.resetForm();
      }
      tariff.getCurrentEnergyTariff();
      this.call = success(void 0);
      this.$emit("tariff-updated");
      this.$emit("input", false);
    } catch (error) {
      this.call = failure(userErrorFrom(error));
    }
  }

  resetForm() {
    this.supplier = "";
    this.pricePerKwh = "";
    this.surchargePerKwh = "";
    this.basePrice = "";
    this.greenEnergy = false;
    this.co2PerKWh = "";
    this.earliestCancelDate = null;
    this.startDate = null;
    this.maxPricePerKWh = "";
    this.minPricePerKWh = "";
    this.isVariableTariff = false;
    this.monthlyAdvancePayments = "";
    this.yearlyConsumption = "";
  }

  onCancel() {
    this.fetchForm();
    this.$emit("input", false);
  }

  onChangedAdvancePaymentDetails(newAdvancePayment: AdvancePayment | null) {
    this.newAdvancePayment = newAdvancePayment;
  }

  fetchForm() {
    this.startDateRequired = this.startDateRequiredProp;
    if (this.energyTariff) {
      this.supplier = this.energyTariff.getSupplier()?.getName() ?? "";
      this.greenEnergy = this.energyTariff.getIsGreen();
      this.earliestCancelDate = new Date(this.energyTariff.getEarliestCancelDate());
      this.isVariableTariff = this.energyTariff.getIsVariable();
      this.basePrice = this.energyTariff.getBasePrice().toFixed(2);
      if (this.energyTariff.getStartDate()) {
        this.startDate = new Date(this.energyTariff.getStartDate());
      } else {
        this.startDateRequired = false;
      }

      if (this.energyTariff.getMonthlyAdvancePayment() > 0) {
        this.monthlyAdvancePayments = this.energyTariff.getMonthlyAdvancePayment()?.toFixed(2) ?? "";
      }

      if (this.energyTariff.getKgCo2PerKwh() > 0) {
        this.co2PerKWh = (this.energyTariff.getKgCo2PerKwh() * 1000).toFixed(0);
      }

      const priceDetails = this.energyTariff.getVariablePriceDetails();
      if (this.energyTariff.getIsVariable() && priceDetails) {
        this.surchargePerKwh = priceDetails.getSurchargePerKwh()?.toFixed(2) ?? "";

        if (priceDetails.getMaxPricePerKwh() !== 0) {
          this.maxPricePerKWh = priceDetails.getMaxPricePerKwh().toFixed(2);
        }

        if (priceDetails.getMinPricePerKwh() !== 0) {
          this.minPricePerKWh = (-1 * priceDetails.getMinPricePerKwh()).toFixed(2);
        }
      } else {
        this.pricePerKwh = this.energyTariff.getFixedPriceDetails()?.getPricePerKwh()?.toFixed(2) ?? "";
      }
    }
  }
}
