
import { getAuthService } from "@/auth";
import { i18n } from "@/locales/i18n";
import { paths } from "@/router/routes";
import { Vue, Component } from "vue-property-decorator";

interface DeviceType {
  name: string;
  icon: string;
  description: string;
  supportedDevices: Device[];
}

interface Device {
  name: string;
  stillInProgress: boolean;
}

const deviceTypes: DeviceType[] = [
  {
    name: i18n.t("energyMeters").toString(),
    icon: "mdi-meter-electric",
    description: i18n.t("landingPage.b2c.supportEnergyMetersText").toString(),
    supportedDevices: [
      {
        name: "Discovergy",
        stillInProgress: false,
      },
      {
        name: "Shelly 3EM (Pro)",
        stillInProgress: false,
      },
      {
        name: "Wattline",
        stillInProgress: false,
      },
      {
        name: "Powerfox",
        stillInProgress: false,
      },
      {
        name: "Zählerfreunde Auslesekopf",
        stillInProgress: false,
      },
      {
        name: "Tibber",
        stillInProgress: false,
      },
      {
        name: "TSUN Wechselrichter",
        stillInProgress: false,
      },
      {
        name: "EDA Zähler (Österreich)",
        stillInProgress: false,
      },
      {
        name: "Analoge Stromzähler",
        stillInProgress: false,
      },
      {
        name: "bitShake",
        stillInProgress: false,
      },
    ],
  },
  {
    name: i18n.t("wallboxes").toString(),
    icon: "mdi-car-electric",
    description: i18n.t("landingPage.b2c.supportWallboxesText").toString(),
    supportedDevices: [
      {
        name: "Easee",
        stillInProgress: false,
      },
      {
        name: "Go-E",
        stillInProgress: false,
      },
      {
        name: "Juice",
        stillInProgress: false,
      },
      {
        name: "KEBA",
        stillInProgress: false,
      },
      {
        name: "Myenergi",
        stillInProgress: false,
      },
      {
        name: "Webasto",
        stillInProgress: false,
      },
      {
        name: "ABL",
        stillInProgress: false,
      },
      {
        name: "EVBox",
        stillInProgress: false,
      },
      {
        name: "Heidelberg",
        stillInProgress: false,
      },
    ],
  },
  {
    name: i18n.t("smartPlugs").toString(),
    icon: "mdi-connection",
    description: i18n.t("landingPage.b2c.supportSmartPlugsText").toString(),
    supportedDevices: [
      {
        name: "Shelly 1PM",
        stillInProgress: false,
      },
      {
        name: "Shelly Plus 1PM",
        stillInProgress: false,
      },
      {
        name: "Shelly Plus 1",
        stillInProgress: false,
      },
      {
        name: "Shelly Plug S",
        stillInProgress: false,
      },
      {
        name: "Shelly Plug",
        stillInProgress: false,
      },
      {
        name: "Shelly Plus Plug S",
        stillInProgress: false,
      },
      {
        name: "myStrom WiFi Switch",
        stillInProgress: false,
      },
    ],
  },
  {
    name: i18n.t("heatPumps").toString(),
    icon: "mdi-access-point",
    description: i18n.t("landingPage.b2c.supportHeatPumps").toString(),
    supportedDevices: [
      {
        name: "Viessmann",
        stillInProgress: false,
      },
    ],
  },
];

@Component
export default class SupportedDevices extends Vue {
  deviceTypes = deviceTypes;

  selectedDeviceTypeIndex = 0;

  get selectedDeviceType(): DeviceType {
    return deviceTypes[this.selectedDeviceTypeIndex];
  }

  onRegisterClicked(): void {
    this.$gtag.event("registration-clicked", {
      event_label: " clicked",
      event_category: "user-action",
    });

    this.$gtag.event("conversion", {
      send_to: "AW-10831568791/--0CCJP1sI0YEJe_8qwo",
      value: 10,
      currency: "USD",
    });

    getAuthService().loginWithRedirect({ authorizationParams: { screen_hint: "signup" } });
  }

  onShopClicked(): void {
    this.$router.push(paths.homepage.privateCustomers.shop);
  }
}
