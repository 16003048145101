
import { paths } from "@/router/routes";
import devices, { devicesModule } from "@/store/modules/devices";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class BlueMeteringApiKey extends Vue {
  @devicesModule.State bluemeteringApiKey: RemoteData<UserError, string | null>;

  paths = paths;

  mounted(): void {
    devices.getBluemeteringApiKey();
  }

  onAddMeterClicked(): void {
    this.$router.push({
      path: paths.platform.blueMeteringImport,
      query: {
        apiKey: this.bluemeteringApiKey.data,
      },
    });
  }
}
