
import { spaceServiceClient, tariffServiceClient } from "@/config/service-clients";
import AddTariffReminder from "../../dashboard/AddTariffReminder.vue";
import TariffCard from "./TariffCard.vue";
import { RemoteData, success, failure, pending, initialized } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { EnergyTariff, GasTariff, WaterTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import { GetTariffsRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { GetSpacePermissionsRequest } from "zaehlerfreunde-central/space_service_pb";
import { AccessPermission } from "zaehlerfreunde-proto-types/permissions_pb";

interface TariffItem {
  title: string;
  value: Device.Medium;
}

@Component({
  components: {
    AddTariffReminder,
    TariffCard,
  },
})
export default class SpaceTariffs extends Vue {
  @Prop() spaceId: string;
  @Prop({ default: false }) isAdminView: boolean;

  @featuresModules.Getter enabledFeatures: Set<Feature>;

  tariffs: RemoteData<UserError, (EnergyTariff | WaterTariff | GasTariff)[]> = initialized;
  deviceMedium: Device.Medium = Device.Medium.ELECTRICITY;
  title: string = "Strom Tarif";
  useHasEditPermissions = false;

  get tariffItems(): TariffItem[] {
    const items = [
      { title: "Strom Tarif", value: Device.Medium.ELECTRICITY },
      { title: "Wasser Tarif", value: Device.Medium.WATER },
      { title: "Gas Tarif", value: Device.Medium.GAS },
    ];
    var resItems: TariffItem[] = [];
    if (this.isAdminView) {
      resItems = [...items];
    } else {
      if (this.enabledFeatures.has(Feature.ENERGY_TARIFF)) resItems.push(items[0]);
      if (this.enabledFeatures.has(Feature.WATER_TARIFF)) resItems.push(items[1]);
      if (this.enabledFeatures.has(Feature.GAS_TARIFF)) resItems.push(items[2]);
    }

    return resItems;
  }
  mounted(): void {
    this.getTariffs();
    this.checkEditPermissions();
  }

  get deviceTitle() {
    switch (this.deviceMedium) {
      case Device.Medium.ELECTRICITY:
        return "Strom Tarif";
      case Device.Medium.WATER:
        return "Wasser Tarif";
      case Device.Medium.GAS:
        return "Gas Tarif";
      default:
        return "";
    }
  }

  async getTariffs(): Promise<void> {
    try {
      this.tariffs = pending;
      const request = new GetTariffsRequest();
      request.setSpaceId(this.spaceId ?? "");
      request.setMedium(this.deviceMedium);
      const response = await tariffServiceClient.getTariffs(request, {});
      const tariffs = response.getTariffs();

      switch (this.deviceMedium) {
        case Device.Medium.ELECTRICITY:
          this.tariffs = success(tariffs?.getEnergy()?.getTariffsList() ?? []);
          break;
        case Device.Medium.WATER:
          this.tariffs = success(tariffs?.getWater()?.getTariffsList() ?? []);
          break;
        case Device.Medium.GAS:
          this.tariffs = success(tariffs?.getGas()?.getTariffsList() ?? []);
          break;
      }
    } catch (error) {
      this.tariffs = failure(userErrorFrom(error));
    }
  }

  async checkEditPermissions(): Promise<void> {
    try {
      const response = await spaceServiceClient.getSpacePermissions(
        new GetSpacePermissionsRequest().setSpaceId(this.spaceId),
        {}
      );

      this.useHasEditPermissions = response.getPermissionsList().some((p) => p === AccessPermission.EDIT);
    } catch (error) {
      this.useHasEditPermissions = true;
    }
  }
}
