
import { RemoteData, initialized, initializedFactory } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import spaces from "@/store/modules/spaces";
import { paths } from "@/router/routes";
import { TableControl } from "../spaces-table/SpacesTable.vue";

export interface DeviceTable {
  id: string;
  name: string;
  type: string;
  provider: string;
  status: string;
  device: Device;
}

export interface SearchParams {
  search: string;
  page: number;
}

@Component({})
export default class DevicesTable extends Vue {
  @Prop() devicesList: RemoteData<UserError, Device[]>;
  @Prop({ default: false }) showAllDeviceDetails: boolean;
  @Prop({ default: false }) isInSpaces: boolean;
  @Prop({ default: false }) clickable: boolean;
  @Prop() devicePages: RemoteData<UserError, number>;

  @Prop({ default: null }) itemControls: TableControl[] | null;
  @Prop({ default: null }) headerControls: TableControl[] | null;
  @Prop({ default: false }) showSearchBar: boolean;

  searchParams: SearchParams = {
    search: "",
    page: 1,
  };
  itemsPerPage = 10;
  paths = paths;

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async mounted(): Promise<void> {
    const pageQuery = this.$route.query.page;
    this.searchParams.search = (this.$route.query.search as string) ?? "";
    this.searchParams.page = typeof pageQuery === "string" ? parseInt(pageQuery, 10) : 1;
  }

  updateRoute(): void {
    this.$router.push({
      path: paths.admin.devices,
      query: { page: this.searchParams.page.toString(), search: this.searchParams.search },
    });
  }

  beforeMount(): void {
    this.$emit("page-changed", this.searchParams.page);
  }

  @Watch("searchParams.page")
  onPageChanged(): void {
    this.updateRoute();
    this.$emit("search-params-input", this.searchParams);
  }
  @Watch("searchParams.search")
  async onSearchChanged(): Promise<void> {
    this.updateRoute();
    if (this.searchParams.search === "") {
      this.$emit("search-params-input", this.searchParams);
    } else {
      const searchTerm = this.searchParams.search;
      await this.sleep(500);
      if (searchTerm === this.searchParams.search) {
        this.$emit("search-params-input", this.searchParams);
      }
    }
  }

  get headers() {
    return [
      { text: "Name", value: "name" },
      { text: "Typ", value: "type" },
      { text: "Hersteller", value: "provider" },
      { text: "Status", value: "status" },
      { text: "", value: "controls" },
    ];
  }

  get devices(): DeviceTable[] {
    let devices: DeviceTable[] = [];
    this.devicesList.list.forEach((d) => {
      devices.push({
        id: d.getId(),
        name: d.getName(),
        type: d.getDeviceTypeInfo()?.getName() ?? "",
        provider: d.getDeviceProviderInfo()?.getName() ?? "",
        status: this.getStatus(d.getStatus()),
        device: d,
      });
    });
    return devices;
  }

  getStatus(status: Device.Status): string {
    switch (status) {
      case Device.Status.CONNECTED:
        return "Connected";
      case Device.Status.CONNECTING:
        return "Connecting";
      case Device.Status.FAILED:
        return "Failed";
      default:
        return "Unknown Status";
    }
  }

  get totalPages(): number {
    return this.devicePages.data ?? 1;
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }
  onItemControlsClicked(device: Device, event: string) {
    this.$emit(event, device);
  }
  handleRowClick(device): void {
    this.$router.push({
      path: `${paths.admin.devices}/${device.id}`,
    });
  }
}
