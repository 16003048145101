
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const stylingModule = namespace("styling");

@Component
export default class SetConsumptionTarget extends Vue {
  @Prop({ default: 0 }) currentTarget: number;
  @Prop({ default: false }) loading: boolean;
  @Prop() value: boolean;

  target: number = 0;

  @stylingModule.State primaryColor: string;

  mounted(): void {
    this.target = this.currentTarget;
  }

  get validInput(): boolean {
    return this.target > 0;
  }

  onCancelClicked(): void {
    this.$emit("cancel");
  }

  onSaveClicked(): void {
    this.$emit("save", this.target);
  }
}
