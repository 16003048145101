
import { Component, Prop, Vue } from "vue-property-decorator";
import { DashboardItem } from "zaehlerfreunde-proto-types/dashboard_layout_pb";
import CurrentMonthCosts from "./current-month/CurrentMonthCosts.vue";
import TariffSwitch from "./tariff-switch/TariffSwitchStatus.vue";
import AddTariffReminder from "./AddTariffReminder.vue";
import BluemeteringApiKeyReminder from "./BluemeteringApiKeyReminder.vue";
import ConnectDeviceReminder from "./ConnectDeviceReminder.vue";
import ConsumptionGraph from "./ConsumptionGraph.vue";
import ConsumptionTarget from "./ConsumptionTarget/ConsumptionTarget.vue";
import CreateFirstSpaceReminder from "./CreateFirstSpaceReminder.vue";
import DashboardTipPresenter from "./DashboardTipPresenter.vue";
import CurrentMonthConsumption from "./current-month/CurrentMonthConsumption.vue";
import LatestStatusOverview from "./latest-status/LatestStatusOverview.vue";
import SpaceComparison from "./space-comparison/SpaceComparison.vue";
import Tips from "./tips/Tips.vue";
import UpdateManualReadings from "./update-manual-readings/UpdateManualReadings.vue";
import WelcomeMessage from "./WelcomeMessage.vue";
import MarketPremium from "./market-premium/MarketPremium.vue";
import SpaceKpis from "./space-kpis/SpaceKpis.vue";

@Component({
  components: {
    LatestStatusOverview,
    ConsumptionGraph,
    DashboardTipPresenter,
    Tips,
    WelcomeMessage,
    ConnectDeviceReminder,
    CreateFirstSpaceReminder,
    BluemeteringApiKeyReminder,
    CurrentMonthCosts,
    CurrentMonthConsumption,
    UpdateManualReadings,
    TariffSwitch,
    AddTariffReminder,
    ConsumptionTarget,
    SpaceComparison,
    MarketPremium,
    SpaceKpis,
  },
})
export default class DashboardItemComponent extends Vue {
  Kind = DashboardItem.Kind;

  @Prop() item: DashboardItem;
  @Prop({ default: false }) isEditing: boolean;

  get clazz(): { [name: string]: boolean } {
    const isFullWidth = this.item.getWidth() === DashboardItem.Width.FULL_WIDTH || this.isEditing;

    return { fullWidth: isFullWidth, halfWidth: !isFullWidth };
  }
}
