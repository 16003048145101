/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_landlord_to_tenant_service_pb from '../../services/central/landlord_to_tenant_service_pb';


export class LandlordToTenantServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetLandlordToTenantProjects = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/GetLandlordToTenantProjects',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsRequest,
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsResponse,
    (request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsResponse.deserializeBinary
  );

  getLandlordToTenantProjects(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsResponse>;

  getLandlordToTenantProjects(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsResponse>;

  getLandlordToTenantProjects(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/GetLandlordToTenantProjects',
        request,
        metadata || {},
        this.methodInfoGetLandlordToTenantProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/GetLandlordToTenantProjects',
    request,
    metadata || {},
    this.methodInfoGetLandlordToTenantProjects);
  }

  methodInfoGetLandlordToTenantProject = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/GetLandlordToTenantProject',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectRequest,
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectResponse,
    (request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectResponse.deserializeBinary
  );

  getLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectResponse>;

  getLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectResponse>;

  getLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/GetLandlordToTenantProject',
        request,
        metadata || {},
        this.methodInfoGetLandlordToTenantProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/GetLandlordToTenantProject',
    request,
    metadata || {},
    this.methodInfoGetLandlordToTenantProject);
  }

  methodInfoGetLandlordToTenantProjectMeters = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/GetLandlordToTenantProjectMeters',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersRequest,
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersResponse,
    (request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersResponse.deserializeBinary
  );

  getLandlordToTenantProjectMeters(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersResponse>;

  getLandlordToTenantProjectMeters(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersResponse>;

  getLandlordToTenantProjectMeters(
    request: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.GetLandlordToTenantProjectMetersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/GetLandlordToTenantProjectMeters',
        request,
        metadata || {},
        this.methodInfoGetLandlordToTenantProjectMeters,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/GetLandlordToTenantProjectMeters',
    request,
    metadata || {},
    this.methodInfoGetLandlordToTenantProjectMeters);
  }

  methodInfoDeleteLandlordToTenantMeter = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/DeleteLandlordToTenantMeter',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterRequest,
    services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterResponse,
    (request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterResponse.deserializeBinary
  );

  deleteLandlordToTenantMeter(
    request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterResponse>;

  deleteLandlordToTenantMeter(
    request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterResponse>;

  deleteLandlordToTenantMeter(
    request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantMeterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/DeleteLandlordToTenantMeter',
        request,
        metadata || {},
        this.methodInfoDeleteLandlordToTenantMeter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/DeleteLandlordToTenantMeter',
    request,
    metadata || {},
    this.methodInfoDeleteLandlordToTenantMeter);
  }

  methodInfoAddLandlordToTenantMeter = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/AddLandlordToTenantMeter',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterRequest,
    services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterResponse,
    (request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterResponse.deserializeBinary
  );

  addLandlordToTenantMeter(
    request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterResponse>;

  addLandlordToTenantMeter(
    request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterResponse>;

  addLandlordToTenantMeter(
    request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantMeterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/AddLandlordToTenantMeter',
        request,
        metadata || {},
        this.methodInfoAddLandlordToTenantMeter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/AddLandlordToTenantMeter',
    request,
    metadata || {},
    this.methodInfoAddLandlordToTenantMeter);
  }

  methodInfoDeleteLandlordToTenantProject = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/DeleteLandlordToTenantProject',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectRequest,
    services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectResponse,
    (request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectResponse.deserializeBinary
  );

  deleteLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectResponse>;

  deleteLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectResponse>;

  deleteLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.DeleteLandlordToTenantProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/DeleteLandlordToTenantProject',
        request,
        metadata || {},
        this.methodInfoDeleteLandlordToTenantProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/DeleteLandlordToTenantProject',
    request,
    metadata || {},
    this.methodInfoDeleteLandlordToTenantProject);
  }

  methodInfoAddLandlordToTenantProject = new grpcWeb.MethodDescriptor(
    '/services.central.LandlordToTenantService/AddLandlordToTenantProject',
    grpcWeb.MethodType.UNARY,
    services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectRequest,
    services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectResponse,
    (request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectRequest) => {
      return request.serializeBinary();
    },
    services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectResponse.deserializeBinary
  );

  addLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectResponse>;

  addLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectResponse) => void): grpcWeb.ClientReadableStream<services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectResponse>;

  addLandlordToTenantProject(
    request: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_landlord_to_tenant_service_pb.AddLandlordToTenantProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.LandlordToTenantService/AddLandlordToTenantProject',
        request,
        metadata || {},
        this.methodInfoAddLandlordToTenantProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.LandlordToTenantService/AddLandlordToTenantProject',
    request,
    metadata || {},
    this.methodInfoAddLandlordToTenantProject);
  }

}

