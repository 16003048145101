
import { Component, Vue, Watch } from "vue-property-decorator";
import TariffSearchResults from "./TariffSearchResults.vue";
import Savings from "./Savings.vue";
import SwitchDate from "./SwitchDate.vue";
import ProjectSelection from "./project-selection/ProjectSelection.vue";
import EnableWatchdog from "./EnableWatchdog.vue";
import PendingSwitchBanner from "./PendingSwitchBanner.vue";
import WatchdogService from "./tariff-switch-service-selection/WatchdogService.vue";
import { tariffModule } from "@/store/modules/tariff";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import projects from "@/store/modules/projects";
import tariff from "@/store/modules/tariff";
import NoSpace from "./NoSpace.vue";
import { spacesModule } from "@/store/modules/spaces";
import AddTariffReminder from "../dashboard/AddTariffReminder.vue";
import { subscriptionModule } from "@/store/modules/subscription";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { EnergyTariff, SearchResult, TariffSearchInfo, TariffSwitch } from "zaehlerfreunde-proto-types/tariff_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { paths } from "@/router/routes";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import DynamicTariffChange from "./DynamicTariffChange.vue";

@Component({
  components: {
    TariffSearchResults,
    Savings,
    SwitchDate,
    ProjectSelection,
    EnableWatchdog,
    PendingSwitchBanner,
    WatchdogService,
    NoSpace,
    AddTariffReminder,
    DynamicTariffChange,
  },
})
export default class TariffSwitchPage extends Vue {
  @spacesModule.State selectedSpace: Space | null;
  @spacesModule.State spaces: RemoteData<UserError, Space[]>;

  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff | null>;
  @tariffModule.Getter pendingTariffSwitch: RemoteData<UserError, TariffSwitch | null>;
  @tariffModule.Getter tariffSearchInfo: RemoteData<UserError, TariffSearchInfo | null>;
  @tariffModule.Getter tariffSearchResults: RemoteData<UserError, SearchResult[]>;

  @subscriptionModule.Getter currentPlan: SubscriptionPlan;

  @featuresModules.Getter enabledFeatures: Set<Feature>;

  @Watch("enabledFeatures")
  onEnabledFeaturesChanged() {
    if (!this.enabledFeatures.has(Feature.SHOP)) {
      this.$router.replace(paths.platform.dashboard);
    }
  }

  SubscriptionPlan = SubscriptionPlan;
  showEnableWatchdogServiceDialog: boolean = false;
  zfProtoDeviceMedium = Device.Medium;

  metaInfo(): { title: string } {
    return {
      title: "Tarifwechsel",
    };
  }

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    this.loadData();
  }

  beforeMount(): void {
    this.loadData();
  }

  loadData(): void {
    if (this.selectedSpace) {
      projects.getProjects();
      tariff.getCurrentEnergyTariff();
      tariff.getPendingTariffSwitch();
      tariff.getTariffSearchInfo();
      tariff.getTariffSearchResults();
    }
  }

  onEnableWatchdogService(): void {
    if (this.currentPlan === SubscriptionPlan.PRO) {
      this.showEnableWatchdogServiceDialog = true;
    } else {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "tariff-watchdog" },
      });
    }
  }
}
