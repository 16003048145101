
import { Component, Vue, Watch } from "vue-property-decorator";
import { RunMiniPVSimulationRequest } from "zaehlerfreunde-central/advisory_service_pb";
import simulations, { simulationModule } from "@/store/modules/simulations";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { tariffModule } from "@/store/modules/tariff";
import { EnergyTariff } from "zaehlerfreunde-proto-types/tariff_pb";
import devices, { devicesModule } from "@/store/modules/devices";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { paths } from "@/router/routes";
import { Mounting, MiniPVInstallation } from "zaehlerfreunde-proto-types/simulations_pb";

@Component({
  components: {},
})
export default class MIniPV extends Vue {
  @simulationModule.State simulationCall: RemoteCall<UserError>;
  @tariffModule.Getter currentEnergyTariff: RemoteData<UserError, EnergyTariff>;
  @devicesModule.Getter mainDevice: RemoteData<UserError, Device | null>;

  currentStepIndex = 0;
  orientation: number = 0;
  slope: number = 0;
  availableWidthInCM: string = "";
  orientationLabels: string[] = ["N", "NO", "O", "SO", "S", "SW", "W", "NW"];
  tiltLabels: string[] = ["0°", "15°", "30°", "45°", "60°", "75°", "90°"];
  miniPVsteps: string[] = ["mini-pv-info", "mini-pv-simulation-data", "simulation-parameter", "simulation"];
  mounting: Mounting | null = null;
  installationAbove4M: boolean = false;
  balcony: Mounting = Mounting.BALCONY;
  useDefaultLoadProfile: boolean = false;
  yearlyConsumption: number = 2000;

  mountingAnswers: { text: string; value: Mounting }[] = [
    { text: "Balkon", value: Mounting.BALCONY },
    { text: "Garten", value: Mounting.GARDEN },
    { text: "Fassade", value: Mounting.WALL },
    { text: "Schrägdach", value: Mounting.ROOF },
    { text: "Sonstiges", value: Mounting.ALL },
  ];

  heightAnswers: { text: string; value: boolean }[] = [
    { text: "Ja", value: true },
    { text: "Nein", value: false },
  ];

  get currentStep(): string {
    return this.miniPVsteps[this.currentStepIndex];
  }

  @Watch("mounting")
  onMountingSelected(): void {
    switch (this.mounting) {
      case Mounting.BALCONY:
        this.slope = 6;
        break;
      case Mounting.GARDEN:
        this.slope = 2;
        break;
      case Mounting.ROOF:
        this.slope = 2;
        break;
      case Mounting.WALL:
        this.slope = 3;
        break;
    }
  }

  isFlexibleTariff(): boolean {
    return this.currentEnergyTariff.data?.getIsVariable() ?? false;
  }

  onCloseButtonClicked(): void {
    this.$router.push(paths.platform.simulator);
  }

  onNextClicked(): void {
    if (this.currentStepIndex == 0) {
      if (this.mainDevice.data) {
        this.currentStepIndex += 2;
      } else {
        this.useDefaultLoadProfile = true;
        this.currentStepIndex += 1;
      }
    } else {
      this.currentStepIndex += 1;
    }
  }

  get infoCompleted(): boolean {
    return !!this.availableWidthInCM && this.mounting != null;
  }

  async startSimulation(): Promise<void> {
    if (this.mounting != null) {
      const request = new RunMiniPVSimulationRequest();
      const installation = new MiniPVInstallation();
      installation.setSlope(this.slope);
      installation.setMounting(this.mounting);
      installation.setOrientation(this.orientation);
      installation.setAvailableWidth(parseInt(this.availableWidthInCM, 10));
      installation.setInstallationAbove4mHeight(this.installationAbove4M);
      request.setInstallation(installation);
      request.setUseDefaultLoadProfile(this.useDefaultLoadProfile);
      if (this.useDefaultLoadProfile) {
        request.setYearlyConsumption(this.yearlyConsumption);
      }
      simulations.runMiniPVSimulation(request);
      this.currentStepIndex += 1;
    }
  }

  beforeMount(): void {
    devices.getMainDevice();
  }
}
