// source: types/tariff.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_datetime_pb = require('../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
goog.exportSymbol('proto.types.CharityProject', null, global);
goog.exportSymbol('proto.types.EnergyTariff', null, global);
goog.exportSymbol('proto.types.EnergyTariff.FixedOrVariableCase', null, global);
goog.exportSymbol('proto.types.EnergyTariff.FixedPriceDetails', null, global);
goog.exportSymbol('proto.types.EnergyTariff.Supplier', null, global);
goog.exportSymbol('proto.types.EnergyTariff.VariablePriceDetails', null, global);
goog.exportSymbol('proto.types.EnergyTariffs', null, global);
goog.exportSymbol('proto.types.GasTariff', null, global);
goog.exportSymbol('proto.types.GasTariffs', null, global);
goog.exportSymbol('proto.types.SearchResult', null, global);
goog.exportSymbol('proto.types.SearchResult.Certificate', null, global);
goog.exportSymbol('proto.types.SearchResult.Detail', null, global);
goog.exportSymbol('proto.types.SearchResult.Supplier', null, global);
goog.exportSymbol('proto.types.SepaDetails', null, global);
goog.exportSymbol('proto.types.TariffSearchInfo', null, global);
goog.exportSymbol('proto.types.TariffSearchInfo.LatestSearch', null, global);
goog.exportSymbol('proto.types.TariffSwitch', null, global);
goog.exportSymbol('proto.types.Tariffs', null, global);
goog.exportSymbol('proto.types.Tariffs.TariffsCase', null, global);
goog.exportSymbol('proto.types.WaterTariff', null, global);
goog.exportSymbol('proto.types.WaterTariffs', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EnergyTariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.EnergyTariff.oneofGroups_);
};
goog.inherits(proto.types.EnergyTariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EnergyTariff.displayName = 'proto.types.EnergyTariff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EnergyTariff.Supplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.EnergyTariff.Supplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EnergyTariff.Supplier.displayName = 'proto.types.EnergyTariff.Supplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EnergyTariff.FixedPriceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.EnergyTariff.FixedPriceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EnergyTariff.FixedPriceDetails.displayName = 'proto.types.EnergyTariff.FixedPriceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EnergyTariff.VariablePriceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.EnergyTariff.VariablePriceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EnergyTariff.VariablePriceDetails.displayName = 'proto.types.EnergyTariff.VariablePriceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.SearchResult.repeatedFields_, null);
};
goog.inherits(proto.types.SearchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.displayName = 'proto.types.SearchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult.Supplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SearchResult.Supplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.Supplier.displayName = 'proto.types.SearchResult.Supplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult.Certificate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SearchResult.Certificate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.Certificate.displayName = 'proto.types.SearchResult.Certificate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SearchResult.Detail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SearchResult.Detail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SearchResult.Detail.displayName = 'proto.types.SearchResult.Detail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TariffSwitch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TariffSwitch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TariffSwitch.displayName = 'proto.types.TariffSwitch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TariffSearchInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TariffSearchInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TariffSearchInfo.displayName = 'proto.types.TariffSearchInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TariffSearchInfo.LatestSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.TariffSearchInfo.LatestSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TariffSearchInfo.LatestSearch.displayName = 'proto.types.TariffSearchInfo.LatestSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.SepaDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.SepaDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.SepaDetails.displayName = 'proto.types.SepaDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.CharityProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.CharityProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.CharityProject.displayName = 'proto.types.CharityProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.GasTariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.GasTariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.GasTariff.displayName = 'proto.types.GasTariff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.WaterTariff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.WaterTariff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.WaterTariff.displayName = 'proto.types.WaterTariff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Tariffs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.Tariffs.oneofGroups_);
};
goog.inherits(proto.types.Tariffs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Tariffs.displayName = 'proto.types.Tariffs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.EnergyTariffs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.EnergyTariffs.repeatedFields_, null);
};
goog.inherits(proto.types.EnergyTariffs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.EnergyTariffs.displayName = 'proto.types.EnergyTariffs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.WaterTariffs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.WaterTariffs.repeatedFields_, null);
};
goog.inherits(proto.types.WaterTariffs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.WaterTariffs.displayName = 'proto.types.WaterTariffs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.GasTariffs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.GasTariffs.repeatedFields_, null);
};
goog.inherits(proto.types.GasTariffs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.GasTariffs.displayName = 'proto.types.GasTariffs';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.EnergyTariff.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.types.EnergyTariff.FixedOrVariableCase = {
  FIXED_OR_VARIABLE_NOT_SET: 0,
  FIXED_PRICE_DETAILS: 5,
  VARIABLE_PRICE_DETAILS: 6
};

/**
 * @return {proto.types.EnergyTariff.FixedOrVariableCase}
 */
proto.types.EnergyTariff.prototype.getFixedOrVariableCase = function() {
  return /** @type {proto.types.EnergyTariff.FixedOrVariableCase} */(jspb.Message.computeOneofCase(this, proto.types.EnergyTariff.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EnergyTariff.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EnergyTariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EnergyTariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldStartDate: (f = msg.getOldStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    supplier: (f = msg.getSupplier()) && proto.types.EnergyTariff.Supplier.toObject(includeInstance, f),
    isVariable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    fixedPriceDetails: (f = msg.getFixedPriceDetails()) && proto.types.EnergyTariff.FixedPriceDetails.toObject(includeInstance, f),
    variablePriceDetails: (f = msg.getVariablePriceDetails()) && proto.types.EnergyTariff.VariablePriceDetails.toObject(includeInstance, f),
    basePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    kgCo2PerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    isGreen: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    oldEarliestCancelDate: (f = msg.getOldEarliestCancelDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    monthlyAdvancePayment: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    startDate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    earliestCancelDate: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EnergyTariff}
 */
proto.types.EnergyTariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EnergyTariff;
  return proto.types.EnergyTariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EnergyTariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EnergyTariff}
 */
proto.types.EnergyTariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setOldStartDate(value);
      break;
    case 3:
      var value = new proto.types.EnergyTariff.Supplier;
      reader.readMessage(value,proto.types.EnergyTariff.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVariable(value);
      break;
    case 5:
      var value = new proto.types.EnergyTariff.FixedPriceDetails;
      reader.readMessage(value,proto.types.EnergyTariff.FixedPriceDetails.deserializeBinaryFromReader);
      msg.setFixedPriceDetails(value);
      break;
    case 6:
      var value = new proto.types.EnergyTariff.VariablePriceDetails;
      reader.readMessage(value,proto.types.EnergyTariff.VariablePriceDetails.deserializeBinaryFromReader);
      msg.setVariablePriceDetails(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasePrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKgCo2PerKwh(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGreen(value);
      break;
    case 10:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setOldEarliestCancelDate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthlyAdvancePayment(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEarliestCancelDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EnergyTariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EnergyTariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EnergyTariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.EnergyTariff.Supplier.serializeBinaryToWriter
    );
  }
  f = message.getIsVariable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFixedPriceDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.types.EnergyTariff.FixedPriceDetails.serializeBinaryToWriter
    );
  }
  f = message.getVariablePriceDetails();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.types.EnergyTariff.VariablePriceDetails.serializeBinaryToWriter
    );
  }
  f = message.getBasePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getIsGreen();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOldEarliestCancelDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEarliestCancelDate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EnergyTariff.Supplier.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EnergyTariff.Supplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EnergyTariff.Supplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.Supplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EnergyTariff.Supplier}
 */
proto.types.EnergyTariff.Supplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EnergyTariff.Supplier;
  return proto.types.EnergyTariff.Supplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EnergyTariff.Supplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EnergyTariff.Supplier}
 */
proto.types.EnergyTariff.Supplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EnergyTariff.Supplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EnergyTariff.Supplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EnergyTariff.Supplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.Supplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.EnergyTariff.Supplier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnergyTariff.Supplier} returns this
 */
proto.types.EnergyTariff.Supplier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.types.EnergyTariff.Supplier.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnergyTariff.Supplier} returns this
 */
proto.types.EnergyTariff.Supplier.prototype.setCustomerId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnergyTariff.Supplier} returns this
 */
proto.types.EnergyTariff.Supplier.prototype.clearCustomerId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.Supplier.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EnergyTariff.FixedPriceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EnergyTariff.FixedPriceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EnergyTariff.FixedPriceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.FixedPriceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EnergyTariff.FixedPriceDetails}
 */
proto.types.EnergyTariff.FixedPriceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EnergyTariff.FixedPriceDetails;
  return proto.types.EnergyTariff.FixedPriceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EnergyTariff.FixedPriceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EnergyTariff.FixedPriceDetails}
 */
proto.types.EnergyTariff.FixedPriceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerKwh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EnergyTariff.FixedPriceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EnergyTariff.FixedPriceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EnergyTariff.FixedPriceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.FixedPriceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricePerKwh();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double price_per_kwh = 1;
 * @return {number}
 */
proto.types.EnergyTariff.FixedPriceDetails.prototype.getPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff.FixedPriceDetails} returns this
 */
proto.types.EnergyTariff.FixedPriceDetails.prototype.setPricePerKwh = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EnergyTariff.VariablePriceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EnergyTariff.VariablePriceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.VariablePriceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    surchargePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maxPricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    minPricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EnergyTariff.VariablePriceDetails}
 */
proto.types.EnergyTariff.VariablePriceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EnergyTariff.VariablePriceDetails;
  return proto.types.EnergyTariff.VariablePriceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EnergyTariff.VariablePriceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EnergyTariff.VariablePriceDetails}
 */
proto.types.EnergyTariff.VariablePriceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurchargePerKwh(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPricePerKwh(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinPricePerKwh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EnergyTariff.VariablePriceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EnergyTariff.VariablePriceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariff.VariablePriceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurchargePerKwh();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double surcharge_per_kwh = 1;
 * @return {number}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.getSurchargePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff.VariablePriceDetails} returns this
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.setSurchargePerKwh = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double max_price_per_kwh = 3;
 * @return {number}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.getMaxPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff.VariablePriceDetails} returns this
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.setMaxPricePerKwh = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnergyTariff.VariablePriceDetails} returns this
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.clearMaxPricePerKwh = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.hasMaxPricePerKwh = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double min_price_per_kwh = 4;
 * @return {number}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.getMinPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff.VariablePriceDetails} returns this
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.setMinPricePerKwh = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnergyTariff.VariablePriceDetails} returns this
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.clearMinPricePerKwh = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.VariablePriceDetails.prototype.hasMinPricePerKwh = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.EnergyTariff.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.DateTime old_start_date = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.types.EnergyTariff.prototype.getOldStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.types.EnergyTariff} returns this
*/
proto.types.EnergyTariff.prototype.setOldStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearOldStartDate = function() {
  return this.setOldStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasOldStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Supplier supplier = 3;
 * @return {?proto.types.EnergyTariff.Supplier}
 */
proto.types.EnergyTariff.prototype.getSupplier = function() {
  return /** @type{?proto.types.EnergyTariff.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.types.EnergyTariff.Supplier, 3));
};


/**
 * @param {?proto.types.EnergyTariff.Supplier|undefined} value
 * @return {!proto.types.EnergyTariff} returns this
*/
proto.types.EnergyTariff.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_variable = 4;
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.getIsVariable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setIsVariable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional FixedPriceDetails fixed_price_details = 5;
 * @return {?proto.types.EnergyTariff.FixedPriceDetails}
 */
proto.types.EnergyTariff.prototype.getFixedPriceDetails = function() {
  return /** @type{?proto.types.EnergyTariff.FixedPriceDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.EnergyTariff.FixedPriceDetails, 5));
};


/**
 * @param {?proto.types.EnergyTariff.FixedPriceDetails|undefined} value
 * @return {!proto.types.EnergyTariff} returns this
*/
proto.types.EnergyTariff.prototype.setFixedPriceDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.types.EnergyTariff.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearFixedPriceDetails = function() {
  return this.setFixedPriceDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasFixedPriceDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional VariablePriceDetails variable_price_details = 6;
 * @return {?proto.types.EnergyTariff.VariablePriceDetails}
 */
proto.types.EnergyTariff.prototype.getVariablePriceDetails = function() {
  return /** @type{?proto.types.EnergyTariff.VariablePriceDetails} */ (
    jspb.Message.getWrapperField(this, proto.types.EnergyTariff.VariablePriceDetails, 6));
};


/**
 * @param {?proto.types.EnergyTariff.VariablePriceDetails|undefined} value
 * @return {!proto.types.EnergyTariff} returns this
*/
proto.types.EnergyTariff.prototype.setVariablePriceDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.types.EnergyTariff.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearVariablePriceDetails = function() {
  return this.setVariablePriceDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasVariablePriceDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double base_price = 7;
 * @return {number}
 */
proto.types.EnergyTariff.prototype.getBasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setBasePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double kg_co2_per_kwh = 8;
 * @return {number}
 */
proto.types.EnergyTariff.prototype.getKgCo2PerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setKgCo2PerKwh = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearKgCo2PerKwh = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasKgCo2PerKwh = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_green = 9;
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.getIsGreen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setIsGreen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.type.DateTime old_earliest_cancel_date = 10;
 * @return {?proto.google.type.DateTime}
 */
proto.types.EnergyTariff.prototype.getOldEarliestCancelDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 10));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.types.EnergyTariff} returns this
*/
proto.types.EnergyTariff.prototype.setOldEarliestCancelDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearOldEarliestCancelDate = function() {
  return this.setOldEarliestCancelDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasOldEarliestCancelDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double monthly_advance_payment = 11;
 * @return {number}
 */
proto.types.EnergyTariff.prototype.getMonthlyAdvancePayment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setMonthlyAdvancePayment = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearMonthlyAdvancePayment = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasMonthlyAdvancePayment = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string start_date = 12;
 * @return {string}
 */
proto.types.EnergyTariff.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.EnergyTariff.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string earliest_cancel_date = 13;
 * @return {string}
 */
proto.types.EnergyTariff.prototype.getEarliestCancelDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.EnergyTariff} returns this
 */
proto.types.EnergyTariff.prototype.setEarliestCancelDate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.SearchResult.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: (f = msg.getSupplier()) && proto.types.SearchResult.Supplier.toObject(includeInstance, f),
    pricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    basePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    priceFirstYear: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    savings: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    certificatesList: jspb.Message.toObjectList(msg.getCertificatesList(),
    proto.types.SearchResult.Certificate.toObject, includeInstance),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.types.SearchResult.Detail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult}
 */
proto.types.SearchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult;
  return proto.types.SearchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult}
 */
proto.types.SearchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTariffName(value);
      break;
    case 2:
      var value = new proto.types.SearchResult.Supplier;
      reader.readMessage(value,proto.types.SearchResult.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerKwh(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasePrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceFirstYear(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSavings(value);
      break;
    case 7:
      var value = new proto.types.SearchResult.Certificate;
      reader.readMessage(value,proto.types.SearchResult.Certificate.deserializeBinaryFromReader);
      msg.addCertificates(value);
      break;
    case 8:
      var value = new proto.types.SearchResult.Detail;
      reader.readMessage(value,proto.types.SearchResult.Detail.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.SearchResult.Supplier.serializeBinaryToWriter
    );
  }
  f = message.getPricePerKwh();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBasePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPriceFirstYear();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSavings();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.types.SearchResult.Certificate.serializeBinaryToWriter
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.types.SearchResult.Detail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.Supplier.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.Supplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult.Supplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Supplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult.Supplier}
 */
proto.types.SearchResult.Supplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult.Supplier;
  return proto.types.SearchResult.Supplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult.Supplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult.Supplier}
 */
proto.types.SearchResult.Supplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.Supplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.Supplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult.Supplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Supplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.SearchResult.Supplier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.types.SearchResult.Supplier.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.setLink = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.clearLink = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.Supplier.prototype.hasLink = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.types.SearchResult.Supplier.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.setLogoUrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SearchResult.Supplier} returns this
 */
proto.types.SearchResult.Supplier.prototype.clearLogoUrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.Supplier.prototype.hasLogoUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.Certificate.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.Certificate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult.Certificate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Certificate.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    getagId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult.Certificate}
 */
proto.types.SearchResult.Certificate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult.Certificate;
  return proto.types.SearchResult.Certificate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult.Certificate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult.Certificate}
 */
proto.types.SearchResult.Certificate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGetagId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.Certificate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.Certificate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult.Certificate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Certificate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGetagId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.types.SearchResult.Certificate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo = 2;
 * @return {string}
 */
proto.types.SearchResult.Certificate.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.setLogo = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.clearLogo = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.Certificate.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 getag_id = 3;
 * @return {number}
 */
proto.types.SearchResult.Certificate.prototype.getGetagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult.Certificate} returns this
 */
proto.types.SearchResult.Certificate.prototype.setGetagId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SearchResult.Detail.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SearchResult.Detail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SearchResult.Detail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Detail.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SearchResult.Detail}
 */
proto.types.SearchResult.Detail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SearchResult.Detail;
  return proto.types.SearchResult.Detail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SearchResult.Detail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SearchResult.Detail}
 */
proto.types.SearchResult.Detail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SearchResult.Detail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SearchResult.Detail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SearchResult.Detail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SearchResult.Detail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.types.SearchResult.Detail.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Detail} returns this
 */
proto.types.SearchResult.Detail.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.types.SearchResult.Detail.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult.Detail} returns this
 */
proto.types.SearchResult.Detail.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tariff_name = 1;
 * @return {string}
 */
proto.types.SearchResult.prototype.getTariffName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setTariffName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Supplier supplier = 2;
 * @return {?proto.types.SearchResult.Supplier}
 */
proto.types.SearchResult.prototype.getSupplier = function() {
  return /** @type{?proto.types.SearchResult.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.types.SearchResult.Supplier, 2));
};


/**
 * @param {?proto.types.SearchResult.Supplier|undefined} value
 * @return {!proto.types.SearchResult} returns this
*/
proto.types.SearchResult.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SearchResult.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double price_per_kwh = 3;
 * @return {number}
 */
proto.types.SearchResult.prototype.getPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setPricePerKwh = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double base_price = 4;
 * @return {number}
 */
proto.types.SearchResult.prototype.getBasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setBasePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double price_first_year = 5;
 * @return {number}
 */
proto.types.SearchResult.prototype.getPriceFirstYear = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setPriceFirstYear = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double savings = 6;
 * @return {number}
 */
proto.types.SearchResult.prototype.getSavings = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.setSavings = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * repeated Certificate certificates = 7;
 * @return {!Array<!proto.types.SearchResult.Certificate>}
 */
proto.types.SearchResult.prototype.getCertificatesList = function() {
  return /** @type{!Array<!proto.types.SearchResult.Certificate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.SearchResult.Certificate, 7));
};


/**
 * @param {!Array<!proto.types.SearchResult.Certificate>} value
 * @return {!proto.types.SearchResult} returns this
*/
proto.types.SearchResult.prototype.setCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.types.SearchResult.Certificate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SearchResult.Certificate}
 */
proto.types.SearchResult.prototype.addCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.types.SearchResult.Certificate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.clearCertificatesList = function() {
  return this.setCertificatesList([]);
};


/**
 * repeated Detail details = 8;
 * @return {!Array<!proto.types.SearchResult.Detail>}
 */
proto.types.SearchResult.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.types.SearchResult.Detail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.SearchResult.Detail, 8));
};


/**
 * @param {!Array<!proto.types.SearchResult.Detail>} value
 * @return {!proto.types.SearchResult} returns this
*/
proto.types.SearchResult.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.types.SearchResult.Detail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.SearchResult.Detail}
 */
proto.types.SearchResult.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.types.SearchResult.Detail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.SearchResult} returns this
 */
proto.types.SearchResult.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TariffSwitch.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TariffSwitch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TariffSwitch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSwitch.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchResult: (f = msg.getSearchResult()) && proto.types.SearchResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TariffSwitch}
 */
proto.types.TariffSwitch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TariffSwitch;
  return proto.types.TariffSwitch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TariffSwitch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TariffSwitch}
 */
proto.types.TariffSwitch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.SearchResult;
      reader.readMessage(value,proto.types.SearchResult.deserializeBinaryFromReader);
      msg.setSearchResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TariffSwitch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TariffSwitch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TariffSwitch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSwitch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.types.SearchResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchResult search_result = 1;
 * @return {?proto.types.SearchResult}
 */
proto.types.TariffSwitch.prototype.getSearchResult = function() {
  return /** @type{?proto.types.SearchResult} */ (
    jspb.Message.getWrapperField(this, proto.types.SearchResult, 1));
};


/**
 * @param {?proto.types.SearchResult|undefined} value
 * @return {!proto.types.TariffSwitch} returns this
*/
proto.types.TariffSwitch.prototype.setSearchResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TariffSwitch} returns this
 */
proto.types.TariffSwitch.prototype.clearSearchResult = function() {
  return this.setSearchResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TariffSwitch.prototype.hasSearchResult = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TariffSearchInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TariffSearchInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TariffSearchInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isWatchdogEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    latestSearch: (f = msg.getLatestSearch()) && proto.types.TariffSearchInfo.LatestSearch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TariffSearchInfo}
 */
proto.types.TariffSearchInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TariffSearchInfo;
  return proto.types.TariffSearchInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TariffSearchInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TariffSearchInfo}
 */
proto.types.TariffSearchInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWatchdogEnabled(value);
      break;
    case 2:
      var value = new proto.types.TariffSearchInfo.LatestSearch;
      reader.readMessage(value,proto.types.TariffSearchInfo.LatestSearch.deserializeBinaryFromReader);
      msg.setLatestSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TariffSearchInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TariffSearchInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TariffSearchInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsWatchdogEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLatestSearch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.TariffSearchInfo.LatestSearch.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TariffSearchInfo.LatestSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TariffSearchInfo.LatestSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.LatestSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    postcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consumption: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currentCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TariffSearchInfo.LatestSearch}
 */
proto.types.TariffSearchInfo.LatestSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TariffSearchInfo.LatestSearch;
  return proto.types.TariffSearchInfo.LatestSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TariffSearchInfo.LatestSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TariffSearchInfo.LatestSearch}
 */
proto.types.TariffSearchInfo.LatestSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConsumption(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentCost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TariffSearchInfo.LatestSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TariffSearchInfo.LatestSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TariffSearchInfo.LatestSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsumption();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrentCost();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string postcode = 1;
 * @return {string}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double consumption = 2;
 * @return {number}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getConsumption = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setConsumption = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double current_cost = 3;
 * @return {number}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getCurrentCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setCurrentCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {number}
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.TariffSearchInfo.LatestSearch} returns this
 */
proto.types.TariffSearchInfo.LatestSearch.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_watchdog_enabled = 1;
 * @return {boolean}
 */
proto.types.TariffSearchInfo.prototype.getIsWatchdogEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.TariffSearchInfo} returns this
 */
proto.types.TariffSearchInfo.prototype.setIsWatchdogEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional LatestSearch latest_search = 2;
 * @return {?proto.types.TariffSearchInfo.LatestSearch}
 */
proto.types.TariffSearchInfo.prototype.getLatestSearch = function() {
  return /** @type{?proto.types.TariffSearchInfo.LatestSearch} */ (
    jspb.Message.getWrapperField(this, proto.types.TariffSearchInfo.LatestSearch, 2));
};


/**
 * @param {?proto.types.TariffSearchInfo.LatestSearch|undefined} value
 * @return {!proto.types.TariffSearchInfo} returns this
*/
proto.types.TariffSearchInfo.prototype.setLatestSearch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TariffSearchInfo} returns this
 */
proto.types.TariffSearchInfo.prototype.clearLatestSearch = function() {
  return this.setLatestSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TariffSearchInfo.prototype.hasLatestSearch = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.SepaDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.types.SepaDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.SepaDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SepaDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountHolder: jspb.Message.getFieldWithDefault(msg, 1, ""),
    street: jspb.Message.getFieldWithDefault(msg, 2, ""),
    streetNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    iban: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bic: jspb.Message.getFieldWithDefault(msg, 7, ""),
    birthDate: (f = msg.getBirthDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.SepaDetails}
 */
proto.types.SepaDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.SepaDetails;
  return proto.types.SepaDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.SepaDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.SepaDetails}
 */
proto.types.SepaDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountHolder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIban(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBic(value);
      break;
    case 8:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setBirthDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.SepaDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.SepaDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.SepaDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.SepaDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountHolder();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStreetNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIban();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBic();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBirthDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_holder = 1;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getAccountHolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setAccountHolder = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string street = 2;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string street_no = 3;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getStreetNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setStreetNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string postcode = 4;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string iban = 6;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getIban = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setIban = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bic = 7;
 * @return {string}
 */
proto.types.SepaDetails.prototype.getBic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.setBic = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.DateTime birth_date = 8;
 * @return {?proto.google.type.DateTime}
 */
proto.types.SepaDetails.prototype.getBirthDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 8));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.types.SepaDetails} returns this
*/
proto.types.SepaDetails.prototype.setBirthDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.SepaDetails} returns this
 */
proto.types.SepaDetails.prototype.clearBirthDate = function() {
  return this.setBirthDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.SepaDetails.prototype.hasBirthDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.CharityProject.prototype.toObject = function(opt_includeInstance) {
  return proto.types.CharityProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.CharityProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.CharityProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partner: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    image: jspb.Message.getFieldWithDefault(msg, 5, ""),
    moneyNeeded: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    moneyRaised: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    link: jspb.Message.getFieldWithDefault(msg, 8, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.CharityProject}
 */
proto.types.CharityProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.CharityProject;
  return proto.types.CharityProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.CharityProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.CharityProject}
 */
proto.types.CharityProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoneyNeeded(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoneyRaised(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.CharityProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.CharityProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.CharityProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.CharityProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMoneyNeeded();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMoneyRaised();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.CharityProject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partner = 2;
 * @return {string}
 */
proto.types.CharityProject.prototype.getPartner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setPartner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.types.CharityProject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.types.CharityProject.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image = 5;
 * @return {string}
 */
proto.types.CharityProject.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float money_needed = 6;
 * @return {number}
 */
proto.types.CharityProject.prototype.getMoneyNeeded = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setMoneyNeeded = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float money_raised = 7;
 * @return {number}
 */
proto.types.CharityProject.prototype.getMoneyRaised = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setMoneyRaised = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string link = 8;
 * @return {string}
 */
proto.types.CharityProject.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool selected = 9;
 * @return {boolean}
 */
proto.types.CharityProject.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.CharityProject} returns this
 */
proto.types.CharityProject.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.GasTariff.prototype.toObject = function(opt_includeInstance) {
  return proto.types.GasTariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.GasTariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GasTariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldStartDate: (f = msg.getOldStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    supplierName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pricePerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    basePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    isGreen: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    energyValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    correctionFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    kgCo2PerKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    startDate: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.GasTariff}
 */
proto.types.GasTariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.GasTariff;
  return proto.types.GasTariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.GasTariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.GasTariff}
 */
proto.types.GasTariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setOldStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerKwh(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasePrice(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGreen(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnergyValue(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCorrectionFactor(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKgCo2PerKwh(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.GasTariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.GasTariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.GasTariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GasTariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPricePerKwh();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBasePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getIsGreen();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getEnergyValue();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCorrectionFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.GasTariff.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.DateTime old_start_date = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.types.GasTariff.prototype.getOldStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.types.GasTariff} returns this
*/
proto.types.GasTariff.prototype.setOldStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.clearOldStartDate = function() {
  return this.setOldStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.GasTariff.prototype.hasOldStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string supplier_name = 3;
 * @return {string}
 */
proto.types.GasTariff.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price_per_kwh = 4;
 * @return {number}
 */
proto.types.GasTariff.prototype.getPricePerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setPricePerKwh = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double base_price = 5;
 * @return {number}
 */
proto.types.GasTariff.prototype.getBasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setBasePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool is_green = 6;
 * @return {boolean}
 */
proto.types.GasTariff.prototype.getIsGreen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setIsGreen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional double energy_value = 7;
 * @return {number}
 */
proto.types.GasTariff.prototype.getEnergyValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setEnergyValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double correction_factor = 8;
 * @return {number}
 */
proto.types.GasTariff.prototype.getCorrectionFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setCorrectionFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double kg_co2_per_kwh = 9;
 * @return {number}
 */
proto.types.GasTariff.prototype.getKgCo2PerKwh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setKgCo2PerKwh = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.clearKgCo2PerKwh = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.GasTariff.prototype.hasKgCo2PerKwh = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string start_date = 10;
 * @return {string}
 */
proto.types.GasTariff.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.GasTariff} returns this
 */
proto.types.GasTariff.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.GasTariff.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.WaterTariff.prototype.toObject = function(opt_includeInstance) {
  return proto.types.WaterTariff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.WaterTariff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WaterTariff.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldStartDate: (f = msg.getOldStartDate()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    supplierName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pricePerM3: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    basePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    startDate: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.WaterTariff}
 */
proto.types.WaterTariff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.WaterTariff;
  return proto.types.WaterTariff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.WaterTariff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.WaterTariff}
 */
proto.types.WaterTariff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setOldStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerM3(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBasePrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.WaterTariff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.WaterTariff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.WaterTariff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WaterTariff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPricePerM3();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBasePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.WaterTariff.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.DateTime old_start_date = 2;
 * @return {?proto.google.type.DateTime}
 */
proto.types.WaterTariff.prototype.getOldStartDate = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 2));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.types.WaterTariff} returns this
*/
proto.types.WaterTariff.prototype.setOldStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.clearOldStartDate = function() {
  return this.setOldStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.WaterTariff.prototype.hasOldStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string supplier_name = 3;
 * @return {string}
 */
proto.types.WaterTariff.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price_per_m3 = 4;
 * @return {number}
 */
proto.types.WaterTariff.prototype.getPricePerM3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.setPricePerM3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double base_price = 5;
 * @return {number}
 */
proto.types.WaterTariff.prototype.getBasePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.setBasePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string start_date = 6;
 * @return {string}
 */
proto.types.WaterTariff.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.WaterTariff} returns this
 */
proto.types.WaterTariff.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.WaterTariff.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.Tariffs.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.types.Tariffs.TariffsCase = {
  TARIFFS_NOT_SET: 0,
  ENERGY: 1,
  WATER: 2,
  GAS: 3
};

/**
 * @return {proto.types.Tariffs.TariffsCase}
 */
proto.types.Tariffs.prototype.getTariffsCase = function() {
  return /** @type {proto.types.Tariffs.TariffsCase} */(jspb.Message.computeOneofCase(this, proto.types.Tariffs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Tariffs.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Tariffs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Tariffs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariffs.toObject = function(includeInstance, msg) {
  var f, obj = {
    energy: (f = msg.getEnergy()) && proto.types.EnergyTariffs.toObject(includeInstance, f),
    water: (f = msg.getWater()) && proto.types.WaterTariffs.toObject(includeInstance, f),
    gas: (f = msg.getGas()) && proto.types.GasTariffs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Tariffs}
 */
proto.types.Tariffs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Tariffs;
  return proto.types.Tariffs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Tariffs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Tariffs}
 */
proto.types.Tariffs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.EnergyTariffs;
      reader.readMessage(value,proto.types.EnergyTariffs.deserializeBinaryFromReader);
      msg.setEnergy(value);
      break;
    case 2:
      var value = new proto.types.WaterTariffs;
      reader.readMessage(value,proto.types.WaterTariffs.deserializeBinaryFromReader);
      msg.setWater(value);
      break;
    case 3:
      var value = new proto.types.GasTariffs;
      reader.readMessage(value,proto.types.GasTariffs.deserializeBinaryFromReader);
      msg.setGas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Tariffs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Tariffs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Tariffs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Tariffs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.types.EnergyTariffs.serializeBinaryToWriter
    );
  }
  f = message.getWater();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.WaterTariffs.serializeBinaryToWriter
    );
  }
  f = message.getGas();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.GasTariffs.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnergyTariffs energy = 1;
 * @return {?proto.types.EnergyTariffs}
 */
proto.types.Tariffs.prototype.getEnergy = function() {
  return /** @type{?proto.types.EnergyTariffs} */ (
    jspb.Message.getWrapperField(this, proto.types.EnergyTariffs, 1));
};


/**
 * @param {?proto.types.EnergyTariffs|undefined} value
 * @return {!proto.types.Tariffs} returns this
*/
proto.types.Tariffs.prototype.setEnergy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.types.Tariffs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Tariffs} returns this
 */
proto.types.Tariffs.prototype.clearEnergy = function() {
  return this.setEnergy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariffs.prototype.hasEnergy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WaterTariffs water = 2;
 * @return {?proto.types.WaterTariffs}
 */
proto.types.Tariffs.prototype.getWater = function() {
  return /** @type{?proto.types.WaterTariffs} */ (
    jspb.Message.getWrapperField(this, proto.types.WaterTariffs, 2));
};


/**
 * @param {?proto.types.WaterTariffs|undefined} value
 * @return {!proto.types.Tariffs} returns this
*/
proto.types.Tariffs.prototype.setWater = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.types.Tariffs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Tariffs} returns this
 */
proto.types.Tariffs.prototype.clearWater = function() {
  return this.setWater(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariffs.prototype.hasWater = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GasTariffs gas = 3;
 * @return {?proto.types.GasTariffs}
 */
proto.types.Tariffs.prototype.getGas = function() {
  return /** @type{?proto.types.GasTariffs} */ (
    jspb.Message.getWrapperField(this, proto.types.GasTariffs, 3));
};


/**
 * @param {?proto.types.GasTariffs|undefined} value
 * @return {!proto.types.Tariffs} returns this
*/
proto.types.Tariffs.prototype.setGas = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.types.Tariffs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.Tariffs} returns this
 */
proto.types.Tariffs.prototype.clearGas = function() {
  return this.setGas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Tariffs.prototype.hasGas = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.EnergyTariffs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.EnergyTariffs.prototype.toObject = function(opt_includeInstance) {
  return proto.types.EnergyTariffs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.EnergyTariffs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariffs.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffsList: jspb.Message.toObjectList(msg.getTariffsList(),
    proto.types.EnergyTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.EnergyTariffs}
 */
proto.types.EnergyTariffs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.EnergyTariffs;
  return proto.types.EnergyTariffs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.EnergyTariffs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.EnergyTariffs}
 */
proto.types.EnergyTariffs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.EnergyTariff;
      reader.readMessage(value,proto.types.EnergyTariff.deserializeBinaryFromReader);
      msg.addTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.EnergyTariffs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.EnergyTariffs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.EnergyTariffs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.EnergyTariffs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.EnergyTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnergyTariff tariffs = 1;
 * @return {!Array<!proto.types.EnergyTariff>}
 */
proto.types.EnergyTariffs.prototype.getTariffsList = function() {
  return /** @type{!Array<!proto.types.EnergyTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.EnergyTariff, 1));
};


/**
 * @param {!Array<!proto.types.EnergyTariff>} value
 * @return {!proto.types.EnergyTariffs} returns this
*/
proto.types.EnergyTariffs.prototype.setTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.EnergyTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.EnergyTariff}
 */
proto.types.EnergyTariffs.prototype.addTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.EnergyTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.EnergyTariffs} returns this
 */
proto.types.EnergyTariffs.prototype.clearTariffsList = function() {
  return this.setTariffsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.WaterTariffs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.WaterTariffs.prototype.toObject = function(opt_includeInstance) {
  return proto.types.WaterTariffs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.WaterTariffs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WaterTariffs.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffsList: jspb.Message.toObjectList(msg.getTariffsList(),
    proto.types.WaterTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.WaterTariffs}
 */
proto.types.WaterTariffs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.WaterTariffs;
  return proto.types.WaterTariffs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.WaterTariffs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.WaterTariffs}
 */
proto.types.WaterTariffs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.WaterTariff;
      reader.readMessage(value,proto.types.WaterTariff.deserializeBinaryFromReader);
      msg.addTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.WaterTariffs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.WaterTariffs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.WaterTariffs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.WaterTariffs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.WaterTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WaterTariff tariffs = 1;
 * @return {!Array<!proto.types.WaterTariff>}
 */
proto.types.WaterTariffs.prototype.getTariffsList = function() {
  return /** @type{!Array<!proto.types.WaterTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.WaterTariff, 1));
};


/**
 * @param {!Array<!proto.types.WaterTariff>} value
 * @return {!proto.types.WaterTariffs} returns this
*/
proto.types.WaterTariffs.prototype.setTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.WaterTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.WaterTariff}
 */
proto.types.WaterTariffs.prototype.addTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.WaterTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.WaterTariffs} returns this
 */
proto.types.WaterTariffs.prototype.clearTariffsList = function() {
  return this.setTariffsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.GasTariffs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.GasTariffs.prototype.toObject = function(opt_includeInstance) {
  return proto.types.GasTariffs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.GasTariffs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GasTariffs.toObject = function(includeInstance, msg) {
  var f, obj = {
    tariffsList: jspb.Message.toObjectList(msg.getTariffsList(),
    proto.types.GasTariff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.GasTariffs}
 */
proto.types.GasTariffs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.GasTariffs;
  return proto.types.GasTariffs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.GasTariffs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.GasTariffs}
 */
proto.types.GasTariffs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.GasTariff;
      reader.readMessage(value,proto.types.GasTariff.deserializeBinaryFromReader);
      msg.addTariffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.GasTariffs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.GasTariffs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.GasTariffs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.GasTariffs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTariffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.GasTariff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GasTariff tariffs = 1;
 * @return {!Array<!proto.types.GasTariff>}
 */
proto.types.GasTariffs.prototype.getTariffsList = function() {
  return /** @type{!Array<!proto.types.GasTariff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.GasTariff, 1));
};


/**
 * @param {!Array<!proto.types.GasTariff>} value
 * @return {!proto.types.GasTariffs} returns this
*/
proto.types.GasTariffs.prototype.setTariffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.GasTariff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.GasTariff}
 */
proto.types.GasTariffs.prototype.addTariffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.GasTariff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.GasTariffs} returns this
 */
proto.types.GasTariffs.prototype.clearTariffsList = function() {
  return this.setTariffsList([]);
};


goog.object.extend(exports, proto.types);
