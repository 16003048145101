import {
  EnergyTariff,
  GasTariff,
  SearchResult,
  TariffSearchInfo,
  TariffSwitch,
  WaterTariff,
} from "zaehlerfreunde-proto-types/tariff_pb";
import {
  GetCurrentTariffRequest,
  GetEstimatedConsumptionRequest,
  GetPendingSwitchRequest,
  GetSearchInfoRequest,
  GetSearchResultsRequest,
  GetTariffsRequest,
  GetTariffsResponse,
} from "zaehlerfreunde-central/tariff_service_pb";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import spaces from "@/store/modules/spaces";
import store from "@/store";
import { failure, initialized, pending, RemoteData, success } from "../utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { SpaceData } from "../utils/space-data";
import { namespace } from "vuex-class";
import { tariffServiceClient } from "@/config/service-clients";
import { Device } from "zaehlerfreunde-proto-types/device_pb";

@Module({
  namespaced: true,
  dynamic: true,
  store: store,
  name: "tariff",
})
class TariffModule extends VuexModule {
  currentEnergyTariffBySpaceId: SpaceData<RemoteData<UserError, EnergyTariff | null>> = {};
  energyTariffsBySpaceId: SpaceData<RemoteData<UserError, EnergyTariff[]>> = {};
  gasTariffsBySpaceId: SpaceData<RemoteData<UserError, GasTariff[]>> = {};
  waterTariffsBySpaceId: SpaceData<RemoteData<UserError, WaterTariff[]>> = {};
  tariffSearchInfoBySpaceId: SpaceData<RemoteData<UserError, TariffSearchInfo | null>> = {};
  pendingTariffSwitchBySpaceId: SpaceData<RemoteData<UserError, TariffSwitch | null>> = {};
  tariffSearchResultsBySpaceId: SpaceData<RemoteData<UserError, SearchResult[]>> = {};
  estimatedConsumptionBySpaceId: SpaceData<RemoteData<UserError, number>> = {};

  tariffsBySpaceId: SpaceData<RemoteData<UserError, GetTariffsResponse | null>> = {};

  get currentEnergyTariff(): RemoteData<UserError, EnergyTariff | null> {
    return this.currentEnergyTariffBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get energyTariffs(): RemoteData<UserError, EnergyTariff[]> {
    return this.energyTariffsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get gasTariffs(): RemoteData<UserError, GasTariff[]> {
    return this.gasTariffsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get waterTariffs(): RemoteData<UserError, WaterTariff[]> {
    return this.waterTariffsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get tariffSearchInfo(): RemoteData<UserError, TariffSearchInfo | null> {
    return this.tariffSearchInfoBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get pendingTariffSwitch(): RemoteData<UserError, TariffSwitch | null> {
    return this.pendingTariffSwitchBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get tariffSearchResults(): RemoteData<UserError, SearchResult[]> {
    return this.tariffSearchResultsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get estimatedConsumption(): RemoteData<UserError, number> {
    return this.estimatedConsumptionBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  get tariffs(): RemoteData<UserError, GetTariffsResponse | null> {
    return this.tariffsBySpaceId[spaces.selectedSpaceId] ?? initialized;
  }

  @Action
  async getCurrentEnergyTariff() {
    try {
      this.setCurrentEnergyTariff(pending);
      const request = new GetCurrentTariffRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setMedium(Device.Medium.ELECTRICITY);
      const response = await tariffServiceClient.getCurrentTariff(request, {});
      this.setCurrentEnergyTariff(success(response.getEnergyTariff() ?? null));
    } catch (error) {
      this.setCurrentEnergyTariff(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getEnergyTariffs() {
    try {
      this.setEnergyTariffs(pending);
      const request = new GetTariffsRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setMedium(Device.Medium.ELECTRICITY);
      const response = await tariffServiceClient.getTariffs(request, {});
      const energyTariffs = response.getEnergyTariffsResponse()?.getEnergyTariffsList() as EnergyTariff[];
      this.setEnergyTariffs(success(energyTariffs));
    } catch (error) {
      this.setEnergyTariffs(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getGasTariffs() {
    try {
      this.setGasTariffs(pending);
      const request = new GetTariffsRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setMedium(Device.Medium.GAS);
      const response = await tariffServiceClient.getTariffs(request, {});
      const gasTariffs = response.getGasTariffsResponse()?.getGasTariffsList() as GasTariff[];
      this.setGasTariffs(success(gasTariffs));
    } catch (error) {
      this.setGasTariffs(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getWaterTariffs() {
    try {
      this.setWaterTariffs(pending);
      const request = new GetTariffsRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      request.setMedium(Device.Medium.WATER);
      const response = await tariffServiceClient.getTariffs(request, {});
      const waterTariffs = response.getWaterTariffsResponse()?.getWaterTariffsList() as WaterTariff[];
      this.setWaterTariffs(success(waterTariffs));
    } catch (error) {
      this.setWaterTariffs(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getTariffSearchInfo() {
    try {
      this.setTariffSearchInfo(pending);
      const request = new GetSearchInfoRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      const response = await tariffServiceClient.getSearchInfo(request, {});
      this.setTariffSearchInfo(success(response.getSearchInfo() ?? null));
    } catch (error) {
      this.setTariffSearchInfo(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getTariffSearchResults() {
    try {
      this.setTariffSearchResults(pending);
      const request = new GetSearchResultsRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      const response = await tariffServiceClient.getSearchResults(request, {});
      this.setTariffSearchResults(success(response.getSearchResultsList()));
    } catch (error) {
      this.setTariffSearchResults(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getPendingTariffSwitch() {
    try {
      this.setPendingTariffSwitch(pending);
      const request = new GetPendingSwitchRequest();
      request.setSpaceId(spaces.selectedSpaceId);
      const response = await tariffServiceClient.getPendingSwitch(request, {});
      this.setPendingTariffSwitch(success(response.getPendingSwitch() ?? null));
    } catch (error) {
      this.setPendingTariffSwitch(failure(userErrorFrom(error)));
    }
  }

  @Action
  async getEstimatedConsumption() {
    try {
      this.setEstimatedConsumption(pending);

      const request = new GetEstimatedConsumptionRequest();
      request.setSpaceId(spaces.selectedSpaceId);

      const response = await tariffServiceClient.getEstimatedConsumption(request, {});
      this.setEstimatedConsumption(success(response.getEstimatedConsumption()));
    } catch (error) {
      this.setEstimatedConsumption(failure(userErrorFrom(error)));
    }
  }

  @Mutation
  setCurrentEnergyTariff(currentEnergyTariff: RemoteData<UserError, EnergyTariff | null>) {
    this.currentEnergyTariffBySpaceId = {
      ...this.currentEnergyTariffBySpaceId,
      [spaces.selectedSpaceId]: currentEnergyTariff,
    };
  }

  @Mutation
  setEnergyTariffs(energyTariffs: RemoteData<UserError, EnergyTariff[]>) {
    this.energyTariffsBySpaceId = {
      ...this.energyTariffsBySpaceId,
      [spaces.selectedSpaceId]: energyTariffs,
    };
  }

  @Mutation
  setGasTariffs(gasTariffs: RemoteData<UserError, GasTariff[]>) {
    this.gasTariffsBySpaceId = {
      ...this.gasTariffsBySpaceId,
      [spaces.selectedSpaceId]: gasTariffs,
    };
  }

  @Mutation
  setWaterTariffs(waterTariffs: RemoteData<UserError, WaterTariff[]>) {
    this.waterTariffsBySpaceId = {
      ...this.waterTariffsBySpaceId,
      [spaces.selectedSpaceId]: waterTariffs,
    };
  }

  @Mutation
  setTariffSearchInfo(data: RemoteData<UserError, TariffSearchInfo | null>) {
    this.tariffSearchInfoBySpaceId = {
      ...this.tariffSearchInfoBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setPendingTariffSwitch(data: RemoteData<UserError, TariffSwitch | null>) {
    this.pendingTariffSwitchBySpaceId = {
      ...this.pendingTariffSwitchBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setTariffSearchResults(data: RemoteData<UserError, SearchResult[]>) {
    this.tariffSearchResultsBySpaceId = {
      ...this.tariffSearchResultsBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }

  @Mutation
  setEstimatedConsumption(data: RemoteData<UserError, number>) {
    this.estimatedConsumptionBySpaceId = {
      ...this.estimatedConsumptionBySpaceId,
      [spaces.selectedSpaceId]: data,
    };
  }
}

export const tariffModule = namespace("tariff");
export default getModule(TariffModule);
