
import { Vue, Component, Prop } from "vue-property-decorator";
import { ChartData } from "zaehlerfreunde-central/ui_service_pb";

@Component
export default class ChartTabs extends Vue {
  @Prop({ default: [] })
  tabs: ChartData.Tab[];

  @Prop({ default: null })
  selectedTab: ChartData.Tab.Type | null;

  onTabSelected(selectedTab: ChartData.Tab): void {
    this.$emit("tab-selected", selectedTab.getType());
  }
}
