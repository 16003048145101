
import { paths } from "@/router/routes";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { subscriptionModule } from "@/store/modules/subscription";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Component, Vue } from "vue-property-decorator";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import CreateSpaceDialog from "@/components/create-space/CreateSpaceDialog.vue";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { getSpaceAddress } from "@/utils/space-utils";

@Component({
  components: {
    CreateSpaceDialog,
  },
})
export default class SpaceSelect extends Vue {
  @spacesModule.State spaces: RemoteData<UserError, Space[]>;
  @spacesModule.State selectedSpace: Space | null;

  @subscriptionModule.Getter currentPlan: SubscriptionPlan;
  @partnerModule.Getter partnerType: Partner.Type;

  showCreateSpaceDialog = false;

  getSpaceAddress = getSpaceAddress;

  spaceName(space: Space): string {
    return space.getName();
  }

  spaceValue(space: Space): string {
    return space.getId();
  }

  onSpaceSelected(space: Space): void {
    spaces.setSelectedSpace(space);
  }

  onCreateSpaceClicked(): void {
    if (
      this.spaces.list.length > 0 &&
      this.currentPlan === SubscriptionPlan.FREE &&
      this.partnerType !== Partner.Type.WHITE_LABEL
    ) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "multiple-spaces" },
      });
    } else if (
      this.spaces.list.length > 1 &&
      this.currentPlan === SubscriptionPlan.PRO &&
      this.partnerType !== Partner.Type.WHITE_LABEL
    ) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "b2b-multiple-spaces" },
      });
    } else {
      this.showCreateSpaceDialog = true;
    }
  }
}
