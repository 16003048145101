
import { RemoteData, initialized } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { paths } from "@/router/routes";
import { TableControl } from "../spaces-table/SpacesTable.vue";
import {
  LandlordToTenantMeterType,
  LandlordToTenantProjectMeter,
} from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";

export interface ProjectMeterTable {
  id: string;
  name: string;
  status: string;
  type: string;
  deviceId: string;
}

@Component({})
export default class ProjectMetersTable extends Vue {
  @Prop({ default: initialized }) metersList: RemoteData<UserError, LandlordToTenantProjectMeter[]>;

  @Prop({ default: null }) itemControls: TableControl[] | null;
  @Prop({ default: null }) headerControls: TableControl[] | null;

  itemsPerPage = 10;
  paths = paths;

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  get headers() {
    return [
      { text: "Name", value: "name" },
      { text: "Status", value: "status" },
      { text: "Meter Type", value: "type" },
      { text: "", value: "controls" },
    ];
  }

  get devices(): ProjectMeterTable[] {
    let devices: ProjectMeterTable[] = [];
    this.metersList.list.forEach((d) => {
      devices.push({
        id: d.getId() ?? "",
        name: d.getDevice()?.getName() ?? "",
        status: this.getStatus(d.getDevice()?.getStatus() ?? Device.Status.FAILED),
        type: this.getMeterType(d.getMeterType()),
        deviceId: d.getDevice()?.getId() ?? "",
      });
    });
    return devices;
  }

  getStatus(status: Device.Status): string {
    switch (status) {
      case Device.Status.CONNECTED:
        return "Connected";
      case Device.Status.CONNECTING:
        return "Connecting";
      case Device.Status.FAILED:
        return "Failed";
      default:
        return "Unknown Status";
    }
  }

  getMeterType(meter: LandlordToTenantMeterType): string {
    switch (meter) {
      case LandlordToTenantMeterType.FLAT:
        return "Flat";
      case LandlordToTenantMeterType.VIRTUAL:
        return "Virtual";
      case LandlordToTenantMeterType.SOLAR_PANEL:
        return "Solar Panel";
      case LandlordToTenantMeterType.BUILDING:
        return "Building";
      default:
        return "Unknown Meter Type";
    }
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }
  onItemControlsClicked(meter: ProjectMeterTable, event: string) {
    this.$emit(event, meter);
  }
}
